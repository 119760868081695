import React, { useState } from "react"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Badge,
} from "reactstrap"
// for range slider
import "react-rangeslider/lib/index.css"
import { Form, Link } from "react-router-dom"
import { toast } from "react-toastify"
import Select from "react-select"

const Options = ["Yes", "No"]
const Recentallergies = ["Yes", "No"]
const alternativeTherapiesOptions = ["Yes", "No"]
// const alternativeTherapiesOptions = ["Yes", "No"]

const allergies = ["option1", "option2", "options3", "option4", "option5"]

const maritalStatusOptions2 = [
  "option1",
  "option2",
  "options3",
  "option4",
  "option5",
]
const familyPlanningOptions2 = [
  "None",
  "option1",
  "option2",
  "options3",
  "option4",
  "option5",
]

const AnyOtherPain = [
  "Ankle pain",
  "Hand/wrist/arm pain",
  "Joint pain",
  "Muscle pain",
  "Knee pain",
  "Neck pain",
  "Shoulder pain",
  "Toes pain",
  "Back pain",
]

const TypeofSputum = [
  "More in quantity",
  "Less in quantity",
  "Purulent",
  "Mucoid",
  "Serous",
  "Blood",
]
const ChestPain = [
  "Retrosternal (Indoor)",
  "Radiating to the arm or jaw",
  "At Rest",
  "On Exertion",
  "Associated with Sweating",
]
const HeartPalpitation = ["At Rest", "Exertional", "None"]
const Breathlessness = ["Exertional", "Lying Down at Night", "None"]

const UrinationType = [
  "Increased Quantity",
  "Decreased Quantity",
  "Frequent urge to urinate",
  "Flatulence",
  "Cloudy/ dark colour",
  "Blood",
  "Pain while urination",
  "Burning sensation",
  "Urinary hesitancy (trouble in flow)",
  "Foul smelling urine",
]

const StoolType = [
  "No Abnormality",
  "Constipation",
  "Diarrhoea",
  "Mucus",
  "Excessive straining",
  "Black or dark colour",
  "Blood",
  "White colour",
  "Strong smelling",
  "Hard stool",
  "Foul smelling urine",
  "Soft stool",
]
const MenstrualProfilePattern = [
  "No Abnormality",
  "Not yet started",
  "Started before the age of 12",
  "Normal menstrual cycle",
  "Premenstrual Syndrome (PMS)",
  "Light or irregular menstruation",
  "Excessive painful",
  "Abnormal/Smelly uterine bleeding",
  "Heavy or prolonged menstrual bleeding",
  "Absence of menstrual bleeding",
  "Menopause",
]

const GynaecologicalProblems = [
  "No Abnormality",
  "Cervical Dysplasia",
  "Menstrual Disorders",
  "Pelvic Floor Prolapse",
  "Pelvic Pain",
  "Polycystic Ovarian Syndrome",
  "Uterine Fibroids",
  "Urinary Incontinence",
  "No Problem",
]

const SignSymptoms = () => {
  const [formData, setFormData] = useState({
    maritalStatus: null,
    numPregnancies: "",
    numMiscarriages: "",
    numChildren: "",
    ageLastChild: "",
    knownAllergies: [],
    familyPlanning: [],
    HeightFeetInches: "",
    weight: null,
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const [selectedAllergies, setSelectedAllergies] = useState([])

  const toggleAllergy = allergy => {
    setSelectedAllergies(prevSelected =>
      prevSelected.includes(allergy)
        ? prevSelected.filter(a => a !== allergy)
        : [...prevSelected, allergy]
    )
  }

  // Recentallergies
  const [selectedAllergyOption, setSelectedAllergyOption] = useState(null)

  const toggleAllergyOption = data => {
    setSelectedAllergyOption(data)
  }

  const [selectedMaritialStatus, setSelectedMaritialStatus] = useState("")

  const toggleMaritialStatus = maritialStatus => {
    setSelectedMaritialStatus(maritialStatus)
    // console.log(maritialStatus)
  }

  const [selectedBreathlessness, setSelectedBreathlessness] = useState("")

  const toggleBreathlessness = data => {
    setSelectedBreathlessness(data)
    // console.log(maritialStatus)
  }

  const [selectedfamilyPlanning, setSelectedFamilyPlanning] = useState([])

  const toggleFamilyPlanning = familyPlanning => {
    setSelectedFamilyPlanning(prevSelected =>
      prevSelected.includes(familyPlanning)
        ? prevSelected.filter(a => a !== familyPlanning)
        : [...prevSelected, familyPlanning]
    )
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          {submittedData && (
            <>
              <h5>Sign & Symptoms</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have cough?</Label>
                    <div>A.- {submittedData?.height || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Chest Pain</Label>
                    <div>A.- {submittedData?.mentalHealthProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Heart Palpitation</Label>
                    <div>
                      A.- {submittedData?.mentalHealthWellbeing || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Breathlessness </Label>
                    <div>A.- {submittedData?.cognitiveImpairment || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.-Any other pain?</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.-Please Select your urination type from the following ?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.-Please Select your stool type from the following ?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.-Please select your menstrual profile/pattern ?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.-Gynaecological Problem(s) at present or in past??
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col>

        <Col md={6}>
          <h5>Sign & Symptoms</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="numPregnancies">Do you have cough?</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Recentallergies?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergyOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleAllergyOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedAllergyOption === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus"> Type of Sputum </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {TypeofSputum?.map(allergy => (
                        <Col key={allergy} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAllergies.includes(allergy)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAllergy(allergy)}
                            className="m-1"
                          >
                            {allergy}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label for="maritalStatus">Chest Pain</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {ChestPain?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergies.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleAllergy(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus"> Heart Palpitation</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {HeartPalpitation?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus"> Breathlessness</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Breathlessness?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedBreathlessness === data
                              ? "primary"
                              : "light"
                          }
                          onClick={() => setSelectedBreathlessness(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">Any other pain?</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {AnyOtherPain?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {/* urination type */}
                <FormGroup>
                  <Label for="maritalStatus">
                    Please Select your urination type from the following
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {UrinationType?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="maritalStatus">
                    Please Select your stool type from the following
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {StoolType?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {/* menstrual profile/pattern */}
                <FormGroup>
                  <Label for="maritalStatus">
                    Please select your menstrual profile/pattern
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {MenstrualProfilePattern?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">
                    Gynaecological Problem(s) at present or in past?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {GynaecologicalProblems?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <Button type="submit" color="primary" style={{}}>
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  // border: "2px solid  #FF7738",
  borderRadius: 7,
  padding: 10,
}

export default SignSymptoms
