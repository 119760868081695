import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap"

const WelcomeCard = () => {
  return (
    <>
      <Container fluid>
        <Row className="mb-4">
          <Col lg="8" className="d-flex align-items-center">
            <h4>Welcome, Kanupriya</h4>
            <p className="ms-3">Your care journey</p>
          </Col>
          <Col lg="4">
            <InputGroup>
              <Input placeholder="search here" />
              <InputGroupText addonType="append">
                <Button color="secondary">
                  <i className="fa fa-search" />
                </Button>
              </InputGroupText>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <Card className="mb-4">
              <CardBody>
                <Row className="align-items-center">
                  <Col xs="4">
                    <img
                      src="profile_image_url"
                      alt="Profile"
                      className="img-fluid rounded-circle"
                    />
                  </Col>
                  <Col xs="8">
                    <CardTitle className="mb-0">Kanupriya Gupta</CardTitle>
                    <CardText className="small">Proud Blood Donor</CardText>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col xs="6" className="text-start">
                    <p className="mb-1">
                      <i className="fa fa-tint" /> B+
                    </p>
                    <p className="mb-1">
                      <i className="fa fa-calendar" /> 18-11-1997
                    </p>
                    <p className="mb-1">
                      <i className="fa fa-venus-mars" /> Female
                    </p>
                  </Col>
                  <Col xs="6" className="text-end">
                    <p className="mb-1">
                      <i className="fa fa-id-card" /> EMJI785030
                    </p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col lg="8">
            <Card className="mb-4">
              <CardBody>
                <Row className="align-items-center">
                  <Col md="6">
                    <CardTitle className="text-start">Health Summary</CardTitle>
                    <p className="small">My Care Coins</p>
                    <h4>1000</h4>
                  </Col>
                  <Col md="6">
                    <div className="text-center">
                      <p>Your Health Credits</p>
                      <div className="progress" style={{ height: "5rem" }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "90%", backgroundColor: "#28a745" }}
                          aria-valuenow="90"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          9.1 / 10
                        </div>
                      </div>
                      <p className="small mt-2">Last Updated: 10/04/2023</p>
                      <div className="mt-3">
                        <i className="fa fa-smile-o fa-2x" />
                        <p>Awesome! Keep it up</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WelcomeCard
