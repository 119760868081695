import React, { useState } from "react";

// Redux
import { Link, useNavigate } from "react-router-dom"

import { Row, Col, CardBody, Card, Container, Form, Input, Label, FormFeedback } from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import CarouselPage from "./CarouselPage"
import LoginImage from "./login_screen.jpg"

const Login = () => {
  const [show, setShow] = useState(false)
  const [activeButton, setActiveButton] = useState(null)
  let nevigate = useNavigate()

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName)
  }
  //meta title
  document.title = "Login"

  // Form validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: "admin@gmail.com",
      password: "123",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      // console.log("values", values)
      nevigate("/dashboard")
    },
  })
  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* <Col>
              <CarouselPage />

              <img
                src={LoginImage}
                alt="Login"
                style={{
                  height: "100vh",
                  width: "50%",
                  objectFit: "cover",
                }}
              />
            </Col> */}

            <Col
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#0077B5", height: "100vh" }}
              // xl={6}
            >
              <div className="text-center">
                {/* <h1 className="text-white mb-4">LOGO</h1> */}
                <img
                  src={LoginImage}
                  alt="Login"
                  className="img-fluid"
                  style={{ maxWidth: "40%", height: "auto" }}
                />
              </div>
            </Col>
            <Col
              xl={4}
              className="position-relative"
              // style={{
              //   backgroundImage: `url(${imagebg})`,
              //   backgroundPosition: "center",
              //   backgroundSize: "cover",
              // }}
            >
              {/* <img src={imagebg} alt=""> */}
              <div
                className="auth-full-page-content p-md-5 p-4"
                style={{ background: "rgba(255,255,255,0.85)" }}
              >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-1 mb-md-1">
                      {/* <Link to="#" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="150"
                          className="logo-dark-element bg-white"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="150"
                          className="logo-light-element bg-white "
                        />
                      </Link> */}
                    </div>
                    <div className="my-auto">
                      {/* <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">Sign in to continue to.</p>
                      </div> */}

                      <div className="text-primary2" style={{ marginLeft: 10 }}>
                        <h5 className="text-primary">Welome to Company</h5>
                        {/* <p>.</p> */}
                        <div className="">
                          <h6 className="text-grey col mt-3">
                            Need an account,
                            <span className="text-primary">
                              {/* &nbsp;&nbsp;SignUp. */}
                              <Link to="/register" className="text-primary">
                                {/* <i className="mdi mdi-lock me-1" /> */}
                                &nbsp;Sign Up
                              </Link>
                            </span>
                          </h6>

                          {/* <h6 className="text-primary col">Signup.</h6> */}
                        </div>
                      </div>
                      <Row>
                        <Col>
                          <div className="mt-2 d-grid p-2">
                            <button
                              className={`btn btn-primary2 btn-block text-white ${
                                activeButton === "Admin" ? "active" : ""
                              }`}
                              type="button"
                              style={{
                                backgroundColor:
                                  activeButton === "Admin"
                                    ? "#FF5733"
                                    : "#FFA41B",
                              }}
                              onClick={() => handleButtonClick("Admin")}
                            >
                              Admin
                            </button>
                          </div>
                        </Col>
                        <Col>
                          <div className="mt-2 d-grid p-2">
                            <button
                              className={`btn btn-primary2 btn-block text-white ${
                                activeButton === "Doctor" ? "active" : ""
                              }`}
                              type="button"
                              style={{
                                backgroundColor:
                                  activeButton === "Doctor"
                                    ? "#FF5733"
                                    : "#FFA41B",
                              }}
                              onClick={() => handleButtonClick("Doctor")}
                            >
                              Doctor
                            </button>
                          </div>
                        </Col>
                        <Col>
                          <div className="mt-2 d-grid p-2">
                            <button
                              className={`btn btn-primary2 btn-block text-white ${
                                activeButton === "Patient" ? "active" : ""
                              }`}
                              type="button"
                              style={{
                                backgroundColor:
                                  activeButton === "Patient"
                                    ? "#FF5733"
                                    : "#FFA41B",
                              }}
                              onClick={() => handleButtonClick("Patient")}
                            >
                              Patient
                            </button>
                          </div>
                        </Col>
                      </Row>

                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()
                            validation.handleSubmit()
                            return false
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Sign in</Label>
                            <Input
                              name="username"
                              className="form-control"
                              placeholder="Username"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            {/* <Label className="form-label">Password</Label> */}
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={show ? "text" : "password"}
                                placeholder="Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <button
                                onClick={() => setShow(!show)}
                                className="btn btn-light "
                                type="button"
                                id="password-addon"
                              >
                                <i className="mdi mdi-eye-outline"></i>
                              </button>
                            </div>
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <Row>
                            <Col>
                              <div className="form-check">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="customControlInline"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="customControlInline"
                                >
                                  Remember me
                                </label>
                              </div>
                            </Col>
                            <Col>
                              <div className="mt text-center">
                                <Link
                                  to="/pages-forgot-pwd"
                                  className="text-dark"
                                >
                                  {/* <i className="mdi mdi-lock me-1" /> */}
                                  Forgot password?
                                </Link>
                              </div>
                            </Col>
                          </Row>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary2 btn-block text-white"
                              type="submit"
                              style={{ backgroundColor: "#F86F03" }}
                            >
                              Log In
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <h5 className="font-size-16 mb-3 text-grey">
                              ----------OR----------
                            </h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              </li>{" "}
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info"
                                >
                                  <i className="mdi mdi-twitter" />
                                </Link>
                              </li>{" "}
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                >
                                  <i className="mdi mdi-linkedin" />
                                </Link>
                              </li>
                            </ul>
                          </div>

                          {/* <div className="mt-4 text-center">
                            <Link to="/pages-forgot-pwd" className="text-muted">
                              <i className="mdi mdi-lock me-1" /> Forgot your
                              password?
                            </Link>
                          </div> */}
                        </Form>
                      </div>
                    </div>

                    {/* <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()}</p>
                    </div> */}
                  </div>
                </div>
              </div>
              {/* </img> */}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login;
