import React, { useState } from "react"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Badge,
} from "reactstrap"
// for range slider
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"
import Dropzone from "react-dropzone"
import { Form, Link } from "react-router-dom"
import { toast } from "react-toastify"
import ImageFullView from "components/Common/ImageFullView"

const maritalStatusOptions2 = ["Yes", "No"]
const antibioticresistance = ["Yes", "No"]
const lossofAppetite = ["Yes", "No"]
const lossofSleepDisturbed = ["Yes", "No"]
const Recentallergies = ["Yes", "No"]
const alternativeTherapiesOptions = ["Yes", "No"]

const familyPlanningOptions2 = [
  "None",
  "Injectable Contraceptive",
  "Oral contraceptive pills",
  "Emergency Contraceptive Pills",
  "Condoms",
  "Intra-Uterine Contraceptive Devices (IUCD)",
  "Implants",
  "Combined contraceptive patch",
  "Contraceptive vaginal ring (CVR)",
  "Female Sterilization",
  "Male Sterilization",
]

const allergies = [
  "Hives",
  "Itching of the skin or eyes",
  "Skin rash",
  "Swelling of the lips,tongue ,or face",
  "Nausea/Vomiting",
]

const alternativeTherapies = [
  "5-HTP (5-hydroxytryptophan)",
  "Acupuncture",
  "Alternative medical systems",
  "Aromatherapy",
  "Cannabis-based medicines",
  "Herbal remedies",
  "Homeopathy",
  "Hypnotherapy",
  "Light therapy",
  "Massage",
  "Meditation",
  "Mindfulness",
  "Reflexology",
  "Reiki",
  "SAMe (S-Adenosyl-Methionine)",
  "Spiritual and religious healing",
  "St John's wort",
  "Tai chi",
  "Vitamin and mineral supplements",
  "Weighted blankets",
  "Yoga",
]
const CurrentMedication = () => {
  const [custom_val, setcustom_val] = useState(0)
  const [custom_val2, setcustom_val2] = useState(0)
  const [custom_val3, setcustom_val3] = useState(0)
  const [custom_val4, setcustom_val4] = useState(0)

  const [selectedFiles, setSelectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    const updatedFiles = files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    )
    setSelectedFiles([...selectedFiles, ...updatedFiles])
    // console.log(selectedFiles, "Files")
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  function removeFile(index) {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index))
  }

  const labels = {
    1: "1",
    2: "2",
    3: "3",
    4: "4",
    5: "5",
    6: "6",
    7: "7",
    8: "8",
    9: "9",
    10: "10",
  }

  const [medicines, setMedicines] = useState([])
  const [medicineName, setMedicineName] = useState("")
  const [medicineYears, setMedicineYears] = useState("")

  const handleAddMedicine = () => {
    if (medicineName && medicineYears) {
      setMedicines([...medicines, { name: medicineName, years: medicineYears }])
      setMedicineName("")
      setMedicineYears("")
    } else {
      //   alert("required")
      toast("Both Fields Required!", {
        type: "error",
      })
    }
  }

  const handleRemoveMedicine = index => {
    const newMedicines = medicines.filter((_, i) => i !== index)
    setMedicines(newMedicines)
  }

  const [formData, setFormData] = useState({
    maritalStatus: null,
    numPregnancies: "",
    numMiscarriages: "",
    numChildren: "",
    ageLastChild: "",
    knownAllergies: [],
    familyPlanning: [],
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const [selectedAllergies, setSelectedAllergies] = useState([])

  const toggleAllergy = allergy => {
    setSelectedAllergies(prevSelected =>
      prevSelected.includes(allergy)
        ? prevSelected.filter(a => a !== allergy)
        : [...prevSelected, allergy]
    )
  }

  const [selectedMaritialStatus, setSelectedMaritialStatus] = useState(null)

  const toggleMaritialStatus = maritialStatus => {
    setSelectedMaritialStatus(maritialStatus)
    // console.log(maritialStatus)
  }

  const [selectedantibioticresistance, setSelectedAntibioticresistance] =
    useState(null)

  const toggleAntibioticresistance = data => {
    setSelectedAntibioticresistance(data)
    // console.log(selectedantibioticresistance, "selectedantibioticresistance")
  }
  // loss of appitite
  const [selectedlossofAppetite, setSelectedLossofAppetite] = useState(null)

  const toggleLossofAppetite = data => {
    setSelectedLossofAppetite(data)
  }
  // loss of lossofSleepDisturbed
  const [selectedlossofSleepDisturbed, setSelectedlossofSleepDisturbed] =
    useState(null)

  const toggleLossofSleepDisturbed = data => {
    setSelectedlossofSleepDisturbed(data)
  }

  // Recentallergies
  const [selectedAllergyOption, setSelectedAllergyOption] = useState(null)

  const toggleAllergyOption = data => {
    setSelectedAllergyOption(data)
  }

  // alternativeTherapiesOptions
  const [
    selectedalternativeTherapiesOptions,
    setSelectedalternativeTherapiesOptions,
  ] = useState(null)

  const toggleAlternativeTherapiesOptions = data => {
    setSelectedalternativeTherapiesOptions(data)
  }
  // allergies options
  const [selectedAlternativeTherapies, setselectedAlternativeTherapies] =
    useState([])

  const toggleAlternativeTherapies = values => {
    setselectedAlternativeTherapies(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  // const [selectedantibioticresistance, setSelectedAntibioticresistance] =
  //   useState([])

  // const toggleAntibioticresistance = values => {
  //   setSelectedAntibioticresistance(prevSelected =>
  //     prevSelected.includes(values)
  //       ? prevSelected.filter(a => a !== values)
  //       : [...prevSelected, values]
  //   )
  // }
  const [selectedfamilyPlanning, setSelectedFamilyPlanning] = useState([])

  const toggleFamilyPlanning = familyPlanning => {
    setSelectedFamilyPlanning(prevSelected =>
      prevSelected.includes(familyPlanning)
        ? prevSelected.filter(a => a !== familyPlanning)
        : [...prevSelected, familyPlanning]
    )
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          {submittedData && (
            <>
              <h5>Current Medication(s)</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Name of medicine</Label>
                    <div>
                      A.- {submittedData?.maritalStatus?.label || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have anti-biotic resistance?s</Label>
                    <div>A.- {submittedData?.numPregnancies || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Rate your response to Current Medication(s)s (scale of
                      10)
                    </Label>
                    <div>A.- {submittedData?.numMiscarriages || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Rate your drug adherence habits (scale of 10)
                    </Label>
                    <div>A.- {submittedData?.numChildren || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Rate your dietary adherence as prescribed by your
                      doctors (scale of 10)
                    </Label>
                    <div>A.- {submittedData?.ageLastChild || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Known Allergies</Label>
                    <div>
                      A.-{" "}
                      {submittedData?.knownAllergies
                        ?.map(allergy => allergy.label)
                        .join(", ") || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Family Planning (Method Adopted)</Label>
                    <div>
                      A.-{" "}
                      {submittedData?.familyPlanning
                        ?.map(method => method.label)
                        .join(", ") || "N/A"}
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
        <Col md={6}>
          <h5>Current Medication(s)</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="maritalStatus">Name of medicine</Label>
                  <Card style={formGroupStyle}>
                    <CardBody>
                      <FormGroup>
                        {/* <Label for="medicineName">Name of medicine</Label> */}
                        <Input
                          type="text"
                          id="medicineName"
                          placeholder="Name of medicine"
                          value={medicineName}
                          onChange={e => setMedicineName(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        {/* <Label for="medicineYears">Since when (years)</Label> */}
                        <Input
                          type="number"
                          id="medicineYears"
                          placeholder="Since when (years)"
                          value={medicineYears}
                          onChange={e => setMedicineYears(e.target.value)}
                        />
                      </FormGroup>
                      <Button
                        size="sm"
                        color="primary"
                        onClick={handleAddMedicine}
                      >
                        Add
                      </Button>
                      <Row>
                        {medicines.map((medicine, index) => (
                          <Col
                            key={index}
                            md="auto"
                            xs="auto"
                            lg="auto"
                            className="mt-1"
                          >
                            <Badge
                              color="light"
                              className="p- m-1"
                              style={{
                                color: "dark",
                                backgroundColor: "",
                                border: "1px solid",
                                borderRadius: "10px",
                              }}
                            >
                              {medicine.name} / {medicine.years} years
                              <Button
                                //   color="danger"
                                style={{ color: "black" }}
                                size="sm"
                                className="ml-2 mr-2"
                                onClick={() => handleRemoveMedicine(index)}
                              >
                                &times;
                              </Button>
                            </Badge>
                          </Col>
                        ))}
                      </Row>
                    </CardBody>
                  </Card>
                </FormGroup>
                <FormGroup>
                  <Label for="numPregnancies">
                    Do you have anti-biotic resistance?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {antibioticresistance?.map(values => (
                      <Col key={values} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedantibioticresistance === values
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleAntibioticresistance(values)}
                          className="m-1"
                        >
                          {values}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup className="g-1" style={formGroupStyle}>
                  <Label for="numMiscarriages">
                    Rate your response to Current Medication(s)s (scale of 10)
                  </Label>
                  <Slider
                    className="g-1"
                    style={formGroupStyle}
                    value={custom_val}
                    min={1}
                    max={10}
                    labels={labels}
                    orientation="horizontal"
                    onChange={value => {
                      setcustom_val(value)
                    }}
                  />
                </FormGroup>
                <FormGroup className="g-1" style={formGroupStyle}>
                  <Label for="numMiscarriages">
                    Rate your drug adherence habits (scale of 10)
                  </Label>
                  <Slider
                    className="g-1"
                    style={formGroupStyle}
                    value={custom_val2}
                    min={1}
                    max={10}
                    labels={labels}
                    orientation="horizontal"
                    onChange={value => {
                      setcustom_val2(value)
                    }}
                  />
                </FormGroup>
                <FormGroup className="g-1" style={formGroupStyle}>
                  <Label for="numMiscarriages">
                    Rate your dietary adherence as prescribed by your doctors
                    (scale of 10)
                  </Label>
                  <Slider
                    className="g-1"
                    style={formGroupStyle}
                    value={custom_val3}
                    min={1}
                    max={10}
                    labels={labels}
                    orientation="horizontal"
                    onChange={value => {
                      setcustom_val3(value)
                    }}
                  />
                </FormGroup>
                <FormGroup className="g-1" style={formGroupStyle}>
                  <Label for="numMiscarriages">
                    Rate your exercise adherence as recommended by your doctor
                    (scale of 10)
                  </Label>
                  <Slider
                    className="g-1"
                    style={formGroupStyle}
                    value={custom_val4}
                    min={1}
                    max={10}
                    labels={labels}
                    orientation="horizontal"
                    onChange={value => {
                      setcustom_val4(value)
                    }}
                  />
                </FormGroup>
                {/* loss of appetite */}
                <FormGroup>
                  <Label for="maritalStatus">
                    {" "}
                    Have You noticed loss of appetite in recent days after
                    taking medications?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {lossofAppetite?.map(values => (
                      <Col key={values} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedlossofAppetite === values
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleLossofAppetite(values)}
                          className="m-1"
                        >
                          {values}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {/* <FormGroup>
                  <Label for="numPregnancies">
                    Have you noticed loss of appetite in recent days after
                    taking medications?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {maritalStatusOptions2?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup> */}

                {/* loss of sleep/disturbed */}
                <FormGroup>
                  <Label for="numPregnancies">
                    Have you noticed loss of sleep/ disturbed sleep pattern in
                    recent days after taking medications?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {lossofSleepDisturbed?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedlossofSleepDisturbed === data
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleLossofSleepDisturbed(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="numPregnancies">
                    Do you feel fatigue or heart burn or palpitation or
                    excessive / abnormal sweating in recent days?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {maritalStatusOptions2?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="numPregnancies">
                    Have you encountered any allergy in recent days?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Recentallergies?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergyOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleAllergyOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedAllergyOption === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus">
                      {" "}
                      Please Select the allergies noticed by you
                    </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {allergies?.map(allergy => (
                        <Col key={allergy} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAllergies.includes(allergy)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAllergy(allergy)}
                            className="m-1"
                          >
                            {allergy}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="numPregnancies">
                    Are you using any alternative therapies for your
                    pre-existing health problems?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {alternativeTherapiesOptions?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedalternativeTherapiesOptions === data
                              ? "primary"
                              : "light"
                          }
                          onClick={() =>
                            toggleAlternativeTherapiesOptions(data)
                          }
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedalternativeTherapiesOptions === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus">
                      If yes, please specify from the below list
                    </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {alternativeTherapies.map(values => (
                        <Col key={values} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAlternativeTherapies.includes(values)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAlternativeTherapies(values)}
                            className="m-1"
                          >
                            {values}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}
                {/* upload document */}
                <FormGroup>
                  <Row className="g-1" style={formGroupStyle}>
                    <Col>
                      <Card>
                        <h5 className="card-title2">
                          Upload Your medications (if any)
                        </h5>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles)
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick mt-2"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews mt-3"
                          id="file-previews"
                        >
                          {selectedFiles.map((f, i) => (
                            <Card
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              key={i + "-file"}
                            >
                              <div className="p-2">
                                <Row className="align-items-center">
                                  {/* <Col className="col-auto">
                                    <img
                                      data-dz-thumbnail=""
                                      height="80"
                                      className="avatar-sm rounded bg-light"
                                      alt={f.name}
                                      src={f.preview}
                                    />
                                  </Col> */}
                                  <Col className="col-auto">
                                    <ImageFullView
                                      imageSrc={f.preview}
                                      altText={f.name}
                                      triggerElement={
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                          style={{ cursor: "pointer" }}
                                        />
                                      }
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {f.name}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{f.formattedSize}</strong>
                                    </p>
                                  </Col>
                                  <Col className="col-auto">
                                    <Button
                                      color="danger"
                                      size="sm"
                                      onClick={() => removeFile(i)}
                                    >
                                      &times;
                                    </Button>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          ))}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </FormGroup>
                <Button type="submit" color="primary" style={{}}>
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  borderRadius: 7,
  padding: 10,
}

export default CurrentMedication
