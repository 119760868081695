import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
// import PersonalProfiling from "./comp/PersonalProfiling"

import InboxIcon from "../../assets/images/health/paitentRegistrationTab/inbox-in.png"
import OutboxIcon from "../../assets/images/health/paitentRegistrationTab/inbox-out.png"
import PendingIcon from "../../assets/images/health/paitentRegistrationTab/pending.png"
import RejectedIcon from "../../assets/images/health/paitentRegistrationTab/rejected2.png"
import Inbox from "./comp/Inbox"
import OutboxPage from "./comp/Outbox"
import InboxForm from "./comp/InboxForm"
import PendingOrders from "./comp/PendingOrders"
import RejectedOrders from "./comp/RejectedOrders"

const PharmacyStore = () => {
  //meta title
  document.title = "Pharmacy Store"

  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const tabs = [
    { id: "1", title: "Inbox", icon: InboxIcon },
    { id: "2", title: "Outbox", icon: OutboxIcon },
    { id: "3", title: "Pending Orders", icon: PendingIcon },
    { id: "4", title: "Rejected Orders", icon: RejectedIcon },
  ]

  const [marginLeft, setMarginLeft] = useState(0)

  useEffect(() => {
    const updateMargin = () => {
      if (window.innerWidth >= 768) {
        // Adjust the breakpoint as needed
        setMarginLeft(250) // Add margin-left for desktop
      } else {
        setMarginLeft(0) // No margin for mobile
      }
    }
    // Set the initial margin
    updateMargin()
    // Update margin on window resize
    window.addEventListener("resize", updateMargin)
    // Cleanup on component unmount
    return () => {
      window.removeEventListener("resize", updateMargin)
    }
  }, [])

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* <Breadcrumbs title="" breadcrumbItem="" /> */}
          <Card>
            <CardBody>
              <div
                className="scrollable-tabs "
                style={{ marginLeft }}
                // style={{
                //   marginLeft: `${marginLeft}px`,
                //   transition: "margin-left 0.5s ease", // Transition property
                // }}
              >
                <Nav className="nav-tabs-custom mt-2 ">
                  {tabs.map(tab => (
                    <NavItem key={tab.id} className="tab-card">
                      <NavLink
                        className={classnames({ active: activeTab === tab.id })}
                        onClick={() => toggle(tab.id)}
                      >
                        <Card
                          className="tab-card-content"
                          style={{
                            // border: "1px solid  #007bff",
                            border: "2px solid  #e5e8eb",
                            borderRadius: "35px",
                            // for tab coutom height
                            minWidth: "150px",
                            minHeight: "150px",
                            maxWidth: "200px",
                            maxHeight: "250px",
                          }}
                        >
                          {/* Badge for message count */}
                          <div
                            className="badge-notification"
                            style={{
                              position: "absolute",
                              top: "10px",
                              right: "20px",
                              backgroundColor: "red",
                              color: "white",
                              borderRadius: 30,
                              padding: "5px 8px",
                              fontSize: "12px",
                            }}
                          >
                            {tab.messageCount || 15}
                          </div>
                          <CardBody className="text-center">
                            {/* <i
                              className={`${tab.icon} d-block mb-2 fs-3 text`}
                            ></i> */}
                            <img
                              src={tab.icon}
                              // className="d-block mb-2 fs- text"
                              height={50}
                              width={50}
                            />
                            <CardTitle
                              tag="h6"
                              style={{
                                textAlign: "center",
                                textWrap: "balance",
                                marginTop: 10,
                              }}
                            >
                              {tab.title}
                            </CardTitle>
                          </CardBody>
                        </Card>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
            </CardBody>
          </Card>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Inbox />
                {/* <Col xl="6" lg="6">
                  <Inbox />
                </Col>
                <Col xl="6" lg="6">
                  <InboxForm />
                </Col> */}
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <OutboxPage />
            </TabPane>
            <TabPane tabId="3">
              <PendingOrders />
            </TabPane>
            <TabPane tabId="4">
              <RejectedOrders />
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </>
  )
}

export default PharmacyStore
