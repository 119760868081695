import React, { useState } from "react"
import { Card, CardBody, CardTitle, Col, Modal, Row, Button } from "reactstrap"
import "./filtermodal.css"
const FilterModal = () => {
  const [modal_scroll, setmodal_scroll] = useState(false)

  function tog_scroll() {
    setmodal_scroll(!modal_scroll)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  return (
    <>
      <Col>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-primary mb-5"
            onClick={() => {
              tog_scroll()
            }}
            data-toggle="modal"
          >
            Advance Filter
          </button>

          {/* 
          <Button
            type="button"
            className="advance-filter-btn"
            onClick={() => {
              tog_scroll()
            }}
            data-toggle="modal"
          >
            A<br />
            D<br />
            V<br />
            A<br />
            N<br />
            C<br />
            E<br />
            <br />
            F<br />
            I<br />
            L<br />
            T<br />
            E<br />R
          </Button> */}

          <Modal
            isOpen={modal_scroll}
            toggle={() => {
              tog_scroll()
            }}
            scrollable={true}
            size="xl"
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">Supplementary Filters</h5>
              <button
                type="button"
                onClick={() => setmodal_scroll(false)}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="filter-section">
                <h6>Comorbidity:</h6>
                <Row className="mb-2">
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Diabetes</Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Hypertension</Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Asthma</Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Obesity</Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Thyroid</Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Genetic Disorders</Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Endocrine Disorders
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Auto Immune Diseases
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Rheumatological Disorders
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Transplant Case</Button>
                  </Col>
                </Row>
                <h6>Habits and lifestyles:</h6>
                <Row className="mb-2">
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Alcohol Consumption
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Smoking</Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Tobacco/ Gutkha</Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Night Shift Job</Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Exposure to Hazardous Environment
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Sedentary Lifestyle
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Frequent Junk Foods
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">Special Diet</Button>
                  </Col>
                </Row>
                <h6>Habits and lifestyles:</h6>
                <Row className="mb-2">
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Family History (Hereditary)
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Disease Recurrence (same disease)
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      History of Fall/ Injury/ Accident/ Hemorrhage
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Past Surgery (Same Disease)
                    </Button>
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <Button className="filter-button">
                      Past Surgery (Other Disease)
                    </Button>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => setmodal_scroll(false)}
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </Modal>
        </div>
      </Col>
    </>
  )
}

export default FilterModal

// import React, { useState } from "react"
// import { Card, CardBody, CardTitle, Col, Modal, Row, Button } from "reactstrap"
// import "./filtermodal.css"

// const FilterModal = () => {
//   const [modal_scroll, setmodal_scroll] = useState(false)
//   const [selectedButtons, setSelectedButtons] = useState(new Set())

//   function tog_scroll() {
//     setmodal_scroll(!modal_scroll)
//     removeBodyCss()
//   }

//   function removeBodyCss() {
//     document.body.classList.add("no_padding")
//   }

//   function handleButtonClick(buttonLabel) {
//     setSelectedButtons(prevSelectedButtons => {
//       const newSelectedButtons = new Set(prevSelectedButtons)
//       if (newSelectedButtons.has(buttonLabel)) {
//         newSelectedButtons.delete(buttonLabel)
//       } else {
//         newSelectedButtons.add(buttonLabel)
//       }
//       return newSelectedButtons
//     })
//   }

//   return (
//     <>
//       <Col>
//         <div>
//           <button
//             type="button"
//             className="btn btn-primary flex-end"
//             onClick={() => {
//               tog_scroll()
//             }}
//             data-toggle="modal"
//           >
//             Advance Filter
//           </button>
//           <Modal
//             isOpen={modal_scroll}
//             toggle={() => {
//               tog_scroll()
//             }}
//             scrollable={true}
//             size="xl"
//           >
//             <div className="modal-header">
//               <h5 className="modal-title mt-0">Supplementary Filters</h5>
//               <button
//                 type="button"
//                 onClick={() => setmodal_scroll(false)}
//                 className="close"
//                 data-dismiss="modal"
//                 aria-label="Close"
//               >
//                 <span aria-hidden="true">&times;</span>
//               </button>
//             </div>
//             <div className="modal-body">
//               <div className="filter-section">
//                 <h6>Comorbidity:</h6>
//                 <Row className="mb-2">
//                   {[
//                     "Diabetes",
//                     "Hypertension",
//                     "Asthma",
//                     "Obesity",
//                     "Thyroid",
//                     "Genetic Disorders",
//                     "Endocrine Disorders",
//                     "Auto Immune Diseases",
//                     "Rheumatological Disorders",
//                     "Transplant Case",
//                   ].map(label => (
//                     <Col xs="auto" className="mb-2" key={label}>
//                       <Button
//                         className={`filter-button ${
//                           selectedButtons.has(label) ? "active" : ""
//                         }`}
//                         onClick={() => handleButtonClick(label)}
//                       >
//                         {label}
//                       </Button>
//                     </Col>
//                   ))}
//                 </Row>
//                 <h6>Habits and lifestyles:</h6>
//                 <Row className="mb-2">
//                   {[
//                     "Alcohol Consumption",
//                     "Smoking",
//                     "Tobacco/ Gutkha",
//                     "Night Shift Job",
//                     "Exposure to Hazardous Environment",
//                     "Sedentary Lifestyle",
//                     "Frequent Junk Foods",
//                     "Special Diet",
//                   ].map(label => (
//                     <Col xs="auto" className="mb-2" key={label}>
//                       <Button
//                         className={`filter-button ${
//                           selectedButtons.has(label) ? "active" : ""
//                         }`}
//                         onClick={() => handleButtonClick(label)}
//                       >
//                         {label}
//                       </Button>
//                     </Col>
//                   ))}
//                 </Row>
//                 <h6>Habits and lifestyles:</h6>
//                 <Row className="mb-2">
//                   {[
//                     "Family History (Hereditary)",
//                     "Disease Recurrence (same disease)",
//                     "History of Fall/ Injury/ Accident/ Hemorrhage",
//                     "Past Surgery (Same Disease)",
//                     "Past Surgery (Other Disease)",
//                   ].map(label => (
//                     <Col xs="auto" className="mb-2" key={label}>
//                       <Button
//                         className={`filter-button ${
//                           selectedButtons.has(label) ? "active" : ""
//                         }`}
//                         onClick={() => handleButtonClick(label)}
//                       >
//                         {label}
//                       </Button>
//                     </Col>
//                   ))}
//                 </Row>
//               </div>
//             </div>
//             <div className="modal-footer">
//               <button
//                 type="button"
//                 className="btn btn-secondary"
//                 onClick={() => setmodal_scroll(false)}
//               >
//                 Close
//               </button>
//               <button type="button" className="btn btn-primary">
//                 Save changes
//               </button>
//             </div>
//           </Modal>
//         </div>
//       </Col>
//     </>
//   )
// }

// export default FilterModal
