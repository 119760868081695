import ImageFullView from "components/Common/ImageFullView"
import React, { useState, useEffect, useRef } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Form,
  Table,
  CardTitle,
  ListGroup,
  ListGroupItem,
} from "reactstrap"
import HospitalReferral from "./HospitalRefferral"
import Select from "react-select"
import VideoCallWebRtc from "./comp/VideoCallWebRtc"
import GoproControl from "./comp/GoproControl"
import VideoFrame from "./comp/GoproLive"
import GoProLiveStream from "./comp/GoproLive"
import Rtmp_player from "./comp/Rtmp_player/Rtmp_player"

// Dummy JSON data for different search types
const dummyData = {
  diagnosis: [
    { value: "acute-bronchitis", label: "Acute bronchitis" },
    { value: "allergic-rhinitis", label: "Allergic rhinitis" },
    { value: "anemia", label: "Anemia" },
    { value: "anxiety-disorder", label: "Anxiety disorder" },
    { value: "asthma", label: "Asthma" },
    { value: "atrial-fibrillation", label: "Atrial fibrillation" },
    { value: "back-pain", label: "Back pain" },
    { value: "bacterial-pneumonia", label: "Bacterial pneumonia" },
  ],
  medicine: [
    { value: "acetaminophen", label: "Acetaminophen" },
    { value: "amoxicillin", label: "Amoxicillin" },
    { value: "atorvastatin", label: "Atorvastatin" },
    { value: "azithromycin", label: "Azithromycin" },
    { value: "bupropion", label: "Bupropion" },
    { value: "ciprofloxacin", label: "Ciprofloxacin" },
    { value: "doxycycline", label: "Doxycycline" },
    { value: "escitalopram", label: "Escitalopram" },
  ],
  therapies: [
    { value: "acupuncture", label: "Acupuncture" },
    { value: "cbt", label: "Cognitive Behavioral Therapy" },
    { value: "hydrotherapy", label: "Hydrotherapy" },
    { value: "massage-therapy", label: "Massage therapy" },
    { value: "occupational-therapy", label: "Occupational therapy" },
    { value: "physical-therapy", label: "Physical therapy" },
    { value: "speech-therapy", label: "Speech therapy" },
    { value: "yoga-therapy", label: "Yoga therapy" },
  ],
}

const RightBar = () => {
  const formGroupStyle = {
    border: "2px solid  #e5e8eb",
    // border: "1px solid grey",
    borderRadius: 7,
    padding: 5,
  }

  const reports = [
    {
      id: 1,
      name: "Report-1",
      image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    },
    {
      id: 2,
      name: "Report-2",
      image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    },
    {
      id: 3,
      name: "Report-3",
      image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    },
    {
      id: 4,
      name: "Report-4",
      image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    },
    {
      id: 5,
      name: "Report-5",
      image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    },
    {
      id: 6,
      name: "Report-6",
      image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    },
  ]

  const dischargeData = [
    { id: 1, doctor: "Gaurav Pandey", date: "01/02/24" },
    { id: 2, doctor: "Mohshin Khan", date: "01/02/24" },
    { id: 3, doctor: "Zafran Khan", date: "01/02/24" },
  ]

  const SearchableInput = ({ label, placeholder, searchType }) => {
    const [selectedOptions, setSelectedOptions] = useState(null)

    const handleChange = selected => {
      setSelectedOptions(selected)
    }

    return (
      <div className="mb-3">
        <label className="control-label">{label}</label>
        <Select
          value={selectedOptions}
          isMulti={true}
          onChange={handleChange}
          options={dummyData[searchType]}
          className="select2-selection"
          placeholder={placeholder}
        />
      </div>
    )
  }
  const openGoogleMeet = () => {
    window.open("https://meet.google.com/new", "_blank")
  }

  const iframeRef = useRef(null)

  const openFullScreen = () => {
    if (iframeRef.current) {
      // Request fullscreen for the iframe's container
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen()
      } else if (iframeRef.current.mozRequestFullScreen) {
        iframeRef.current.mozRequestFullScreen() // Firefox
      } else if (iframeRef.current.webkitRequestFullscreen) {
        iframeRef.current.webkitRequestFullscreen() // Chrome, Safari, and Opera
      } else if (iframeRef.current.msRequestFullscreen) {
        iframeRef.current.msRequestFullscreen() // IE/Edge
      }
    }
  }

  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    // Check screen size on mount
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768) // Adjust 768px as needed for your breakpoint
    }
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize)

    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])

  return (
    <React.Fragment>
      <Card
        style={{
          border: "2px solid  #e5e8eb",
          // borderRadius: 7,
        }}
      >
        <Row>
          {/* Video Call Column */}

          <Col
            md="12"
            // className="d-flex flex-column align-items-center mb-4"
            className={`d-flex flex-column align-items-center mb-4 ${
              isMobile ? "d-none" : ""
            }`}

            // style={formGroupStyle}
          >
            {!isMobile && ( // Conditionally render iframe on larger screens only
              <>
                <>
                  {/* <GoProLiveStream defaultUrl="" /> */}
                  {/* <Rtmp_player defaultUrl="" /> */}
                  <Card style={{ width: "100%" }}>
                    <div
                      ref={iframeRef} // Reference to the div containing the iframe
                      style={{
                        position: "relative",
                        paddingTop: "56.25%", // 16:9 aspect ratio
                        width: "100%",
                      }}
                    >
                      <iframe
                        src="https://livepush.io/embed/emmnW9tAl8m5CWK5"
                        allowFullScreen="1"
                        frameBorder="0"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                      ></iframe>
                    </div>
                    {/* <button
                      type="button"
                      onClick={openFullScreen}
                      className="btn header-item noti-icon"
                      data-toggle="fullscreen"
                    >
                      <i className="bx bx-fullscreen" />
                    </button> */}
                  </Card>
                  {/* <Button
                    color="primary"
                    onClick={openFullScreen}
                    className="mt-1"
                  >
                    View Full Screen
                  </Button> */}
                </>
                {/* <div>
                <VideoFrame defaultUrl="https://gopro.com/v/WlQNr7Xr1LNen" />
              </div> */}
                {/* <GoproControl /> */}
                {/* <VideoCallWebRtc /> */}
                {/* <Button color="primary" onClick={openGoogleMeet}>
                Start Google Meet
              </Button> */}
              </>
            )}
          </Col>

          {/* E Prescription Column */}
          <Col
            md="12"
            // style={formGroupStyle}
          >
            <h5 className="text-center bg-primary text-white fs-3">
              E Prescription
            </h5>
            <Card
            //  style={formGroupStyle}
            >
              <CardBody style={{ height: "100%", overflowY: "auto" }}>
                {/* <div className="search-box" style={formGroupStyle}>
                  <p className="text-muted">Provisional Diagnosis</p>
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control rounded bg-light border-light"
                      placeholder="Select Provisional Diagnosis"
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
                <div className="search-box mt-2" style={formGroupStyle}>
                  <p className="text-muted">Recommended Test</p>
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control rounded bg-light border-light"
                      placeholder="Select Recommended Test"
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div> */}
                <SearchableInput
                  label="Provisional Diagnosis"
                  placeholder="Select Provisional Diagnosis"
                  searchType="diagnosis"
                />
                <SearchableInput
                  label="Recommended Test"
                  placeholder="Select Recommended Test"
                  searchType="diagnosis"
                />

                <Form>
                  <FormGroup className="mt-2" style={formGroupStyle}>
                    <Label>
                      Refer Prescription to preferred investigation Partner(s)
                    </Label>
                    <Col lg="12" sm="12">
                      <Input type="checkbox" className="mx-4" /> Gourav Pandey
                    </Col>
                    <Col lg="12" sm="12">
                      <Input type="checkbox" className="mx-4" /> Mohshin Khan
                    </Col>
                  </FormGroup>
                </Form>
                <div>
                  <Card className="mb-4 mt-2" style={formGroupStyle}>
                    <CardBody
                    // style={formGroupStyle}
                    >
                      <div
                        className="d-flex overflow-auto"
                        // style={formGroupStyle}
                        style={{
                          display: "flex",
                          overflowX: "auto",
                          scrollbarWidth: "none",
                          msOverflowStyle: "none",
                        }}
                      >
                        <style>
                          {`
                                 div::-webkit-scrollbar {
                               display: none;
                                             }
                                  `}
                        </style>
                        {reports.map(report => (
                          <ImageFullView
                            key={report.id}
                            imageSrc={report.image}
                            altText={report.name}
                            triggerElement={
                              <div
                                className="text-center me-1"
                                style={{ minWidth: "100px" }}
                              >
                                {/* <ReportIcon /> */}
                                <img
                                  src={report.image}
                                  alt=""
                                  height="50px"
                                  width="40px"
                                />
                                <div>{report.name}</div>
                              </div>
                            }
                          />
                        ))}
                      </div>
                    </CardBody>
                  </Card>

                  <Card style={formGroupStyle}>
                    <CardTitle>
                      Second Opinion Response (s)/ Discharge Summary
                    </CardTitle>
                    <>
                      <Table
                        borderless
                        className="table-responsive"
                        style={formGroupStyle}
                      >
                        <thead className="border border 2px solid black">
                          <tr>
                            <th>S.NO.</th>
                            <th>DOCTOR</th>
                            <th>DATE</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dischargeData.map(item => (
                            <tr key={item.id}>
                              <td>{item.id}</td>
                              <td>{item.doctor}</td>
                              <td>{item.date}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  </Card>
                </div>

                <div>
                  <SearchableInput
                    label="Final Diagnosis"
                    placeholder="Select Final Diagnosis"
                    searchType="diagnosis"
                  />
                  <SearchableInput
                    label="Medicine Prescription"
                    placeholder="Select Medicine Prescription"
                    searchType="medicine"
                  />
                  <SearchableInput
                    label="Recommended Therapies"
                    placeholder="Select Recommended Therapies"
                    searchType="therapies"
                  />
                  <SearchableInput
                    label="Recommended Procedure/ Treatment"
                    placeholder="Select RRecommended Procedure/ Treatment"
                    searchType="therapies"
                  />
                  <SearchableInput
                    label="Refer Paitent For a Second Spinion"
                    placeholder="Select Refer Paitent For a Second Spinion"
                    searchType="therapies"
                  />
                </div>

                {/* <div className="search-box mt-2" style={formGroupStyle}>
                  <p className="text-muted">Recommended Procedure/ Treatment</p>
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control rounded bg-light border-light"
                      placeholder=" Recommended Procedure/ Treatment"
                    />
                    <i className="mdi mdi-magnify search-icon"></i>
                  </div>
                </div>
                <div className="search-box mt-2" style={formGroupStyle}>
                  <p className="text-muted">
                    Refer Paitent For a Second Spinion
                  </p>
                  <div className="position-relative">
                    <Input
                      type="text"
                      className="form-control rounded bg-light border-light"
                      placeholder="Body Strength and mobility(Orthopedic & Spine)"
                    />
                  </div>
                </div> */}

                <HospitalReferral />
                {/* <div className="flex justify-center mt-3">
                    <Button>Save</Button>
                  </div> */}
                <Button className="mt-3 mx-auto d-block bg-primary">
                  Save
                </Button>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  )
}

const SearchableInput = ({ label, placeholder, searchType }) => {
  const [selectedOptions, setSelectedOptions] = useState(null)

  const handleChange = selected => {
    setSelectedOptions(selected)
  }

  return (
    <div className="mb-3">
      <label className="control-label">{label}</label>
      <Select
        value={selectedOptions}
        isMulti={true}
        onChange={handleChange}
        options={dummyData[searchType]}
        className="select2-selection"
        placeholder={placeholder}
      />
    </div>
  )
}

const ReportIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 3H5C4.46957 3 3.96086 3.21071 3.58579 3.58579C3.21071 3.96086 3 4.46957 3 5V19C3 19.5304 3.21071 20.0391 3.58579 20.4142C3.96086 20.7893 4.46957 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V9L15 3Z"
      stroke="#FF9F43"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 3V9H21"
      stroke="#FF9F43"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 11V17"
      stroke="#FF9F43"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 14H16"
      stroke="#FF9F43"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default RightBar
