import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import PreExistingDiseases from "./comp/PreExistingDiseases"
import PersonalProfiling from "./comp/PersonalProfiling"
import currentmedications from "../../assets/images/health/paitentRegistrationTab/currentmedications.png"
import familyhistory from "../../assets/images/health/paitentRegistrationTab/familyhistory.png"
import habitlifestyle from "../../assets/images/health/paitentRegistrationTab/habit&lifestyle.png"
import occupation from "../../assets/images/health/paitentRegistrationTab/occupation.png"
import passsurgery from "../../assets/images/health/paitentRegistrationTab/passsurgery.png"
import pastillness from "../../assets/images/health/paitentRegistrationTab/pastillness.png"
import personalprofiling from "../../assets/images/health/paitentRegistrationTab/personalprofiling.png"
import preExisting from "../../assets/images/health/paitentRegistrationTab/pre-existing.png"
import signsymptoms from "../../assets/images/health/paitentRegistrationTab/sign & symptoms.png"
// import signsymptoms from "../../assets/images/health/paitentRegistrationTab/"

import logo from "../../assets/images/kcc_logo.png"
import ConsultationProcess from "./comp/ConsultationProcess"
import PreExistingDiseases2 from "./comp/PreExistingDiseases2"
import CurrentMedication from "./comp/CurrentMedication"
import FamilyHistory from "./comp/FamilyHistory"
import PhysicalExamination from "./comp/PhysicalExamination"
import VaccinationImmunization from "./comp/VaccinationImmunization"
import PastIllness from "./comp/PastIllness"
import HabitandLifeStyle from "./comp/HabitandLifeStyle"
import SignSymptoms from "./comp/SignSymptoms"
import OccupationalProfile from "./comp/OccupationalProfile"
import PastSurgeriesHospitalization from "./comp/PastSurgeriesHospitalization"
import HarmonalImbalanceandDeficiencies from "./comp/HarmonalImbalanceandDeficiencies"

const PaitentRegistrationProfiling = () => {
  //meta title
  document.title = "Paitent Registration Profiling"

  const [activeTab, setActiveTab] = useState("1")

  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const tabs = [
    { id: "1", title: "Personal Profiling", icon: personalprofiling },
    { id: "2", title: "Pre-Existing Disease(s)", icon: preExisting },
    { id: "3", title: "Current Medication(s)", icon: currentmedications },
    { id: "4", title: "Family History", icon: familyhistory },
    { id: "5", title: "Physical Examination", icon: currentmedications },
    { id: "6", title: "Habits & Lifestyle", icon: habitlifestyle },
    { id: "7", title: "Sign & Symptoms", icon: signsymptoms },
    { id: "8", title: "Occupational Profile", icon: occupation },
    { id: "9", title: "Past Illness", icon: pastillness },
    {
      id: "10",
      title: "Past Surgeries & Hospitalization",
      icon: passsurgery,
    },
    {
      id: "11",
      title: "Harmonal Imbalanceand Deficiencies",
      icon: currentmedications,
    },
    {
      id: "12",
      title: "Vaccination / Immunization",
      icon: currentmedications,
    },
  ]

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Paitent Registration Profiling"
            breadcrumbItem="Paitent Registration Profiling"
          />

          <Card>
            <CardBody>
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="12" sm="12" lg="1">
                      <img
                        src={logo}
                        // className="d-block mb-2 fs- text"
                        height={90}
                        width={90}
                        className="mb-1"
                      />
                    </Col>
                    <Col
                      lg=""
                      // style={{ marginLeft: 10 }}
                    >
                      <CardText tag="h4" className="text-dark mt-3">
                        Medical Centre, South Delhi
                      </CardText>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <CardText
                tag="h6"
                className="text-dark"
                style={{
                  // border: "1px solid",
                  border: "2px solid  #e5e8eb",
                  //   margin: 5,
                  padding: 8,
                  borderRadius: "15px",
                  backgroundColor: "rgba(82, 95, 225, 0.2)",
                  // backgroundColor: "#525FE1",
                  // backgroundColor: "bg-primary",
                }}
              >
                UHID-#12345 Mohsin Khan(28 |Male), DOA-08/01/2024{" "}
              </CardText>
              <div className="scrollable-tabs ">
                <Nav className="nav-tabs-custom mt-2">
                  {tabs.map(tab => (
                    <NavItem key={tab.id} className="tab-card">
                      <NavLink
                        className={classnames({ active: activeTab === tab.id })}
                        onClick={() => toggle(tab.id)}
                      >
                        <Card
                          className="tab-card-content"
                          style={{
                            // border: "1px solid  #007bff",
                            border: "2px solid  #e5e8eb",
                            borderRadius: "35px",
                            // for tab coutom height
                            minWidth: "150px",
                            minHeight: "150px",
                            maxWidth: "200px",
                            maxHeight: "250px",
                          }}
                        >
                          <CardBody className="text-center">
                            {/* <i
                              className={`${tab.icon} d-block mb-2 fs-3 text`}
                            ></i> */}
                            <img
                              src={tab.icon}
                              // className="d-block mb-2 fs- text"
                              height={50}
                              width={50}
                            />
                            <CardTitle
                              tag="h6"
                              style={{
                                textAlign: "center",
                                textWrap: "balance",
                                marginTop: 10,
                              }}
                            >
                              {tab.title}
                            </CardTitle>
                          </CardBody>
                        </Card>
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </div>
            </CardBody>
          </Card>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <PersonalProfiling />
            </TabPane>
            <TabPane tabId="2">
              {/* <PreExistingDiseases/> */}
              <PreExistingDiseases2 />
            </TabPane>
            <TabPane tabId="3">
              {/* <ConsultationProcess /> */}
              <CurrentMedication />
            </TabPane>
            <TabPane tabId="4">
              <FamilyHistory />
            </TabPane>
            <TabPane tabId="5">
              <PhysicalExamination />
            </TabPane>
            <TabPane tabId="6">
              <HabitandLifeStyle />
            </TabPane>
            <TabPane tabId="7">
              <SignSymptoms />
            </TabPane>
            <TabPane tabId="8">
              <OccupationalProfile />
            </TabPane>
            <TabPane tabId="9">
              <PastIllness />
            </TabPane>
            <TabPane tabId="10">
              <PastSurgeriesHospitalization />
            </TabPane>
            <TabPane tabId="11">
              <HarmonalImbalanceandDeficiencies />
            </TabPane>
            <TabPane tabId="12">
              <VaccinationImmunization />
            </TabPane>
          </TabContent>

          {/* <TabContent activeTab={activeTab}>
            {tabs.map(tab => (
              <TabPane tabId={tab.id} key={tab.id}>
                <Card>
                  <CardBody>
                    <CardTitle>{tab.title}</CardTitle>
                    <CardText>Content for {tab.title}</CardText>
                  </CardBody>
                </Card>
              </TabPane>
            ))}
          </TabContent> */}
        </Container>
      </div>
    </>
  )
}

export default PaitentRegistrationProfiling
