import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Table,
} from "reactstrap"

const NewbornCaseRecordView = () => {
  // Dummy data - replace with actual data in your implementation
  const recordData = {
    facilityName: "NBSU NOIDA",
    nbsuRegNo: "8787878787",
    doctorInCharge: "Rajesh K Chawla",
    rchNo: "987656700",
    motherName: "Vimla devi",
    admissionDate: "2024-05-17",
    generalCondition: "Alert",
    temperature: "98.6",
    heartRate: "140",
    apnea: "No",
    respiratoryRate: "45",
    bloodPressure: "65/40",
    grunting: "No",
    chestIndrawing: "No",
    headCircumference: "34",
    length: "50",
    color: "Pink",
    crt: "No",
    skinPinch: "No",
    meconiumStainedCord: "No",
    cry: "Normal",
    tone: "Active",
    convulsions: "No",
    breastFeed: "Yes",
    sucking: "Good",
    attachment: "Well attached",
    bleeding: "No",
    jaundice: "No",
    ubiCuLus: "Normal",
    skinPustules: "No",
    bulgingAnteriorFontanel: "No",
    malformation: "None",
    bloodSugar: "70",
    oxygenSaturation: "98",
  }

  return (
    <Container fluid={true} className="my-4 newborn-case-record">
      <style>
        {`
          .newborn-case-record .form-control-static {
            padding: 0.375rem 0.75rem;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            background-color: #f8f9fa;
            min-height: 38px;
          }

          .newborn-case-record label {
            font-weight: bold;
          }

          @media print {
            body { background-color: white; }
            .newborn-case-record { width: 100%; margin: 0; padding: 0; }
            .card { border: none; box-shadow: none; }
            .card-header { background-color: white !important; color: black !important; padding: 0.5rem 0; }
            .card-body { padding: 0.5rem 0; }
            .form-control-static { border: none; background-color: white; padding: 0.2rem 0; }
            .col-md-6, .col-md-3, .col-md-4 { flex: 0 0 50%; max-width: 50%; }
            h4 { margin-top: 1rem; margin-bottom: 0.5rem; }
            .no-print { display: none; }
          }
        `}
      </style>
      <Card>
        {/* <CardHeader className="bg-primary text-white text-center">
          <h2>NEWBORN STABLIZATION UNIT</h2>
          <h3>NEWBORN CASE RECORD SHEET</h3>
        </CardHeader> */}
        <CardHeader className="bg-primary text-white text-center">
          <h2 style={{ fontSize: window.innerWidth < 768 ? 14 : 20 }}>
            NEWBORN STABLIZATION UNIT
          </h2>
          <h3 style={{ fontSize: window.innerWidth < 768 ? 14 : 20 }}>
            NEWBORN CASE RECORD SHEET
          </h3>
        </CardHeader>
        <>
          <Form>
            <Container fluid={true} className="mt-2">
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Facility Name</Label>
                    <div className="form-control-static">
                      {recordData.facilityName}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>NBSU Reg No.</Label>
                    <div className="form-control-static">
                      {recordData.nbsuRegNo}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Doctor in Charge</Label>
                    <div className="form-control-static">
                      {recordData.doctorInCharge}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>RCH No.</Label>
                    <div className="form-control-static">
                      {recordData.rchNo}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>Mother's Name</Label>
                    <div className="form-control-static">
                      {recordData.motherName}
                    </div>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>Date of Admission</Label>
                    <div className="form-control-static">
                      {recordData.admissionDate}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <h4 className="mt-4">General Examination</h4>
              <Table responsive bordered>
                <tbody>
                  <tr>
                    <td>General Condition</td>
                    <td>{recordData.generalCondition}</td>
                    <td>Temperature (°F)</td>
                    <td>{recordData.temperature}</td>
                  </tr>
                  <tr>
                    <td>Heart Rate (/min)</td>
                    <td>{recordData.heartRate}</td>
                    <td>Apnea</td>
                    <td>{recordData.apnea}</td>
                  </tr>
                  <tr>
                    <td>RR (/min)</td>
                    <td>{recordData.respiratoryRate}</td>
                    <td>B.P. (mm/Hg)</td>
                    <td>{recordData.bloodPressure}</td>
                  </tr>
                  <tr>
                    <td>Grunting</td>
                    <td>{recordData.grunting}</td>
                    <td>Chest Indrawing</td>
                    <td>{recordData.chestIndrawing}</td>
                  </tr>
                  <tr>
                    <td>Head Circumference (cm)</td>
                    <td>{recordData.headCircumference}</td>
                    <td>Length (cm)</td>
                    <td>{recordData.length}</td>
                  </tr>
                  <tr>
                    <td>Color</td>
                    <td>{recordData.color}</td>
                    <td>CRT > 3 secs</td>
                    <td>{recordData.crt}</td>
                  </tr>
                  <tr>
                    <td>Skin pinch > 2 secs</td>
                    <td>{recordData.skinPinch}</td>
                    <td>Meconium Stained Cord</td>
                    <td>{recordData.meconiumStainedCord}</td>
                  </tr>
                  <tr>
                    <td>Cry</td>
                    <td>{recordData.cry}</td>
                    <td>Tone</td>
                    <td>{recordData.tone}</td>
                  </tr>
                  <tr>
                    <td>Convulsions</td>
                    <td>{recordData.convulsions}</td>
                    <td>Taking Breast Feed</td>
                    <td>{recordData.breastFeed}</td>
                  </tr>
                  <tr>
                    <td>Sucking</td>
                    <td>{recordData.sucking}</td>
                    <td>Attachment</td>
                    <td>{recordData.attachment}</td>
                  </tr>
                  <tr>
                    <td>Bleeding</td>
                    <td>{recordData.bleeding}</td>
                    <td>Jaundice</td>
                    <td>{recordData.jaundice}</td>
                  </tr>
                  <tr>
                    <td>Ubi/Cu/Lus</td>
                    <td>{recordData.ubiCuLus}</td>
                    <td>Skin Pustules</td>
                    <td>{recordData.skinPustules}</td>
                  </tr>
                  <tr>
                    <td>Bulging Anterior Fontanel</td>
                    <td>{recordData.bulgingAnteriorFontanel}</td>
                    <td>Malformation</td>
                    <td>{recordData.malformation}</td>
                  </tr>
                  <tr>
                    <td>Blood Sugar</td>
                    <td>{recordData.bloodSugar}</td>
                    <td>Oxygen Saturation (70 or above)</td>
                    <td>{recordData.oxygenSaturation}</td>
                  </tr>
                </tbody>
              </Table>
            </Container>

            {/* 
            <h4 className="mt-4">General Examination</h4>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>General Condition</Label>
                  <div className="form-control-static">
                    {recordData.generalCondition}
                  </div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Temperature (°F)</Label>
                  <div className="form-control-static">
                    {recordData.temperature}
                  </div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Heart Rate (/min)</Label>
                  <div className="form-control-static">
                    {recordData.heartRate}
                  </div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Apnea</Label>
                  <div className="form-control-static">{recordData.apnea}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>RR (/min)</Label>
                  <div className="form-control-static">
                    {recordData.respiratoryRate}
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>B.P. (mm/Hg)</Label>
                  <div className="form-control-static">
                    {recordData.bloodPressure}
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Grunting</Label>
                  <div className="form-control-static">
                    {recordData.grunting}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Chest Indrawing</Label>
                  <div className="form-control-static">
                    {recordData.chestIndrawing}
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Head Circumference (cm)</Label>
                  <div className="form-control-static">
                    {recordData.headCircumference}
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Length (cm)</Label>
                  <div className="form-control-static">{recordData.length}</div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Color</Label>
                  <div className="form-control-static">{recordData.color}</div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>CRT > 3 secs</Label>
                  <div className="form-control-static">{recordData.crt}</div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Skin pinch > 2 secs</Label>
                  <div className="form-control-static">
                    {recordData.skinPinch}
                  </div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Meconium Stained Cord</Label>
                  <div className="form-control-static">
                    {recordData.meconiumStainedCord}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Cry</Label>
                  <div className="form-control-static">{recordData.cry}</div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Tone</Label>
                  <div className="form-control-static">{recordData.tone}</div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Convulsions</Label>
                  <div className="form-control-static">
                    {recordData.convulsions}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Taking Breast Feed</Label>
                  <div className="form-control-static">
                    {recordData.breastFeed}
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Sucking</Label>
                  <div className="form-control-static">
                    {recordData.sucking}
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Attachment</Label>
                  <div className="form-control-static">
                    {recordData.attachment}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup>
                  <Label>Bleeding</Label>
                  <div className="form-control-static">
                    {recordData.bleeding}
                  </div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Jaundice</Label>
                  <div className="form-control-static">
                    {recordData.jaundice}
                  </div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Ubi/Cu/Lus</Label>
                  <div className="form-control-static">
                    {recordData.ubiCuLus}
                  </div>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Skin Pustules</Label>
                  <div className="form-control-static">
                    {recordData.skinPustules}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label>Bulging Anterior Fontanel</Label>
                  <div className="form-control-static">
                    {recordData.bulgingAnteriorFontanel}
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Malformation</Label>
                  <div className="form-control-static">
                    {recordData.malformation}
                  </div>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label>Blood Sugar</Label>
                  <div className="form-control-static">
                    {recordData.bloodSugar}
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Oxygen Saturation (70 or above)</Label>
                  <div className="form-control-static">
                    {recordData.oxygenSaturation}
                  </div>
                </FormGroup>
              </Col>
            </Row> */}
          </Form>
        </>
      </Card>
      {/* <div className="mt-3 text-center no-print">
        <button className="btn btn-primary" onClick={() => window.print()}>
          Print Record
        </button>
      </div> */}
    </Container>
  )
}

export default NewbornCaseRecordView
