import React from "react"
import { Container, Card, CardBody, Row, Col, Button } from "reactstrap"

const RejectedOrders = () => {
  const services = [
    {
      serviceTitle: "Consultation",
      name: "Gaurav Pande (M/45 years)",
      patientType: "Online",
      category: "New",
      referredBy: "Dr. Smith",
      location: "New Delhi",
    },
    {
      serviceTitle: "Medication Review",
      name: "Rahul Sharma (M/34 years)",
      patientType: "Offline",
      category: "Follow up",
      referredBy: "Dr. Kumar",
      location: "Mumbai",
    },
    {
      serviceTitle: "Telehealth Session",
      name: "Priya Mehta (F/29 years)",
      patientType: "Online",
      category: "New, Follow up",
      referredBy: "Self",
      location: "Bangalore",
    },
  ]

  return (
    <div className="bg-light min-vh-100">
      <div className="bg-primary text-white p-3 mb-4">
        <h2 className="m-0">Rejected Orders</h2>
      </div>
      <Container>
        {services.map((service, index) => (
          <Card className="mb-4 shadow-sm" key={index} lg="6" xl="6">
            <CardBody>
              <h4 className="text-center mb-3">{service.serviceTitle}</h4>
              <Row className="mb-2">
                <Col xs="6">
                  <strong className="text-muted">Name:</strong>
                  <div>{service.name}</div>
                </Col>
                <Col xs="6">
                  <strong className="text-muted">Contact number:</strong>
                  <div>7349048790</div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col xs="6">
                  <strong className="text-muted">Patient type:</strong>
                  <div>{service.patientType}</div>
                </Col>
                <Col xs="6">
                  <strong className="text-muted">Category:</strong>
                  <div>{service.category}</div>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs="6">
                  <strong className="text-muted">Referred by:</strong>
                  <div>{service.referredBy}</div>
                </Col>
                <Col xs="6">
                  <strong className="text-muted">
                    Location Portal code/ City/ Country:
                  </strong>
                  <div>{service.location}</div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-between">
                  <Button
                    color="light"
                    className="border-primary text-primary"
                    outline
                  >
                    View History
                  </Button>
                  <Button color="warning" className="text-white">
                    Doctor Response
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
      </Container>
    </div>
  )
}

export default RejectedOrders
