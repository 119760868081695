import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import RightBar from "./RightBar"
import LeftBar from "./LeftBar"

const Index = () => {
  //meta title
  document.title = "Video Calling"
  const formGroupStyle = {
    border: "2px solid  #e5e8eb",
    // border: "1px solid grey",
    borderRadius: 7,
    // padding: 10,
  }
  const [isWebView, setIsWebView] = useState(window.innerWidth >= 768)

  useEffect(() => {
    const handleResize = () => setIsWebView(window.innerWidth >= 768)
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return (
    <React.Fragment>
      <div
        className={isWebView ? "page-content" : ""}
        // className="page-content"
        // className={window.innerWidth >= 768 ? "page-content" : ""}
        style={
          {
            // marginTop: window.innerWidth < 768 ? "200" : "", // 100 padding for mobile, 1rem for larger screens
            // padding: window.innerWidth < 768 ? "0" : "", // 0 padding for mobile, 1rem for larger screens
          }
        }
      >
        <Container fluid>
          {/* <Breadcrumbs title="" breadcrumbItem="" /> */}
          <Row style={{}}>
            <Col lg={8}>
              <LeftBar />
            </Col>
            <Col lg={4}>
              <RightBar />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Index
