import React from "react"

import {
  Table,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { UpArrow } from "../../assets/images/uparrow.svg"
import { DownArrow } from "../../assets/images/downarrow.svg"

const BasicTable = () => {
  //meta title

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          {/* <Breadcrumbs title="City List" breadcrumbItem="" /> */}
          <Row>
            <Row>
              <Col lg="">
                <CardTitle className="mb-4">City Lists</CardTitle>
              </Col>
              <Col lg="2" className="flex-end">
                <Button
                  size="10"
                  className="mb-3"
                  style={{ color: "white", backgroundColor: "orange" }}
                >
                  DownLoad Report
                </Button>
              </Col>
            </Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <span style={{ color: "green" }}> Green Zone</span>- Top 5
                    Cities{" "}
                  </CardTitle>
                  {/* <CardSubtitle className="card-title-desc">
                    Create responsive tables by wrapping any <code>.table</code>{" "}
                    in <code>.table-responsive</code>
                    to make them scroll horizontally on small devices (under
                    768px).
                  </CardSubtitle> */}

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>City</th>
                          <th>Rank</th>
                          <th>Last Year Rank</th>
                          <th
                          //  style={{ color: "#04B000" }}
                          >
                            % Change
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Kanpur</td>
                          <td>#1</td>
                          <td className="text-center">#1</td>
                          {/* <td style={{ color: "#04B000" }}>5%🔼</td> */}
                          <td style={{ color: "#04B000", fontSize: 20 }}>
                            5% ↑
                          </td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Kanpur</td>
                          <td>#2</td>
                          <td className="text-center">#2</td>
                          {/* <td className="text-danger">5%🔽</td> */}
                          <td className="text-danger">5% ↓</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Kanpur</td>
                          <td>#3</td>
                          <td className="text-center">#3</td>
                          {/* <td className="text-danger">5%🔽</td> */}
                          <td className="text-danger">5% ↓</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    <span style={{ color: "red" }}>Red Zone</span>
                    -Bottom 5 Cities{" "}
                  </CardTitle>
                  {/* <CardSubtitle className="card-title-desc">
                    Create responsive tables by wrapping any <code>.table</code>{" "}
                    in <code>.table-responsive</code>
                    to make them scroll horizontally on small devices (under
                    768px).
                  </CardSubtitle> */}

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>City</th>
                          <th>Rank</th>
                          <th>Last Year Rank</th>
                          <th className="text-succeess">% Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td style={{ color: "#04B000" }}>5%🔼</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-danger">5%🔽</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-danger">5%🔽</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">
                    5 Outperforming cities jumping the chart
                  </CardTitle>
                  {/* <CardSubtitle className="card-title-desc">
                    Create responsive tables by wrapping any <code>.table</code>{" "}
                    in <code>.table-responsive</code>
                    to make them scroll horizontally on small devices (under
                    768px).
                  </CardSubtitle> */}

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>City</th>
                          <th>Rank</th>
                          <th>Last Year Rank</th>
                          <th className="text-succeess">%Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td style={{ color: "#04B000" }}>5%🔼</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-danger">5%🔽</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-danger">5%🔽</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-2 text=white">
                    5 Cities declining on chart{" "}
                  </CardTitle>
                  {/* <CardSubtitle className="card-title-desc">
                    Create responsive tables by wrapping any <code>.table</code>{" "}
                    in <code>.table-responsive</code>
                    to make them scroll horizontally on small devices (under
                    768px).
                  </CardSubtitle> */}

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr style={{ backgroundColor: "#525FE1" }}>
                          <th>#</th>
                          <th>City</th>
                          <th>Rank</th>
                          <th>Last Year Rank</th>
                          <th className="text-succeess">%Change</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td style={{ color: "#04B000" }}>5%🔼</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-danger">5%🔽</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-danger">5%🔽</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">5 most consistent cities</CardTitle>
                  {/* <CardSubtitle className="card-title-desc">
                    Create responsive tables by wrapping any <code>.table</code>{" "}
                    in <code>.table-responsive</code>
                    to make them scroll horizontally on small devices (under
                    768px).
                  </CardSubtitle> */}

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>City</th>
                          <th>Rank</th>
                          <th>Last Year Rank</th>
                          <td className="text-succeess">%Change</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-succeess">5%+</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-succeess">5%+</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-succeess">5%+</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <CardTitle className="h4 mb-2 text=white">
                    5 most volatile cities{" "}
                  </CardTitle>
                  {/* <CardSubtitle className="card-title-desc">
                    Create responsive tables by wrapping any <code>.table</code>{" "}
                    in <code>.table-responsive</code>
                    to make them scroll horizontally on small devices (under
                    768px).
                  </CardSubtitle> */}

                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr style={{ backgroundColor: "#525FE1" }}>
                          <th>#</th>
                          <th>City</th>
                          <th>Rank</th>
                          <th>Last Year Rank</th>
                          <td className="text-succeess">%Change</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">1</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-succeess">5%+</td>
                        </tr>
                        <tr>
                          <th scope="row">2</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-succeess">5%+</td>
                        </tr>
                        <tr>
                          <th scope="row">3</th>
                          <td>Kanpur</td>
                          <td>Agra</td>
                          <td>Lucknow</td>
                          <td className="text-succeess">5%+</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default BasicTable
