import React from "react"
import PropTypes from "prop-types"

const HealthScoreGraph = ({ value, lastYearValue, title }) => {
  const calculateRank = score => {
    // Placeholder logic - adjust as needed
    return Math.round(score / 10)
  }

  const currentRank = calculateRank(value)
  const lastYearRank = calculateRank(lastYearValue)
  const changePercentage = (
    ((value - lastYearValue) / lastYearValue) *
    100
  ).toFixed(0)

  const getColor = angle => {
    if (angle < 90) return "#4CAF50" // Green
    if (angle < 135) return "#FFA500" // Orange
    if (angle < 170) return "#FF0000" // Red
    return "#8BC34A" // Light Green
  }

  const drawArc = (
    ctx,
    centerX,
    centerY,
    radius,
    startAngle,
    endAngle,
    color
  ) => {
    ctx.beginPath()
    ctx.arc(
      centerX,
      centerY,
      radius,
      (startAngle * Math.PI) / 180,
      (endAngle * Math.PI) / 180,
      false
    )
    ctx.lineWidth = 20
    ctx.strokeStyle = color
    ctx.stroke()
  }

  const drawKnob = (ctx, centerX, centerY, radius, angle) => {
    const x = centerX + radius * Math.cos((angle * Math.PI) / 180)
    const y = centerY + radius * Math.sin((angle * Math.PI) / 180)
    ctx.beginPath()
    ctx.arc(x, y, 10, 0, 2 * Math.PI, false)
    ctx.fillStyle = "#FFFFFF"
    ctx.fill()
    ctx.lineWidth = 2
    ctx.strokeStyle = "blue"
    ctx.stroke()
  }

  const drawDottedDiameter = (ctx, centerX, centerY, radius) => {
    ctx.beginPath()
    ctx.setLineDash([5, 5])
    ctx.moveTo(centerX - radius, centerY)
    ctx.lineTo(centerX + radius, centerY)
    ctx.strokeStyle = "#CCCCCC"
    ctx.lineWidth = 1
    ctx.stroke()
    ctx.setLineDash([])
  }

  React.useEffect(() => {
    const canvas = document.getElementById("healthScoreCanvas")
    const ctx = canvas.getContext("2d")
    const centerX = canvas.width / 2
    const centerY = canvas.height / 2
    const radius = Math.min(centerX, centerY) - 10

    // Clear canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height)

    // Draw background arc
    drawArc(ctx, centerX, centerY, radius, -180, 0, "#E0E0E0")

    // Draw colored segments
    drawArc(ctx, centerX, centerY, radius, -180, -90, "#4CAF50")
    drawArc(ctx, centerX, centerY, radius, -90, -45, "#FFA500")
    drawArc(ctx, centerX, centerY, radius, -45, -10, "#FF0000")
    drawArc(ctx, centerX, centerY, radius, -10, 0, "#8BC34A")

    // Draw dotted diameter
    drawDottedDiameter(ctx, centerX, centerY, radius - 10)

    // Draw knob
    const knobAngle = -180 + (value / 100) * 180
    drawKnob(ctx, centerX, centerY, radius, knobAngle)

    // Draw rank text
    ctx.font = "bold 24px Arial"
    ctx.fillStyle = "#4CAF50"
    ctx.textAlign = "center"
    ctx.fillText(`Rank ${currentRank}`, centerX, centerY + 40)
  }, [value, currentRank])

  return (
    <div style={{ width: "300px", textAlign: "center" }}>
      <h6 className="mt-2">{title}</h6>
      <canvas id="healthScoreCanvas" width="300" height="170"></canvas>
      <div style={{ marginTop: "1px" }}>
        <p style={{ margin: "1px 0" }}>
          Changes from last year{" "}
          <span style={{ color: "#4CAF50" }}>+{changePercentage}%</span>
        </p>
        <p style={{ fontSize: "12px", color: "#666" }}>
          Last year ranking {lastYearRank}
        </p>
      </div>
    </div>
  )
}

HealthScoreGraph.propTypes = {
  value: PropTypes.number.isRequired,
  lastYearValue: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
}

export default HealthScoreGraph
