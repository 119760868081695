import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Progress,
  Button,
} from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"

// Import your custom icons
import MentalHealthIcon from "../../../assets/images/health/mentahhealth.svg"
import NeurologicalIcon from "../../../assets/images/health/Vector.svg"
import HeartIcon from "../../../assets/images/health/heart-muscle-icon 1.svg"
import LungIcon from "../../../assets/images/health/lungs-icon 1.svg"
import KidneyIcon from "../../../assets/images/health/mentahhealth.svg"
import LiverIcon from "../../../assets/images/health/liver-icon 1.svg"
import GynecologyIcon from "../../../assets/images/health/mentahhealth.svg"
import CancerIcon from "../../../assets/images/health/mentahhealth.svg"
import DiabetesIcon from "../../../assets/images/health/mentahhealth.svg"
import InjuryIcon from "../../../assets/images/health/mentahhealth.svg"
import BoneIcon from "../../../assets/images/health/mentahhealth.svg"
import PregnancyIcon from "../../../assets/images/health/mentahhealth.svg"
import VisionIcon from "../../../assets/images/health/see-icon 1.svg"
import EntIcon from "../../../assets/images/health/ENT Problems.svg"
import InfertilityIcon from "../../../assets/images/health/fertilization-fertility-icon 1.svg"

const DiseaseCard = ({ title, icon, percentage, color }) => (
  <Card
    className="mb-2  data-card-glance "
    style={{
      border: "1px solid",
      // border: "3px solid  #e5e8eb",
      borderRadius: "15px",
      // backgroundColor: "#EEEFFC",
      backgroundColor: "rgba(82, 95, 225, 0.1)",
      color: "blue",
    }}
  >
    <CardBody
      className="d-flex flex-column align-items-center"
      // style={{ backgroundColor: "#525FE1" }}
    >
      {/* <i className={`bx ${icon} bx-lg mb-2`}></i> */}
      <img
        src={icon}
        alt={title}
        className="mb-2"
        style={{
          width: "60px",
          height: "60px",
          padding: "10px",
          border: "1px solid",
          borderRadius: 50,
          color: "blue",
        }}
      />

      <div className="text-center mb-2 small">{title}</div>
      <Progress className="w-100 progress-xl" value={percentage} color={color}>
        {percentage}%
      </Progress>
    </CardBody>
  </Card>
)

const DiseaseBurden = () => {
  const diseases2 = [
    {
      title: "Mental Health Issues",
      icon: "bx-brain",
      percentage: 0,
      color: "success",
    },
    {
      title: "Neurological Problems",
      icon: "bx-brain",
      percentage: 4,
      color: "success",
    },
    {
      title: "Cardiac/Heart Problems",
      icon: "bx-heart",
      percentage: 37.78,
      color: "warning",
    },
    {
      title: "Chronic Lung Problems",
      icon: "bx-wind",
      percentage: 1,
      color: "success",
    },
    {
      title: "Chronic Kidney Problems",
      icon: "bx-body",
      percentage: 4,
      color: "success",
    },
    {
      title: "Chronic Liver Problems",
      icon: "bx-body",
      percentage: 4,
      color: "danger",
    },
    {
      title: "Gynaecology Problems",
      icon: "bx-female",
      percentage: 3,
      color: "success",
    },
    {
      title: "Cancer Risks",
      icon: "bx-cells",
      percentage: 1,
      color: "success",
    },
    {
      title: "Diabetes & Thyroid",
      icon: "bx-injection",
      percentage: 100,
      color: "danger",
    },
    {
      title: "Physical Injury & Fall Risk",
      icon: "bx-run",
      percentage: 0,
      color: "success",
    },
    {
      title: "Osteoporosis & Bone Risk",
      icon: "bx-bone",
      percentage: 4,
      color: "success",
    },
    {
      title: "Pregnancies Problems",
      icon: "bx-baby-carriage",
      percentage: 0,
      color: "success",
    },
    {
      title: "Vision Problems",
      icon: "bx-show",
      percentage: 0,
      color: "success",
    },
    { title: "ENT Problems", icon: "bx-ear", percentage: 9, color: "warning" },
    {
      title: "Infertility Problems",
      icon: "bx-male-female",
      percentage: 0,
      color: "success",
    },
  ]

  const diseases = [
    {
      title: "Mental Health Issues",
      icon: MentalHealthIcon,
      percentage: 10,
      color: "success",
    },
    {
      title: "Neurological Problems",
      icon: NeurologicalIcon,
      percentage: 41,
      color: "success",
    },
    {
      title: "Cardiac/Heart Problems",
      icon: HeartIcon,
      percentage: 37.78,
      color: "warning",
    },
    {
      title: "Chronic Lung Problems",
      icon: LungIcon,
      percentage: 11,
      color: "success",
    },
    {
      title: "Chronic Kidney Problems",
      icon: KidneyIcon,
      percentage: 41,
      color: "success",
    },
    {
      title: "Chronic Liver Problems",
      icon: LiverIcon,
      percentage: 100,
      color: "danger",
    },
    {
      title: "Gynaecology Problems",
      icon: GynecologyIcon,
      percentage: 13,
      color: "success",
    },
    {
      title: "Cancer Risks",
      icon: CancerIcon,
      percentage: 18,
      color: "success",
    },
    {
      title: "Diabetes & Thyroid",
      icon: DiabetesIcon,
      percentage: 100,
      color: "danger",
    },
    {
      title: "Physical Injury & Fall Risk",
      icon: InjuryIcon,
      percentage: 10,
      color: "success",
    },
    {
      title: "Osteoporosis & Bone Risk",
      icon: BoneIcon,
      percentage: 41,
      color: "success",
    },
    {
      title: "Pregnancies Problems",
      icon: PregnancyIcon,
      percentage: 10,
      color: "success",
    },
    {
      title: "Vision Problems",
      icon: VisionIcon,
      percentage: 30,
      color: "success",
    },
    { title: "ENT Problems", icon: EntIcon, percentage: 9, color: "warning" },
    {
      title: "Infertility Problems",
      icon: InfertilityIcon,
      percentage: 40,
      color: "success",
    },
  ]
  return (
    <Container fluid>
      <Row>
        <Col>
          <h5 className="mb-4">Disease burden in select geography</h5>
        </Col>
        <Col lg="2" className="flex-end">
          <Button
            size="10"
            className="mb-3"
            style={{ color: "white", backgroundColor: "#F86F03" }}
          >
            Export Report
          </Button>
        </Col>
      </Row>

      <Row>
        {diseases?.map((disease, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} xl={3} className="mb-2">
            <DiseaseCard {...disease} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default DiseaseBurden
