import React from "react"
import { Container, Card, CardHeader, CardBody, Row, Col } from "reactstrap"

const ViraltrendComp1 = () => {
  const vitalSigns = [
    {
      name: "Heart Rate",
      // value: "72 bpm",
      // time: "10:00 AM",
      // date: "2024-08-12",
    },
    {
      name: "Blood Pressure",
      // value: "120/80 mmHg",
      // time: "10:15 AM",
      // date: "2024-08-12",
    },
    {
      name: "Respiratory Rate",
      // value: "18 breaths/min",
      // time: "10:30 AM",
      // date: "2024-08-12",
    },
    {
      name: "Body Temperature",
      // value: "98.6°F",
      // time: "10:45 AM",
      // date: "2024-08-12",
    },
    {
      name: "Oxygen Saturation",
      // value: "99%",
      // time: "11:00 AM",
      // date: "2024-08-12",
    },
    {
      name: "Blood Sugar",
      // value: "100 mg/dL",
      // time: "11:15 AM",
      // date: "2024-08-12",
    },
  ]

  const cardStyle = {
    border: "1px solid #ced4da", // Adding a light gray border
    borderRadius: "8px", // Rounded corners
    marginBottom: "1rem", // Space between cards
  }

  const headerStyle = {
    // backgroundColor: "#f8f9fa", // Light gray background for header
    // borderBottom: "1px solid #ced4da", // Border between header and body
    // fontWeight: "bold", // Bold text for header
    margin: 5,
  }

  const bodyStyle = {
    padding: "1rem", // Adding padding inside the card body
    border: "1px solid  #e5e8eb",
    // borderRadius: 7,
  }

  const formGroupStyle = {
    border: "1px solid  #e5e8eb",
    // borderRadius: 7,
  }

  return (
    <>
      <Container fluid className="m-2">
        {vitalSigns.map((sign, index) => (
          <Card key={index} style={cardStyle}>
            <h6 style={headerStyle}>{sign.name}</h6>
            <CardBody style={bodyStyle}>
              <Row>
                <Col md={12} sm={6} style={formGroupStyle}>
                  <h7>Value:</h7> {sign.value}
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={6} style={formGroupStyle}>
                  <h7>Time:</h7> {sign.time}
                </Col>
              </Row>
              <Row>
                <Col md={12} sm={6} style={formGroupStyle}>
                  <h7>Date:</h7> {sign.date}
                </Col>
              </Row>
            </CardBody>
          </Card>
        ))}
      </Container>
    </>
  )
}

export default ViraltrendComp1

// import React from "react"
// import {
//   Card,
//   CardBody,
//   CardTitle,
//   Form,
//   FormGroup,
//   Label,
//   Input,
//   Row,
//   Col,
// } from "reactstrap"

// const ViraltrendComp1 = () => {
//   const metrics = [
//     "Heart Rate",
//     "Blood Pressure",
//     "Respiratory Rate",
//     "Body Temperature",
//     "Oxygen Saturation",
//     "Blood Sugar",
//   ]

//   const MetricCard = ({ title }) => (
//     <Card className="mb-3">
//       <CardBody>
//         <CardTitle tag="h5">{title}</CardTitle>
//         <Form>
//           <FormGroup>
//             <Label for={`${title.replace(/\s+/g, "")}`}>{title}</Label>
//             <Input
//               type="text"
//               name={`${title.replace(/\s+/g, "")}`}
//               id={`${title.replace(/\s+/g, "")}`}
//               placeholder={`Enter ${title}`}
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label for={`${title.replace(/\s+/g, "")}Time`}>Time</Label>
//             <Input
//               type="time"
//               name={`${title.replace(/\s+/g, "")}Time`}
//               id={`${title.replace(/\s+/g, "")}Time`}
//             />
//           </FormGroup>
//           <FormGroup>
//             <Label for={`${title.replace(/\s+/g, "")}Date`}>Date</Label>
//             <Input
//               type="date"
//               name={`${title.replace(/\s+/g, "")}Date`}
//               id={`${title.replace(/\s+/g, "")}Date`}
//             />
//           </FormGroup>
//         </Form>
//       </CardBody>
//     </Card>
//   )

//   return (
//     <div className="health-metrics-form">
//       <Row>
//         {metrics.map((metric, index) => (
//           <Col md="6" key={index}>
//             <MetricCard title={metric} />
//           </Col>
//         ))}
//       </Row>
//     </div>
//   )
// }

// export default ViraltrendComp1
