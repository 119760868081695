import React from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"

const SystematicExaminationView = () => {
  const recordData = {
    facilityName: "SANJEEVANI HOSPITAL",
    nbsuRegNo: "564638",
    rchNo: "2323232323",
    doctorInCharge: "Dr. Aditi Sharma",
    cardiovascular: "Normal heart sounds, no murmurs",
    perAbdomen: "Soft, non-tender, no organomegaly",
    respiratory: "Clear lungs, normal breath sounds",
    cns: "Cognitive functions normal, no motor deficits",
    otherFindings: "Patient is alert and oriented, no acute distress",
  }

  return (
    <Container className="my-4 systematic-examination">
      <style>
        {`
          .systematic-examination .form-control {
            background-color: #f8f9fa;
          }

          .systematic-examination label {
            font-weight: bold;
          }

          @media print {
            body { background-color: white; }
            .systematic-examination { width: 100%; margin: 0; padding: 0; }
            .card { border: none; box-shadow: none; }
            .card-header { background-color: white !important; color: black !important; padding: 0.5rem 0; }
            .card-body { padding: 0.5rem 0; }
            .form-control { border: none; background-color: white; padding: 0.2rem 0; }
            .col-md-6 { flex: 0 0 50%; max-width: 50%; }
            h4 { margin-top: 1rem; margin-bottom: 0.5rem; }
            .no-print { display: none; }
          }
        `}
      </style>
      <Card>
        <CardHeader className="bg-primary text-white text-center">
          <h2 style={{ fontSize: window.innerWidth < 768 ? 14 : 20 }}>
            NEWBORN STABLIZATION UNIT
          </h2>
          <h3 style={{ fontSize: window.innerWidth < 768 ? 14 : 20 }}>
            NEWBORN CASE RECORD SHEET
          </h3>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Facility Name</Label>
                  <Input type="text" value={recordData.facilityName} readOnly />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>NBSU Reg No.</Label>
                  <Input type="text" value={recordData.nbsuRegNo} readOnly />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>RCH No.</Label>
                  <Input type="text" value={recordData.rchNo} readOnly />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Doctor in Charge</Label>
                  <Input
                    type="text"
                    value={recordData.doctorInCharge}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>

            <h4 className="mt-4">Systematic Examination</h4>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Cardiovascular (CVS) examination</Label>
                  <Input
                    type="textarea"
                    rows="3"
                    value={recordData.cardiovascular}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Per Abdomen</Label>
                  <Input
                    type="textarea"
                    rows="3"
                    value={recordData.perAbdomen}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Respiratory</Label>
                  <Input
                    type="textarea"
                    rows="3"
                    value={recordData.respiratory}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>CNS (Central Nervous System)</Label>
                  <Input
                    type="textarea"
                    rows="3"
                    value={recordData.cns}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Other Significant Findings</Label>
                  <Input
                    type="textarea"
                    rows="4"
                    value={recordData.otherFindings}
                    readOnly
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      {/* <div className="mt-3 text-center no-print">
        <button className="btn btn-primary" onClick={() => window.print()}>
          Print Record
        </button>
      </div> */}
    </Container>
  )
}

export default SystematicExaminationView
