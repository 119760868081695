import React, { useState } from "react"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Badge,
} from "reactstrap"
// for range slider
import "react-rangeslider/lib/index.css"
import { Form, Link } from "react-router-dom"
import { toast } from "react-toastify"
import Select from "react-select"

const maritalStatusOptions2 = ["Yes", "No"]
const antibioticresistance = ["Yes", "No"]
const lossofAppetite = ["Yes", "No"]
const VisionOptions = ["Yes", "No"]
const Recentallergies = ["Yes", "No"]
const alternativeTherapiesOptions = ["Yes", "No"]
// const alternativeTherapiesOptions = ["Yes", "No"]

const allergies = [
  "Major depression or other mood disorders",
  "Suicidal thoughts or behavior",
  "Anxiety disorders, including social anxiety disorder (social phobia)",
  "Obsessive-compulsive disorder",
  "Eating disorder",
  "Substance misuse",
  "Health problems from behaviors such as skin picking",
  "Physical pain or risk of disfigurement due to repeated surgical interventions",
]

const CognitiveProfileData = [
  "Poor judgments and decisions a lot of the time",
  "Face problems taking care of monthly bills",
  "Lose track of the date or time of year",
  "Face trouble having a conversation",
  "Misplace things often and unable to find them",
  "Face difficulty in recalling your past",
  "Forget things/events quite frequently",
  "Ask the same questions repeatedly",
  "Face confusion and disorientation",
  "Feel lost while driving/walking or talking",
]

const WalkingProfileData = [
  "Walk with the head and neck bent over",
  "Drag, drop, or shuffle the feet",
  "Irregular, jerky movements while walking",
  "Take shorter/smaller steps",
  "Waddle (moving the weight of the body from one side to the other)",
  "Walk more slowly or stiffly",
  "Walk with the knees and thighs crossing or hitting each other like scissors",
  "Walk with the toes scraping the ground (toes point downward)",
  "Drag one foot in a semi-circle to complete a long step",
]

const SensationProfileData = [
  "Loss of balance",
  "Sudden confusion",
  "Sudden, severe headache with no known cause",
  "Sudden weakness on one side of the body",
  "Trouble seeing",
]
const SensationProfileData2 = ["At rest", "While moving", "While sleeping"]
const SensationProfileData3 = ["All the day", "It comes and goes"]
const SensationProfileData4 = [
  "Deep under the skin",
  "Across the top of the skin",
]
const SensationProfileData5 = [
  "Loss of balance",
  "Sudden confusion",
  "Sudden, severe headache with no known cause",
  "Sudden weakness on one side of the body",
  "Trouble seeing",
  "At rest",
  "While moving",
  "While sleeping",
  "All the day",
  "It comes and goes",
  "Deep under the skin",
  "Across the top of the skin",
]

const VisionProfileData = [
  "6/6 with glasses",
  "6/6 without glasses/Normal vision",
  "Night blindness",
  "Glaucoma",
  "Cataract",
  "Color blindness",
  "Floaters",
  "Crossed eyes",
  "Eye pain",
  "Eye pressure",
  "Eye redness",
  "Lazy eye",
  "Blurred vision",
  "Double vision",
  "Light sensitivity",
  "Dry eyes",
  "Watery eyes",
]

const HairProfileData = [
  "Dandruff",
  "Hair loss",
  "Dry hair",
  "Split ends",
  "Oily/greasy hair",
  "Frizzy hair",
  "Dull hair",
  "Heat/color damaged hair",
  "Early greying",
  "Early baldness",
  "Hair loss at the hairline",
]

const SpeechProfile = [
  "Fluency disorder",
  "Voice disorder",
  "Articulation disorder",
]

const NoseProfileData = [
  "Allergies",
  "Smell and Taste disorders",
  "Deviated septum",
  "Nasal congestion",
  "Nasal fractures",
  "Nosebleeds",
  "Rhinitis",
  "Sinus infections (sinusitis)",
]
const OralDentalProfileData = [
  "Ulcers, sores, or tender areas in the mouth that won't heal after a week or two",
  "Bleeding or swollen gums after brushing or flossing",
  "Chronic bad breath",
  "Sudden sensitivity to hot and cold temperatures or beverages",
  "Pain or toothache",
  "Loose teeth",
  "Receding gums",
  "Pain with chewing or biting",
  "Swelling of the face and cheek",
  "Clicking of the jaw",
  "Cracked or broken teeth",
  "Frequent dry mouth",
]

const SoreThroatProfile = [
  "Sore throat",
  "Frequent throat infections",
  "Swallowing disorders (Dysphagia)",
  "Tonsillitis",
  "Voice disorders",
]

const maritalStatusOptions = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
  { value: "separated", label: "Separated" },
  { value: "divorced", label: "Divorced" },
  { value: "live_in_relationship", label: "Live-in Relationship" },
]
const HeightFeetOptions = [
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
]
const HeightInchesOptions = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
]

const PhysicalExamination = () => {
  const [medicines, setMedicines] = useState([])
  const [medicineName, setMedicineName] = useState("")
  const [medicineYears, setMedicineYears] = useState("")

  const handleAddMedicine = () => {
    if (medicineName && medicineYears) {
      setMedicines([...medicines, { name: medicineName, years: medicineYears }])
      setMedicineName("")
      setMedicineYears("")
    } else {
      //   alert("required")
      toast("Both Fields Required!", {
        type: "error",
      })
    }
  }

  const handleRemoveMedicine = index => {
    const newMedicines = medicines.filter((_, i) => i !== index)
    setMedicines(newMedicines)
  }

  const [formData, setFormData] = useState({
    maritalStatus: null,
    numPregnancies: "",
    numMiscarriages: "",
    numChildren: "",
    ageLastChild: "",
    knownAllergies: [],
    familyPlanning: [],
    HeightFeetInches: "",
    weight: null,
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const [selectedAllergies, setSelectedAllergies] = useState([])

  const toggleAllergy = allergy => {
    setSelectedAllergies(prevSelected =>
      prevSelected.includes(allergy)
        ? prevSelected.filter(a => a !== allergy)
        : [...prevSelected, allergy]
    )
  }

  // Recentallergies
  const [selectedAllergyOption, setSelectedAllergyOption] = useState(null)

  const toggleAllergyOption = data => {
    setSelectedAllergyOption(data)
  }

  // Cognitive Impairment
  const [selectedCognitiveOption, setSelectedCognitiveOption] = useState(null)
  const [selectedCognitiveData, setSelectedCognitiveData] = useState([])
  const toggleCognitiveOption = data => setSelectedCognitiveOption(data)
  const toggleCognitive = values => {
    setSelectedCognitiveData(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  // Walking Impairment
  const [selectedWalkingOption, setSelectedWalkingOption] = useState(null)
  const [selectedWalkingData, setSelectedWalkingData] = useState([])
  const toggleWalkingOption = data => setSelectedWalkingOption(data)
  const toggleWalking = values => {
    setSelectedWalkingData(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  // Abnormal Body Sensation
  const [selectedSensationOption, setSelectedSensationOption] = useState(null)
  const [selectedSensationData, setSelectedSensationData] = useState([])
  const toggleSensationOption = data => setSelectedSensationOption(data)
  const toggleSensation = values => {
    setSelectedSensationData(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  // // vision Yes No
  // const [selecteVisionOptions, setSelectedVisionOptions] = useState(null)

  // const toggleVisionOptions = data => {
  //   setSelectedVisionOptions(data)
  // }
  // // Vision Data  values
  // const [selectedVisionData, setselectedVisionData] = useState([])

  // const toggleVision = values => {
  //   setselectedVisionData(prevSelected =>
  //     prevSelected.includes(values)
  //       ? prevSelected.filter(a => a !== values)
  //       : [...prevSelected, values]
  //   )
  // }

  // Vision
  const [selecteVisionOptions, setSelectedVisionOptions] = useState(null)
  const [selectedVisionData, setSelectedVisionData] = useState([])
  const toggleVisionOptions = data => setSelectedVisionOptions(data)
  const toggleVision = values => {
    setSelectedVisionData(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  // Speech
  const [selectedSpeechOption, setSelectedSpeechOption] = useState(null)
  const [selectedSpeechData, setSelectedSpeechData] = useState([])
  const toggleSpeechOption = data => setSelectedSpeechOption(data)
  const toggleSpeech = values => {
    setSelectedSpeechData(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  // Hair
  const [selectedHairOption, setSelectedHairOption] = useState(null)
  const [selectedHairData, setSelectedHairData] = useState([])
  const toggleHairOption = data => setSelectedHairOption(data)
  const toggleHair = values => {
    setSelectedHairData(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  // Nose
  const [selectedNoseOption, setSelectedNoseOption] = useState(null)
  const [selectedNoseData, setSelectedNoseData] = useState([])
  const toggleNoseOption = data => setSelectedNoseOption(data)
  const toggleNose = values => {
    setSelectedNoseData(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  // Oral/Dental
  const [selectedOralOption, setSelectedOralOption] = useState(null)
  const [selectedOralData, setSelectedOralData] = useState([])
  const toggleOralOption = data => setSelectedOralOption(data)
  const toggleOral = values => {
    setSelectedOralData(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  // Throat
  const [selectedThroatOption, setSelectedThroatOption] = useState(null)
  const [selectedThroatData, setSelectedThroatData] = useState([])
  const toggleThroatOption = data => setSelectedThroatOption(data)
  const toggleThroat = values => {
    setSelectedThroatData(prevSelected =>
      prevSelected.includes(values)
        ? prevSelected.filter(a => a !== values)
        : [...prevSelected, values]
    )
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          {submittedData && (
            <>
              <h5>Physical Examination</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Height (feet and inches)</Label>
                    <div>A.- {submittedData?.height || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any mental health problem?</Label>
                    <div>A.- {submittedData?.mentalHealthProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Mental health & Wellbeing</Label>
                    <div>
                      A.- {submittedData?.mentalHealthWellbeing || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any cognitive impairment?</Label>
                    <div>A.- {submittedData?.cognitiveImpairment || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Rate your dietary adherence as prescribed by your
                      doctors (scale of 10)
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>

                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any speech impairment?</Label>
                    <div>A.- {submittedData?.speechImpairment || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Speech Profile</Label>
                    <div>
                      A.-{" "}
                      {submittedData?.speechProfile
                        ?.map(profile => profile.label)
                        .join(", ") || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any hair problem?</Label>
                    <div>A.- {submittedData?.hairProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Hair Profile</Label>
                    <div>
                      A.-{" "}
                      {submittedData?.hairProfile
                        ?.map(profile => profile.label)
                        .join(", ") || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any nose-related problem?</Label>
                    <div>A.- {submittedData?.noseProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Nose Profile</Label>
                    <div>
                      A.-{" "}
                      {submittedData?.noseProfile
                        ?.map(profile => profile.label)
                        .join(", ") || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Do you have any oral/dental health problem?
                    </Label>
                    <div>A.- {submittedData?.oralDentalProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Oral/Dental Profile</Label>
                    <div>
                      A.-{" "}
                      {submittedData?.oralDentalProfile
                        ?.map(profile => profile.label)
                        .join(", ") || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any throat problem?</Label>
                    <div>A.- {submittedData?.throatProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Throat Profile</Label>
                    <div>
                      A.-{" "}
                      {submittedData?.throatProfile
                        ?.map(profile => profile.label)
                        .join(", ") || "N/A"}
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col>

        <Col md={6}>
          <h5>Physical Examination</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup style={formGroupStyle}>
                  <FormGroup>
                    <Label for="Heightandfeet">Height (in feet)</Label>
                    <Select
                      id="Heightandfeet"
                      name="HeightFeetInches"
                      options={HeightFeetOptions}
                      onChange={handleChange}
                      value={formData.HeightFeetInches}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="maritalStatus">Height (in inches)</Label>
                    <Select
                      id="maritalStatus"
                      name="maritalStatus"
                      options={HeightInchesOptions}
                      onChange={handleChange}
                      value={formData.maritalStatus}
                    />
                  </FormGroup>
                </FormGroup>
                <FormGroup>
                  <Label for="Weight">Weight (Kgs)</Label>
                  <Input
                    type="number"
                    id="Weight"
                    name="weight"
                    placeholder="Weight (Kgs)"
                    value={formData.weight}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="numPregnancies">
                    Do you have any mental health problem?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Recentallergies?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergyOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleAllergyOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedAllergyOption === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus">
                      {" "}
                      Mental health & Wellbeing
                    </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {allergies?.map(allergy => (
                        <Col key={allergy} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAllergies.includes(allergy)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAllergy(allergy)}
                            className="m-1"
                          >
                            {allergy}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}
                {/* cognitive impairment */}
                <FormGroup>
                  <Label for="cognitiveImpairment">
                    Do you have any cognitive impairment?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {alternativeTherapiesOptions.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedCognitiveOption === data
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleCognitiveOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedCognitiveOption === "Yes" && (
                  <FormGroup>
                    <Label for="cognitiveProfile">Cognitive Profile</Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {CognitiveProfileData.map(values => (
                        <Col key={values} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedCognitiveData.includes(values)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleCognitive(values)}
                            className="m-1"
                          >
                            {values}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label for="walkingImpairment">
                    Do you have any walking impairment?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {alternativeTherapiesOptions.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedWalkingOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleWalkingOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedWalkingOption === "Yes" && (
                  <FormGroup>
                    <Label for="walkingProfile">Walking Profile</Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {WalkingProfileData.map(values => (
                        <Col key={values} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedWalkingData.includes(values)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleWalking(values)}
                            className="m-1"
                          >
                            {values}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                {/* body sensation */}
                <FormGroup>
                  <Label for="bodySensation">
                    Do you feel any abnormal body sensation?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {alternativeTherapiesOptions.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedSensationOption === data
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleSensationOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedSensationOption === "Yes" && (
                  <>
                    <Card style={formGroupStyle}>
                      <FormGroup>
                        <Label for="sensationProfile">
                          Sensation Profile - Where do you feel the change in
                          sensation?
                        </Label>
                        <Row className="g-1" style={formGroupStyle}>
                          {SensationProfileData.map(values => (
                            <Col key={values} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedSensationData.includes(values)
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() => toggleSensation(values)}
                                className="m-1"
                              >
                                {values}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Label for="sensationProfile">
                          Sensation Profile - What makes your symptoms feel
                          worse or better?
                        </Label>
                        <Row className="g-1" style={formGroupStyle}>
                          {SensationProfileData2.map(values => (
                            <Col key={values} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedSensationData.includes(values)
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() => toggleSensation(values)}
                                className="m-1"
                              >
                                {values}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Label for="sensationProfile">
                          Sensation Profile - Where do the sensation occur most
                          often?
                        </Label>
                        <Row className="g-1" style={formGroupStyle}>
                          {SensationProfileData3.map(values => (
                            <Col key={values} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedSensationData.includes(values)
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() => toggleSensation(values)}
                                className="m-1"
                              >
                                {values}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                      <FormGroup>
                        <Label for="sensationProfile">
                          Sensation Profile - Where do you feel the change in
                          sensation?
                        </Label>
                        <Row className="g-1" style={formGroupStyle}>
                          {SensationProfileData4.map(values => (
                            <Col key={values} xs="auto">
                              <Button
                                size="sm"
                                color={
                                  selectedSensationData.includes(values)
                                    ? "primary"
                                    : "light"
                                }
                                onClick={() => toggleSensation(values)}
                                className="m-1"
                              >
                                {values}
                              </Button>
                            </Col>
                          ))}
                        </Row>
                      </FormGroup>
                    </Card>
                  </>
                )}

                <FormGroup>
                  <Label for="numPregnancies">
                    Do you have any vision problem?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VisionOptions?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selecteVisionOptions === data ? "primary" : "light"
                          }
                          onClick={() => toggleVisionOptions(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selecteVisionOptions === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus">Vision Profile</Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {VisionProfileData.map(values => (
                        <Col key={values} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedVisionData.includes(values)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleVision(values)}
                            className="m-1"
                          >
                            {values}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label>Do you have any speech impairment?</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VisionOptions.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedSpeechOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleSpeechOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedSpeechOption === "Yes" && (
                  <FormGroup>
                    <Label>Speech Profile</Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {SpeechProfile.map(values => (
                        <Col key={values} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedSpeechData.includes(values)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleSpeech(values)}
                            className="m-1"
                          >
                            {values}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label>Do you have any hair problem?</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VisionOptions.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedHairOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleHairOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedHairOption === "Yes" && (
                  <FormGroup>
                    <Label>Hair Profile</Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {HairProfileData.map(values => (
                        <Col key={values} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedHairData.includes(values)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleHair(values)}
                            className="m-1"
                          >
                            {values}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label>Do you have any oral/dental health problem?</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VisionOptions.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedOralOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleOralOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedOralOption === "Yes" && (
                  <FormGroup>
                    <Label>Oral/Dental Profile</Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {OralDentalProfileData.map(values => (
                        <Col key={values} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedOralData.includes(values)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleOral(values)}
                            className="m-1"
                          >
                            {values}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label>Do you have any nose related problem?</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VisionOptions.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedNoseOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleNoseOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedNoseOption === "Yes" && (
                  <FormGroup>
                    <Label>Nose Profile</Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {NoseProfileData.map(values => (
                        <Col key={values} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedNoseData.includes(values)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleNose(values)}
                            className="m-1"
                          >
                            {values}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label>Do you have any throat problem?</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VisionOptions.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedThroatOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleThroatOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedThroatOption === "Yes" && (
                  <FormGroup>
                    <Label>Throat Profile</Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {SoreThroatProfile.map(values => (
                        <Col key={values} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedThroatData.includes(values)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleThroat(values)}
                            className="m-1"
                          >
                            {values}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <Button type="submit" color="primary" style={{}}>
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  // border: "2px solid  #FF7738",
  borderRadius: 7,
  padding: 10,
}

export default PhysicalExamination
