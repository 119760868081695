// Dashboard.js
import React from "react"
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap"
import DataCard from "./DataCard"

const DataAtGlance = () => {
  const data = [
    {
      icon: <i className="bx bxs-building-house"></i>,
      title: "No. of Households Surveyed",
      value: "23,058",
      color: "#007bff",
    },
    {
      icon: <i className="bx bxs-group"></i>,
      title: "Population Screed",
      value: "3,25,325",
      color: "#28a745",
    },
    {
      icon: <i className="bx bxs-virus"></i>,
      title: "Population living with Diseases",
      value: "57,625",
      color: "#dc3545",
    },
    {
      // icon: <i className="bx bxs-syringe"></i>,
      icon: <i className="bx bxs-building-house"></i>,

      title: "Immunization Coverage",
      value: "44%",
      color: "#ffc107",
    },
    {
      icon: <i className="bx bxs-heart"></i>,
      title: "Chronic Kidney Problems",
      value: "45",
      color: "#6f42c1",
    },
    {
      icon: <i className="bx bxs-heart"></i>,
      title: "Chronic Liver Problems",
      value: "95",
      color: "#e83e8c",
    },
    {
      // icon: <i className="bx bxs-female"></i>,
      icon: <i className="bx bxs-building-house"></i>,

      title: "Gynaecology Problems",
      value: "65",
      color: "#fd7e14",
    },
    {
      // icon: <i className="bx bxs-droplet"></i>,
      icon: <i className="bx bxs-building-house"></i>,

      title: "Cancer Risks",
      value: "45",
      color: "#20c997",
    },
    {
      // icon: <i className="bx bxs-diagnosis"></i>,
      icon: <i className="bx bxs-building-house"></i>,

      title: "Diabetes & Thyroid",
      value: "45",
      color: "#6610f2",
    },
    {
      // icon: <i className="bx bxs-wheelchair"></i>,
      icon: <i className="bx bxs-building-house"></i>,

      title: "Physical Injury & Fall Risk",
      value: "45",
      color: "#6c757d",
    },
  ]

  return (
    <Container fluid>
      <Card>
        <CardBody>
          {/* <h5 className="my-1 ms-2">Data At a Glance</h5> */}
          <CardTitle
            tag="h3"
            className="my-1 mb-2"
            style={{
              fontSize: 18,
              //   textDecoration: "underline",
              //   textDecorationStyle: "",
              // textDecorationLine:"grammar-error",
            }}
          >
            Data At a Glance
          </CardTitle>
          <hr
            style={{
              border: "none",
              borderBottom: "2px solid black",
              margin: "20px 0",
            }}
          />

          <Row>
            {data.map((item, index) => (
              <Col
                xs="12"
                sm="6"
                md="3"
                // md="4"
                // // lg={3}
                // // xl={3}
                lg="3"
                xl="3"
                className="mb-4"
                key={index}
              >
                <DataCard {...item} />
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </Container>
  )
}

export default DataAtGlance
