import React from "react"
import { Card, CardBody, Row, Col, Container } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"

const DiseaseIndicator = ({ label, value }) => (
  <Card className="bg-light mb-2">
    <CardBody
      className="text-center"
      style={{
        backgroundColor: "rgba(82, 95, 225, 0.2)",
        borderRadius: "10px",
      }}
    >
      <div className="small text-muted">{label}</div>
      <div className="h4 mb-0">{value}</div>
    </CardBody>
  </Card>
)

const StateRanking = ({ currentRank, lastYearRank, change }) => (
  <Card className="bg-light mb-2">
    <CardBody
      style={{
        backgroundColor: "rgba(82, 95, 225, 0.2)",
        borderRadius: "10px",
      }}
    >
      <div className="small">State Ranking</div>
      <div>
        Current Rank:{" "}
        <strong className="text-primary fs-6">#{currentRank}</strong>
      </div>
      <div className="small text-muted">Last Year: #{lastYearRank}</div>
      <div className={`small ${change > 0 ? "text-success" : "text-danger"}`}>
        Change: {change > 0 ? "+" : ""}
        {change}%
      </div>
    </CardBody>
  </Card>
)

const ChangeIndicator = ({ value }) => (
  <Card
    style={{
      // backgroundColor: "rgba(82, 95, 225, 0.2)",
      border: "2px solid",
    }}
    // className={`text-white ${value > 0 ? "bg-success" : "bg-danger"}`}
    className={` ${value > 0 ? "text-success" : "text-danger"}`}
  >
    <CardBody className="text-center">
      <div className="h3 mb-0">
        {value > 0 ? "↑" : "↓"}
        {Math.abs(value)}%
      </div>
    </CardBody>
  </Card>
)

const DiseaseIndicatorsCard = () => {
  return (
    <Container fluid className="p-3">
      <Card className="mb-3">
        <CardBody>
          <h5 className="card-title mb-3">
            Disease Indicators - Nation Wise Result
          </h5>
          <Row>
            <Col md={9}>
              <Row>
                <Col sm={3}>
                  <DiseaseIndicator label="Prevalence Rate" value="11%" />
                </Col>
                <Col sm={3}>
                  <DiseaseIndicator label="Recovery Rate" value="11%" />
                </Col>
                <Col sm={3}>
                  <DiseaseIndicator label="Mortality Rate" value="11%" />
                </Col>
                <Col sm={3}>
                  <DiseaseIndicator label="Case Fatality Rate" value="11%" />
                </Col>
              </Row>
            </Col>
            <Col md={2}>
              <StateRanking currentRank={14} lastYearRank={17} change={3} />
            </Col>
            <Col md={1}>
              <ChangeIndicator value={3} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col md={9}>
              <Row>
                <Col sm={3}>
                  <DiseaseIndicator label="Prevalence Rate" value="11%" />
                </Col>
                <Col sm={3}>
                  <DiseaseIndicator label="Recovery Rate" value="11%" />
                </Col>
                <Col sm={3}>
                  <DiseaseIndicator label="Mortality Rate" value="11%" />
                </Col>
                <Col sm={3}>
                  <DiseaseIndicator label="Case Fatality Rate" value="11%" />
                </Col>
              </Row>
            </Col>
            <Col md={2}>
              <StateRanking currentRank={17} lastYearRank={14} change={-3} />
            </Col>
            <Col md={1}>
              <ChangeIndicator value={-3} />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  )
}

export default DiseaseIndicatorsCard
