import React from "react"
import WelcomeCard from "./comp/WelcomeCard"

const HealthTracker = () => {
  return (
    <>
      <WelcomeCard />
    </>
  )
}

export default HealthTracker
