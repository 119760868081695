import React from "react"
import { Card, CardBody, Row, Col } from "reactstrap"
import logo from "../../assets/images/kcc_logo.png"

const NewbornCaseRecordHeader = () => {
  const formGroupStyle = {
    border: "2px solid  #e5e8eb",
    // border: "1px solid grey",
    borderRadius: 7,
    padding: 10,
  }
  return (
    <div className="mb-4" style={formGroupStyle}>
      <Row className="align-items-center">
        <Col xs="2">
          <img
            src={logo}
            alt="Left Logo"
            className="img-fluid"
            style={{ maxHeight: "100px" }}
            // style={{ maxHeight: window.innerWidth < 768 ? "80px" : "100px" }}
          />
        </Col>
        <Col xs="8" className="text-center">
          <h2
            className="mb-1 "
            style={{
              color: "red",
              fontSize: window.innerWidth < 768 ? 18 : 24,
            }}
          >
            NEWBORN STABLIZATION UNIT
          </h2>
          <h4
            className="mb-2"
            style={{
              color: "black",
              fontSize: window.innerWidth < 768 ? 16 : 20,
            }}
          >
            NEWBORN CASE RECORD SHEET
          </h4>
          <h5
            style={{
              color: "black",
              fontSize: window.innerWidth < 768 ? 14 : 20,
            }}
            className=""
          >
            FACILITY NAME:
            <span style={{ color: "grey", fontWeight: "bold" }}>
              &nbsp;NBSU NOIDA
            </span>{" "}
          </h5>
        </Col>
        <Col xs="2">
          <img
            src={logo}
            alt="Left Logo"
            className="img-fluid"
            style={{ maxHeight: "100px" }}
            // style={{ maxHeight: window.innerWidth < 768 ? "80px" : "100px" }}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="4">
          <p style={{ color: "black" }}>
            <strong>NBSU REG No.:</strong> 8787878787
          </p>
        </Col>
        <Col md="4">
          <p style={{ color: "black" }}>
            <strong>DOCTOR IN CHARGE:</strong> Rajesh K Chawla
          </p>
        </Col>
        <Col md="4">
          <p style={{ color: "black" }}>
            <strong>RCH No.:</strong> 987656700
          </p>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <p style={{ color: "black" }}>
            <strong>Mother's Name:</strong> vimla devi
          </p>
        </Col>
        <Col md="4">
          <p style={{ color: "black" }}>
            <strong>Date of Admission:</strong> 2024-05-17
          </p>
        </Col>
      </Row>
    </div>
  )
}

export default NewbornCaseRecordHeader
