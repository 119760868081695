import React, { useState, useRef, useEffect } from "react"
import { Button, Card, Input } from "reactstrap"
import flvjs from "flv.js"

const Rtmp_player = () => {
  const videoRef = useRef(null)

  useEffect(() => {
    if (flvjs.isSupported()) {
      const flvPlayer = flvjs.createPlayer({
        type: "flv",
        url: "http://192.168.1.4:8080/live/kcc_rtmp.flv",
      })
      flvPlayer.attachMediaElement(videoRef.current)
      flvPlayer.load()
      flvPlayer.play()
    }
  }, [])

  return <video ref={videoRef} width="100%" height="100%" controls />
}

const Rtmp_player1 = ({ defaultUrl = "" }) => {
  const [streamUrl, setStreamUrl] = useState(defaultUrl)

  const handleInputChange = event => {
    setStreamUrl(event.target.value)
  }

  const openStreamInNewTab = () => {
    if (streamUrl) {
      window.open(streamUrl, "_blank")
    } else {
      alert("Please enter a valid live stream URL.")
    }
  }

  const openStreamInPopup = () => {
    if (streamUrl) {
      window.open(
        streamUrl,
        "LiveStreamPopup",
        "width=800,height=600,resizable,scrollbars"
      )
    } else {
      alert("Please enter a valid live stream URL.")
    }
  }

  return (
    <Card style={cardStyle}>
      <h3 className="mt-3">Live Stream Player</h3>
      <Input
        type="text"
        value={streamUrl}
        onChange={handleInputChange}
        placeholder="Enter live stream URL"
        style={inputStyle}
        required
      />
      <Button onClick={openStreamInPopup} style={buttonStyle} color="primary">
        Open Live Stream in Popup
      </Button>
      <Button
        onClick={openStreamInNewTab}
        style={buttonStyle}
        color="secondary"
      >
        Open Live Stream in New Tab
      </Button>

      {streamUrl && (
        <div style={{ marginTop: "20px" }}>
          <FlvVideoPlayer streamUrl={streamUrl} />
        </div>
      )}
    </Card>
  )
}

const Rtmp_player2 = ({ defaultUrl = "" }) => {
  const videoRef = useRef(null)
  const [streamUrl, setStreamUrl] = useState(defaultUrl)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    let flvPlayer

    if (flvjs.isSupported() && streamUrl) {
      try {
        flvPlayer = flvjs.createPlayer({
          type: "flv",
          url: streamUrl.replace("rtmp://", "http://") + ".flv", // Adjusting for FLV
        })

        // Error event listener
        flvPlayer.on(flvjs.Events.ERROR, error => {
          console.error("FLV Player Error:", error)
          setErrorMessage(
            "Unable to play the stream. Error: " + JSON.stringify(error)
          )
        })

        // Ensure videoRef.current is valid before attaching
        if (videoRef.current) {
          flvPlayer.attachMediaElement(videoRef.current)
          flvPlayer.load()
          flvPlayer.play()
        } else {
          setErrorMessage("Video element not found.")
        }
      } catch (error) {
        console.error("Error initializing FLV player:", error)
        setErrorMessage(
          "Failed to initialize the video player. Please check the console for more details."
        )
      }
    } else {
      setErrorMessage("FLV.js is not supported or URL is empty.")
    }

    return () => {
      if (flvPlayer) {
        flvPlayer.destroy() // Clean up the player on unmount
      }
    }
  }, [streamUrl])

  const handleInputChange = event => {
    setStreamUrl(event.target.value)
    setErrorMessage("") // Reset error message on input change
  }

  const openStreamInNewTab = () => {
    if (streamUrl) {
      window.open(streamUrl, "_blank")
    } else {
      alert("Please enter a valid live stream URL.")
    }
  }

  const openStreamInPopup = () => {
    if (streamUrl) {
      window.open(
        streamUrl,
        "LiveStreamPopup",
        "width=800,height=600,resizable,scrollbars"
      )
    } else {
      alert("Please enter a valid live stream URL.")
    }
  }

  return (
    <Card>
      <h3 className="mt-3">Live Stream Player</h3>
      <Input
        type="text"
        value={streamUrl}
        onChange={handleInputChange}
        placeholder="Enter RTMP live stream URL"
        style={inputStyle}
        required
      />
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
      <Button onClick={openStreamInPopup} style={buttonStyle} color="primary">
        Open Live Stream in Popup
      </Button>
      <Button
        onClick={openStreamInNewTab}
        style={buttonStyle}
        color="secondary"
      >
        Open Live Stream in New Tab
      </Button>
      <video ref={videoRef} width="100%" height="100%" controls />
    </Card>
  )
}

const FlvVideoPlayer = ({ streamUrl }) => {
  const videoRef = React.useRef(null)

  React.useEffect(() => {
    if (flvjs.isSupported() && streamUrl) {
      const flvPlayer = flvjs.createPlayer({
        type: "flv",
        url: streamUrl,
      })
      flvPlayer.attachMediaElement(videoRef.current)
      flvPlayer.load()
      flvPlayer.play()

      return () => {
        flvPlayer.destroy() // Cleanup on unmount
      }
    }
  }, [streamUrl])

  return (
    <video
      ref={videoRef}
      width="100%"
      height="400px"
      controls
      style={videoStyle}
    >
      Your browser does not support the video tag.
    </video>
  )
}

// Optional styles
const inputStyle = {
  width: "300px",
  marginBottom: "20px",
  fontSize: "16px",
}

const buttonStyle = {
  marginRight: "10px",
  fontSize: "16px",
}

const cardStyle = {
  padding: "20px",
}

const videoStyle = {
  border: "1px solid #ccc",
  borderRadius: "10px",
}

export default Rtmp_player

// import React, { useState } from "react"
// import VideoPlayer from "./VideoPlayer"

// const Rtmp_player = () => {
//   const [url, setUrl] = useState("")

//   const handleUrlChange = e => {
//     setUrl(e.target.value)
//   }

//   return (
//     <div style={{ padding: "20px" }}>
//       <h2>Enter Stream URL</h2>

//       {/* Input field to change the URL dynamically */}
//       <input
//         type="text"
//         value={url}
//         onChange={handleUrlChange}
//         placeholder="Enter FLV Stream URL"
//         style={{ width: "100%", padding: "10px", marginBottom: "20px" }}
//       />

//       {/* VideoPlayer component with dynamic URL */}
//       {url && <VideoPlayer streamUrl={url} />}
//     </div>
//   )
// }

// export default Rtmp_player
