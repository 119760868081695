import React, { useState } from "react"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Badge,
} from "reactstrap"
// for range slider
import "react-rangeslider/lib/index.css"
import { Form, Link } from "react-router-dom"
import { toast } from "react-toastify"
import Select from "react-select"
// for range slider
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

const Options = ["Yes", "No"]
const Recentallergies = ["Yes", "No"]
const alternativeTherapiesOptions = ["Yes", "No"]
// const alternativeTherapiesOptions = ["Yes", "No"]

const labels = {
  1: "1",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
}
const allergies = ["option1", "option2", "options3", "option4", "option5"]

const maritalStatusOptions2 = [
  "option1",
  "option2",
  "options3",
  "option4",
  "option5",
]
const familyPlanningOptions2 = [
  "None",
  "option1",
  "option2",
  "options3",
  "option4",
  "option5",
]
const OccupationalType = [
  "Job (Daytime)",
  "Job (Night)",
  "Business",
  "Retired Life",
  "Home Maker",
]
const WorkEnvironment = [
  "Relaxed Office Environment",
  "Noisy and Polluted",
  "Hazardous",
  "Work From Home",
]
const TravelData = ["Frequent", "Occasional", "Seldom"]

const RecreationMethods = [
  "Sports (Indoor)",
  "Sports (Outdoor)",
  "Weekend Outing/Social Gathering",
  "TV/Cinema/Computer Games",
  "Nothing",
]

const hazardousMaterials = [
  "Liquefied Gases - Anhydrous Ammonia, Chlorine, Propane, Nitrous Oxides, Carbon Dioxide.",
  "Non-Liquefied Gases - Oxygen, Nitrogen, Helium, Argon.",
  "Dissolved Gases - Acetylene.",
  "Flammable Liquids - Ethyl Ether, Isopentane, Propylene Oxides, Acetone, Benzene, Ethyl Alcohol, Gasoline, Isopropyl Alcohol, Naphtha, Xylene, Ethylene Glycol, Glycerine.",
  "Flammable Solids - Metallic Hydrides, Sodium, Potassium, Processed Metals, Nitrocellulose products.",
  "Oxidising Substances and Organic Peroxides - Potassium Permanganate, Sodium Nitrite, Hydrogen Peroxides, Ammonium Nitrate Fertilizers, Oxygen Generators.",
  "Toxic and Infectious Substances - Cyanides, Lead Compounds, Phenols, Cresols, Pesticides, Herbicides.",
  "Radioactive Materials - Uranium, Radioactive Ores, Isotopes, Some medical equipment that emits radiation.",
  "Corrosive Substances - Sulphuric Acids, Sodium Hydroxide, Batteries and their fluids.",
]
const OccupationalProfile = () => {
  // for range slider
  const [custom_val, setcustom_val] = useState(0)
  const [custom_val2, setcustom_val2] = useState(0)

  const [formData, setFormData] = useState({
    maritalStatus: null,
    numPregnancies: "",
    numMiscarriages: "",
    numChildren: "",
    ageLastChild: "",
    knownAllergies: [],
    familyPlanning: [],
    HeightFeetInches: "",
    weight: null,
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const [selectedAllergies, setSelectedAllergies] = useState([])

  const toggleAllergy = allergy => {
    setSelectedAllergies(prevSelected =>
      prevSelected.includes(allergy)
        ? prevSelected.filter(a => a !== allergy)
        : [...prevSelected, allergy]
    )
  }

  // Recentallergies
  const [selectedAllergyOption, setSelectedAllergyOption] = useState(null)

  const toggleAllergyOption = data => {
    setSelectedAllergyOption(data)
  }

  const [selectedMaritialStatus, setSelectedMaritialStatus] = useState("")

  const toggleMaritialStatus = maritialStatus => {
    setSelectedMaritialStatus(maritialStatus)
    // console.log(maritialStatus)
  }

  const [selectedfamilyPlanning, setSelectedFamilyPlanning] = useState([])

  const toggleFamilyPlanning = familyPlanning => {
    setSelectedFamilyPlanning(prevSelected =>
      prevSelected.includes(familyPlanning)
        ? prevSelected.filter(a => a !== familyPlanning)
        : [...prevSelected, familyPlanning]
    )
  }

  const [selectedWorkEnvironment, setSelectedWorkEnvironment] = useState(null)
  const [selectedSputumTypes, setSelectedSputumTypes] = useState([])

  const toggleWorkEnvironment = environment => {
    setSelectedWorkEnvironment(environment)
  }

  const toggleSputumType = type => {
    setSelectedSputumTypes(prevSelected =>
      prevSelected.includes(type)
        ? prevSelected.filter(item => item !== type)
        : [...prevSelected, type]
    )
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          {submittedData && (
            <>
              <h5>Occupational Profile</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Occupation Type</Label>
                    <div>A.- {submittedData?.height || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Work Environment</Label>
                    <div>A.- {submittedData?.mentalHealthProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.-Travel</Label>
                    <div>
                      A.- {submittedData?.mentalHealthWellbeing || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Recreation (Methods) ?</Label>
                    <div>A.- {submittedData?.cognitiveImpairment || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Work life balance (1 - 10)</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Worries and tensions (1 - 10)</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col>

        <Col md={6}>
          <h5>Occupational Profile</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="numPregnancies">Occupation Type</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {OccupationalType?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="workEnvironment">Work Environment</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {WorkEnvironment.map(environment => (
                      <Col key={environment} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedWorkEnvironment === environment
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleWorkEnvironment(environment)}
                          className="m-1"
                        >
                          {environment}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedWorkEnvironment === "Hazardous" && (
                  <FormGroup>
                    <Label for="sputumType">
                      Please select from the following explosive and blasting
                      agents indicating hazardous environment :
                    </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {hazardousMaterials.map(type => (
                        <Col key={type} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedSputumTypes.includes(type)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleSputumType(type)}
                            className="m-1"
                          >
                            {type}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label for="maritalStatus"> Travel</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {TravelData?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {/* recreation*/}
                <FormGroup>
                  <Label for="maritalStatus">Recreation (Methods)</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {RecreationMethods?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {/* work life balance slider */}
                <FormGroup className="g-1" style={formGroupStyle}>
                  <Label for="numMiscarriages">
                    Work life balance (1 - 10)
                  </Label>
                  <Slider
                    className="g-1"
                    style={formGroupStyle}
                    value={custom_val}
                    min={1}
                    max={10}
                    labels={labels}
                    orientation="horizontal"
                    onChange={value => {
                      setcustom_val(value)
                    }}
                  />
                </FormGroup>
                <FormGroup className="g-1" style={formGroupStyle}>
                  <Label for="numMiscarriages">
                    Worries and tensions (1 - 10)
                  </Label>
                  <Slider
                    className="g-1"
                    style={formGroupStyle}
                    value={custom_val2}
                    min={1}
                    max={10}
                    labels={labels}
                    orientation="horizontal"
                    onChange={value => {
                      setcustom_val2(value)
                    }}
                  />
                </FormGroup>

                <Button type="submit" color="primary" style={{}}>
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  // border: "2px solid  #FF7738",
  borderRadius: 7,
  padding: 10,
}

export default OccupationalProfile
