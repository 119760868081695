import React, { useContext, useEffect, useState } from "react"
import {
  Container,
  Card,
  CardBody,
  Button,
  Row,
  Col,
  InputGroup,
  Label,
  Input,
  CardTitle,
} from "reactstrap"
import AdminContext from "context/adminContext"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//i18n
import { withTranslation } from "react-i18next"
import WelcomeComp from "./WelcomeComp"
import AreaWiseHealthScore from "./AreaWiseHealthScore"
// import BarChart from "../AllCharts/chartist/barchart"
import LineChart from "../AllCharts/chartjs/linechart"
import DountChart from "../AllCharts/chartjs/dountchart"
import PieChart from "../AllCharts/chartjs/piechart"
import BarChart, { BarChart2 } from "../AllCharts/chartjs/barchart"
import RadarChart from "../AllCharts/chartjs/radarchart"
import PolarChart from "../AllCharts/chartjs/polarchart"
import { Link } from "react-router-dom"
import BasicTable from "pages/Tables/BasicTables"
import DashedLine from "pages/AllCharts/apex/dashedLine"
import TapVisitors from "./comp/TapVisitors"
import DiseaseBurden from "./comp/DiseaseBurden"
import DiseaseIndicatorsCard from "./comp/DiseaseIndicator"
import GenderRatioCard from "./comp/GenderRatioCard"
import FilterModal from "./comp/FilterModal"
import DataAtGlance from "./comp/DataAtGlance"

// select
import Select from "react-select"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import HealthScoreGraph from "./HealthScoreGraph"

const Dashboard = props => {
  //meta title
  document.title = "Dashboard"

  // context
  const context = useContext(AdminContext)
  const { UserCount, CountUser } = context
  useEffect(() => {
    // UserCount()
  }, [])

  const reports = [
    {
      title: "Total User",
      //  iconClass: "bx-copy-alt",.
      iconClass: "bx bx-user",
      description: [CountUser],
    },
    // { title: "Revenue", iconClass: "bx-archive-in", description: "$35, 723" },
    // {
    //   title: "Average Price", iconClass: "bx-purchase-tag-alt", description: "$16.2",
    // },
  ]
  const optionGroup = [
    // {
    //   label: "Picnic",
    //   options: [
    //     { label: "Mustard", value: "Mustard" },
    //     { label: "Ketchup", value: "Ketchup" },
    //     { label: "Relish", value: "Relish" },
    //   ],
    // },

    { label: "Andhra Pradesh", value: "Andhra Pradesh" },
    { label: "Arunachal Pradesh", value: "Arunachal Pradesh" },
    { label: "Assam", value: "Assam" },
    { label: "Bihar", value: "Bihar" },
    { label: "Chhattisgarh", value: "Chhattisgarh" },
    { label: "Goa", value: "Goa" },
    { label: "Gujarat", value: "Gujarat" },
    { label: "Haryana", value: "Haryana" },
    { label: "Himachal Pradesh", value: "Himachal Pradesh" },
    { label: "Jharkhand", value: "Jharkhand" },
    { label: "Karnataka", value: "Karnataka" },
    { label: "Kerala", value: "Kerala" },
    { label: "Madhya Pradesh", value: "Madhya Pradesh" },
    { label: "Maharashtra", value: "Maharashtra" },
    { label: "Manipur", value: "Manipur" },
    { label: "Meghalaya", value: "Meghalaya" },
    { label: "Mizoram", value: "Mizoram" },
    { label: "Nagaland", value: "Nagaland" },
    { label: "Odisha", value: "Odisha" },
    { label: "Punjab", value: "Punjab" },
    { label: "Rajasthan", value: "Rajasthan" },
    { label: "Sikkim", value: "Sikkim" },
    { label: "Tamil Nadu", value: "Tamil Nadu" },
    { label: "Telangana", value: "Telangana" },
    { label: "Tripura", value: "Tripura" },
    { label: "Uttar Pradesh", value: "Uttar Pradesh" },
    { label: "Uttarakhand", value: "Uttarakhand" },
    { label: "West Bengal", value: "West Bengal" },
    // {
    //   label: "Camping",
    //   options: [
    //     { label: "Tent", value: "Tent" },
    //     { label: "Flashlight", value: "Flashlight" },
    //     { label: "Toilet Paper", value: "Toilet Paper" },
    //   ],
    // },
  ]
  const [selectedGroup, setselectedGroup] = useState(null)

  // on change functions
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup)
  }

  function handleClear() {
    setselectedGroup(null)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Dashboards")}
            breadcrumbItem={props.t("Home")}
          />
          {/* Primary Data Information */}
          <Row>
            <Col lg="12">
              <Card className=" bg-primary">
                <CardBody>
                  <h5 style={{ color: "#ffff" }}>Primary Data Information</h5>
                  <Row>
                    <Col lg={12}>
                      <div className="row gy-2 gx-3 align-items-center">
                        <div className="col-sm-auto">
                          <Label
                            className="visually-hidden"
                            htmlFor="autoSizingInputGroup"
                          >
                            Select date and time
                          </Label>
                          <InputGroup>
                            <div className="input-group-text">
                              <i className="bx bx-map"></i>
                            </div>
                            <Select
                              value={selectedGroup}
                              onChange={() => {
                                handleSelectGroup()
                              }}
                              options={optionGroup}
                              className="select2-selection"
                            />
                          </InputGroup>
                        </div>

                        {/* <div className="col-sm-auto">
                          <Label
                            className="visually-hidden"
                            htmlFor="autoSizingInputGroup"
                          >
                            Select date and time
                          </Label>
                          <InputGroup>
                            <div className="input-group-text">
                              <i className="bx bx-calendar"></i>
                            </div>
                            <input
                              type="date"
                              className="form-control"
                              id="autoSizingInputGroup"
                              placeholder="Select date and time"
                            />
                          </InputGroup>
                        </div> */}
                        <div className="col-sm-auto col-lg-3">
                          <Label
                            className="visually-hidden"
                            htmlFor="autoSizingInputGroup"
                          >
                            Select date and time
                          </Label>
                          <InputGroup>
                            <div className="input-group-text">
                              <i className="bx bx-calendar"></i>
                            </div>
                            <Flatpickr
                              className="form-control d-block"
                              placeholder="dd M,yyyy"
                              options={{
                                altInput: true,
                                altFormat: "F j, Y",
                                dateFormat: "Y-m-d",
                              }}
                            />
                          </InputGroup>
                        </div>

                        {/* checkbox */}
                        <div className="col-sm-auto">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="autoSizingCheck"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="autoSizingCheck"
                            >
                              Male
                            </label>
                          </div>
                        </div>
                        <div className="col-sm-auto ">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="autoSizingCheck"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="autoSizingCheck"
                            >
                              Female
                            </label>
                          </div>
                        </div>

                        {/* <div className="col-sm-auto">
                          <label
                            className="visually-hidden"
                            htmlFor="autoSizingSelect"
                          >
                            Preference
                          </label>
                          <select defaultValue="0" className="form-select">
                            <option value="0">Select Age Group...</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div> */}

                        <div className="col-sm-auto col-lg-3">
                          <Label
                            className="visually-hidden"
                            htmlFor="autoSizingInputGroup"
                          >
                            Select Age Group
                          </Label>
                          <InputGroup>
                            <div className="input-group-text">
                              <i className="bx bx-map"></i>
                            </div>
                            <Select
                              value={selectedGroup}
                              onChange={() => {
                                handleSelectGroup()
                              }}
                              options={optionGroup}
                              className="select2-selection"
                            />
                          </InputGroup>
                        </div>

                        <div className="col-sm-auto">
                          <button
                            type="submit"
                            className="btn btn-info w-md"
                            onClick={handleClear}
                          >
                            Clear Data
                          </button>
                        </div>
                      </div>
                    </Col>

                    {/* <Col lg="4" className="align-self-center">
                      <div className="text-lg-center mt-4 mt-lg-0">
                        <Row>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Total Projects
                              </p>
                              <h5 className="mb-0">48</h5>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Projects
                              </p>
                              <h5 className="mb-0">40</h5>
                            </div>
                          </Col>
                          <Col xs="4">
                            <div>
                              <p className="text-muted text-truncate mb-2">
                                Clients
                              </p>
                              <h5 className="mb-0">18</h5>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col> */}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* Data at glance */}
          <DataAtGlance />

          {/*5 Year trends of health score*/}
          <Row>
            <Col xl="3">
              {/* <AreaWiseHealthScore /> */}
              <Card>
                <HealthScoreGraph
                  value={72}
                  lastYearValue={65}
                  title="Area wise health score"
                />
              </Card>
            </Col>
            <Col lg="6">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">
                    5 Year trends of health score
                  </CardTitle>
                  <Row className="justify-content-center"></Row>
                  <BarChart2
                    // dataColors={["--bs-danger-rgb, 0.8","--bs-danger","--bs-warning","--bs-primary",   ]}
                    dataColors='["--bs-success-rgb, 0.8", "--bs-danger" ,"--bs-primary" ,"--bs-warning","--bs-danger" ,"--bs-primary" ,"--bs-warning"]'
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl="3">
              {/* <WelcomeComp /> */}
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Quick Actions</CardTitle>
                  <Row>
                    <Col sm="12">
                      <div className="mt-1">
                        <Link
                          to="#"
                          className="btn btn-primary waves-effect waves-ligh btn-sm"
                        >
                          Download Health Report{" "}
                          <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                      </div>
                      <div className="mt-4">
                        <Link
                          to="#"
                          className="btn btn-primary waves-effect waves-light btn-sm"
                        >
                          Download Health Report
                          <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                      </div>
                      <div className="mt-4">
                        <Link
                          to="#"
                          className="btn btn-primary waves-effect waves-light btn-sm"
                        >
                          Download Health Report{" "}
                          <i className="mdi mdi-arrow-right ms-1"></i>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Disease Burden */}
          {/* <AddModal/> */}
          <FilterModal />
          <Row>
            <DiseaseBurden />
          </Row>
          {/* City Data Tables */}
          <Row>
            <BasicTable />
          </Row>
          {/* Secondary Data Information */}
          <Row>
            <Col lg="12">
              <Card
              //  style={{ backgroundColor: " #525FE1" }}
              >
                <CardBody>
                  <h5 style={{ color: "#ffff" }}>Secondary Data Information</h5>
                  <Row>
                    <Col lg={12}>
                      <div className="row gy-2 gx-3 align-items-center">
                        <div className="col-sm-auto">
                          <label className="" htmlFor="autoSizingSelect">
                            Select Disease Category{" "}
                          </label>
                          <select
                            defaultValue="0"
                            className="form-select  text-primary"
                          >
                            <option value="0">
                              Neuropsychiatric and Mental Illness
                            </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="col-sm-auto">
                          <label className="" htmlFor="autoSizingSelect">
                            Select Sub Category{" "}
                          </label>
                          <select
                            defaultValue="0"
                            className="form-select  text-primary"
                          >
                            <option value="0">Air Borne </option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="col-sm-auto">
                          <label
                            // className="visually-hidden"
                            htmlFor="autoSizingSelect"
                          >
                            SubType
                          </label>
                          <select
                            defaultValue="0"
                            className="form-select text-primary"
                          >
                            <option value="0">COVID 19</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                        <div className="col-sm-auto mt-3 mx-2">
                          <button type="submit" className="btn btn-danger w-md">
                            Clear Data
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Pie Chart */}
          <Row>
            <Row>
              <Col xl="3">
                <Card className="text-center">
                  <CardBody
                    // style={{
                    //   backgroundColor: "#525FE1",

                    //   color: "#fff",
                    // }}
                    style={{
                      // backgroundColor: "#525FE1",
                      backgroundColor: "rgba(82, 95, 225, 0.2)",
                      // color: "#fff",
                      borderRadius: "10px",
                      border: "1px solid blue",
                      // minHeight: "60",
                      // minWidth: "60",
                    }}
                  >
                    <Row className="align-items-center">
                      <Col md="6" className="border-end">
                        <h6>Population</h6>
                        <h3>40.3 Lacs</h3>
                      </Col>
                      <Col md="6">
                        <h6>Area</h6>
                        <h3>140 Sq Km</h3>
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <Col md="6" className="border-end">
                        <h6>Literacy Rate</h6>
                        <h3>100%</h3>
                      </Col>
                      <Col md="6">
                        <h6>Male/Female Ratio</h6>
                        <h3>95:88</h3>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5}>
                <Card>
                  <CardBody>
                    <PieChart dataColors='["--bs-success", "#ebeff2","#FF0000" ,"#FF6B00"]' />
                    <CardTitle className="mb-4 text-center">YTD Data</CardTitle>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="4">
                <GenderRatioCard />
              </Col>
              {/* <Col lg={4}>
                <TapVisitors />
              </Col> */}
            </Row>
          </Row>
          {/* New Reported Cases - YTD */}
          <Row>
            <CardTitle
              className="mb-2  mt-3 ms-2"
              style={{
                // lineHeight: "2.5em",
                fontSize: 16,
              }}
            >
              New Reported Cases - <span className="text-info">YTD</span>
            </CardTitle>{" "}
            <Col xl="4">
              <Card className="text-center">
                <CardBody
                  style={{
                    // backgroundColor: "#525FE1",
                    // backgroundColor: "rgba(82, 95, 225, 0.2)",
                    backgroundColor: "#F8F9FC",
                    // color: "#fff",
                    borderRadius: "10px",
                    border: "1px solid blue",
                  }}
                >
                  <Row className="align-items-center">
                    <Col
                      md="5"
                      // className="border-end mx-2"
                      className="mx-2 mt-1"
                      style={colStyle}
                    >
                      <h6 style={fontSize16}>Suspected</h6>
                      <h4>40.3 Lacs</h4>
                    </Col>
                    <Col md="5" className="mx-2 mt-1" style={colStyle}>
                      <h6>Under Investigation</h6>
                      <h4>40.3 Lacs</h4>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-3">
                    <Col
                      md="5"
                      // className="border-end"
                      className="mx-2 mt-1 "
                      style={colStyle}
                    >
                      <h6>Confirmed</h6>
                      <h4>14000</h4>
                    </Col>
                    <Col
                      md="5"
                      className="mx-2 mt-1"
                      // style={{
                      //   // backgroundColor: "#525FE1",
                      //   backgroundColor: "rgba(82, 95, 225, 0.2)",
                      //   // color: "#fff",
                      //   borderRadius: "10px",
                      //   border: "1px solid blue",
                      //   // minHeight: "60",
                      //   // minWidth: "60",
                      // }}
                      style={colStyle}
                    >
                      <h6>Undiagnosed</h6>
                      <h4>7500</h4>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Dashed Line</CardTitle> */}
                  <DashedLine dataColors='["--bs-primary", "--bs-danger", "--bs-success"]' />
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">
                    5 Year trends of health score
                  </CardTitle> */}
                  {/* <Row className="justify-content-center">
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0">2541</h5>
                          <p className="text-muted text-truncate">Activated</p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0">84845</h5>
                          <p className="text-muted text-truncate">Pending</p>
                        </div>
                      </Col>
                    </Row> */}
                  <BarChart dataColors='["--bs-danger-rgb, 0.8", "--bs-success"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Total Reported Cases - Overall */}
          <Row>
            <CardTitle
              className="mb-2  mt-3 ms-2"
              style={{
                //  lineHeight: "2.5em",
                fontSize: 16,
              }}
            >
              Total Reported Cases - <span className="text-info">Overall</span>
            </CardTitle>{" "}
            <Col xl="4">
              <Card className="text-center">
                <CardBody
                  style={{
                    // backgroundColor: "#525FE1",
                    // backgroundColor: "rgba(82, 95, 225, 0.2)",
                    backgroundColor: "#F8F9FC",
                    // color: "#fff",
                    borderRadius: "10px",
                    border: "1px solid blue",
                  }}
                >
                  <Row className="align-items-center">
                    <Col
                      md="5"
                      // className="border-end mx-2"
                      className="mx-2 mt-1"
                      style={colStyle}
                    >
                      <h6 style={fontSize16}>Suspected</h6>
                      <h4>40.3 Lacs</h4>
                    </Col>
                    <Col md="5" className="mx-2 mt-1" style={colStyle}>
                      <h6>Under Investigation</h6>
                      <h4>40.3 Lacs</h4>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-3">
                    <Col
                      md="5"
                      // className="border-end"
                      className="mx-2 mt-1 "
                      style={colStyle}
                    >
                      <h6>Confirmed</h6>
                      <h4>14000</h4>
                    </Col>
                    <Col
                      md="5"
                      className="mx-2 mt-1"
                      // style={{
                      //   // backgroundColor: "#525FE1",
                      //   backgroundColor: "rgba(82, 95, 225, 0.2)",
                      //   // color: "#fff",
                      //   borderRadius: "10px",
                      //   border: "1px solid blue",
                      //   // minHeight: "60",
                      //   // minWidth: "60",
                      // }}
                      style={colStyle}
                    >
                      <h6>Undiagnosed</h6>
                      <h4>7500</h4>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Dashed Line</CardTitle> */}
                  <DashedLine dataColors='["--bs-primary", "--bs-danger", "--bs-success"]' />
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">
                    5 Year trends of health score
                  </CardTitle> */}
                  {/* <Row className="justify-content-center">
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0">2541</h5>
                          <p className="text-muted text-truncate">Activated</p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0">84845</h5>
                          <p className="text-muted text-truncate">Pending</p>
                        </div>
                      </Col>
                    </Row> */}
                  <BarChart dataColors='["--bs-danger-rgb, 0.8", "--bs-success"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* Treatment Outcomes - YTD
           */}
          <Row>
            <CardTitle className="mb-2  mt-3 ms-2" style={{ fontSize: 16 }}>
              Treatment Outcomes - <span className="text-info">YTD</span>
            </CardTitle>{" "}
            <Col xl="4">
              <Card className="text-center">
                <CardBody
                  style={{
                    // backgroundColor: "#525FE1",
                    // backgroundColor: "rgba(82, 95, 225, 0.2)",
                    backgroundColor: "#F8F9FC",
                    // color: "#fff",
                    borderRadius: "10px",
                    border: "1px solid blue",
                  }}
                >
                  <Row className="align-items-center">
                    <Col
                      md="5"
                      // className="border-end mx-2"
                      className="mx-2 mt-1"
                      style={colStyle}
                    >
                      <h6 style={fontSize16}>Suspected</h6>
                      <h4>40.3 Lacs</h4>
                    </Col>
                    <Col md="5" className="mx-2 mt-1" style={colStyle}>
                      <h6>Under Investigation</h6>
                      <h4>40.3 Lacs</h4>
                    </Col>
                  </Row>
                  <Row className="align-items-center mt-3">
                    <Col
                      md="5"
                      // className="border-end"
                      className="mx-2 mt-1 "
                      style={colStyle}
                    >
                      <h6>Confirmed</h6>
                      <h4>14000</h4>
                    </Col>
                    <Col
                      md="5"
                      className="mx-2 mt-1"
                      // style={{
                      //   // backgroundColor: "#525FE1",
                      //   backgroundColor: "rgba(82, 95, 225, 0.2)",
                      //   // color: "#fff",
                      //   borderRadius: "10px",
                      //   border: "1px solid blue",
                      //   // minHeight: "60",
                      //   // minWidth: "60",
                      // }}
                      style={colStyle}
                    >
                      <h6>Undiagnosed</h6>
                      <h4>7500</h4>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">Dashed Line</CardTitle> */}
                  <DashedLine dataColors='["--bs-primary", "--bs-danger", "--bs-success"]' />
                </CardBody>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <CardBody>
                  {/* <CardTitle className="mb-4">
                    5 Year trends of health score
                  </CardTitle> */}
                  {/* <Row className="justify-content-center">
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0">2541</h5>
                          <p className="text-muted text-truncate">Activated</p>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="text-center">
                          <h5 className="mb-0">84845</h5>
                          <p className="text-muted text-truncate">Pending</p>
                        </div>
                      </Col>
                    </Row> */}
                  <BarChart dataColors='["--bs-danger-rgb, 0.8", "--bs-success"]' />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {/* DiseaseIndicatorsCard */}
          <DiseaseIndicatorsCard />
        </Container>
      </div>
    </React.Fragment>
  )
}
const colStyle = {
  backgroundColor: "rgba(82, 95, 225, 0.2)",
  borderRadius: "10px",
  border: "1px solid blue",
  minHeight: "100px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center", // Center content vertically
}

const fontSize16 = {
  fontSize: 16,
}

export default withTranslation()(Dashboard)
