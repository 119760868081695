import React, { useState } from "react"
import { Row, Col, FormGroup, Label, Button, Card, CardBody } from "reactstrap"

const preExistingHealthIssues = [
  "Low-vision",
  "Leprosy Cured persons",
  "Hearing Impairment",
  "Locomotor Disability",
  "Dwarfism",
  "Intellectual Disability",
  "Mental Illness",
  "Autism Spectrum Disorder",
  "Cerebral Palsy",
  "Muscular Dystrophy",
  "Chronic Neurological conditions",
  "Specific Learning Disabilities",
  "Multiple Sclerosis",
  "Speech and Language disability",
  "Thalassemia",
  "Hemophilia",
  "Sickle cell disease",
  "Multiple Disabilities including deaf-blindness",
  "Acid Attack victims",
  "Parkinson's disease",
]

const allergies = [
  "Anxiety",
  "Cancer",
  "Cardiovascular Diseases",
  "Chronic Kidney Disease",
  "Chronic Liver Diseases",
  "Chronic Lung Diseases",
  "Depression",
  "Diabetes",
  "Gastrointestinal issues",
  "High blood pressure (Hypertension)",
  "HIV/AIDS",
  "migraine",
  "Obesity",
  "Schizophrenia and other psychotic disorders",
  "Sleep apnea/ Sleep disorder/ Insomnia",
  "Sinus",
  "Skin Problems",
  "Thyroid",
  "Fibroids",
  "Arthritis",
  "Ear Impairment",
  "Anemia",
]

const PreExistingDiseases2 = () => {
  const [formData, setFormData] = useState({
    knownAllergies: [],
    familyPlanning: [],
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const toggleAllergy = allergy => {
    setFormData(prevFormData => ({
      ...prevFormData,
      knownAllergies: prevFormData.knownAllergies.includes(allergy)
        ? prevFormData.knownAllergies.filter(a => a !== allergy)
        : [...prevFormData.knownAllergies, allergy],
    }))
  }

  const togglePreExistingHealthIssues = healthIssues => {
    setFormData(prevFormData => ({
      ...prevFormData,
      familyPlanning: prevFormData.familyPlanning.includes(healthIssues)
        ? prevFormData.familyPlanning.filter(a => a !== healthIssues)
        : [...prevFormData.familyPlanning, healthIssues],
    }))
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          {submittedData && (
            <>
              <h5>Pre-Existing Disease(s)</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Pre-Existing Health Issues</Label>
                    <div>
                      A.-{" "}
                      {submittedData.familyPlanning.length > 0
                        ? submittedData.familyPlanning.join(", ")
                        : "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Do you suffer with any or more of the following
                      disabilities
                    </Label>

                    <div>
                      A.-{" "}
                      {submittedData.knownAllergies.length > 0
                        ? submittedData.knownAllergies.join(", ")
                        : "N/A"}
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
        <Col md={6}>
          <h5>Pre-Existing Disease(s)</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="PrehealthIssues">
                    Pre-Existing Health Issues
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {preExistingHealthIssues.map(healthIssues => (
                      <Col key={healthIssues} xs="auto">
                        <Button
                          size="sm"
                          color={
                            formData.familyPlanning.includes(healthIssues)
                              ? "primary"
                              : "light"
                          }
                          onClick={() =>
                            togglePreExistingHealthIssues(healthIssues)
                          }
                          className="m-1"
                        >
                          {healthIssues}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">
                    Do you suffer with any or more of the following disabilities
                    -
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {allergies.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            formData.knownAllergies.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleAllergy(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <Button type="submit" color="primary">
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  borderRadius: 7,
  padding: 10,
}

export default PreExistingDiseases2

// import React, { useState } from "react"
// import {
//   Row,
//   Col,
//   FormGroup,
//   Label,
//   Input,
//   Button,
//   Card,
//   CardBody,
// } from "reactstrap"
// import Select from "react-select"

// const preExistingHealthIssues = [
//   "Low-vision",
//   "Leprosy Cured persons",
//   "Hearing Impairment",
//   "Locomotor Disability",
//   "Dwarfism",
//   "Intellectual Disability",
//   "Mental Illness",
//   "Autism Spectrum Disorder",
//   "Cerebral Palsy",
//   "Muscular Dystrophy",
//   "Chronic Neurological conditions",

//   "Specific Learning Disabilities",

//   "Multiple Sclerosis",

//   "Speech and Language disability",

//   "Thalassemia",

//   "Hemophilia",

//   "Sickle cell disease",

//   "Multiple Disabilities including deaf-blindness",

//   "Acid Attack victims",

//   "Parkinson's disease",
// ]

// const allergies = [
//   "Anxiety",
//   "Cancer",
//   "Cardiovascular Diseases",
//   "Chronic Kidney Disease",
//   "Chronic Liver Diseases",
//   "Chronic Lung Diseases",
//   "Depression",
//   "Diabetes",
//   "Gastrointestinal issues",
//   "High blood pressure (Hypertension)",
//   "HIV/AIDS",
//   "migraine",
//   "Obesity",
//   "Schizophrenia and other psychotic disorders",
//   "Sleep apnea/ Sleep disorder/ Insomnia",
//   "Sinus",
//   "Skin Problems",
//   "Thyroid",
//   "Fibroids",
//   "Arthritis",
//   "Ear Impairment",
//   "Anemia",
// ]

// const PreExistingDiseases2 = () => {
//   const [formData, setFormData] = useState({
//     maritalStatus: null,
//     numPregnancies: "",
//     numMiscarriages: "",
//     numChildren: "",
//     ageLastChild: "",
//     knownAllergies: [],
//     familyPlanning: [],
//   })

//   const [submittedData, setSubmittedData] = useState(null)

//   const handleChange = (selectedOption, { name }) => {
//     setFormData({
//       ...formData,
//       [name]: selectedOption,
//     })
//   }

//   const handleInputChange = e => {
//     const { name, value } = e.target
//     setFormData({
//       ...formData,
//       [name]: value,
//     })
//   }

//   const handleSubmit = e => {
//     e.preventDefault()
//     setSubmittedData(formData)
//   }

//   const [selectedAllergies, setSelectedAllergies] = useState([])

//   const toggleAllergy = allergy => {
//     setSelectedAllergies(prevSelected =>
//       prevSelected.includes(allergy)
//         ? prevSelected.filter(a => a !== allergy)
//         : [...prevSelected, allergy]
//     )
//   }

//   const [PreExistingHealthIssues, setPreExistingHealthIssues] = useState([])

//   const togglePreExistingHealthIssues = healthIssues => {
//     setPreExistingHealthIssues(prevSelected =>
//       prevSelected.includes(healthIssues)
//         ? prevSelected.filter(a => a !== healthIssues)
//         : [...prevSelected, healthIssues]
//     )
//     // console.log(PreExistingHealthIssues, "PreExistingHealthIssues")
//   }

//   return (
//     <div>
//       {/* <h4>Personal Profile</h4> */}
//       <Row>
//         <Col md={6}>
//           {submittedData && (
//             <>
//               <h5>Pre-Existing Disease(s)</h5>
//               <Card>
//                 <CardBody>
//                   <FormGroup style={formGroupStyle}>
//                     <Label>Q.- Known Allergies</Label>
//                     <div>
//                       A.-{" "}
//                       {submittedData?.knownAllergies
//                         ?.map(allergy => allergy.label)
//                         .join(", ") || "N/A"}
//                     </div>
//                   </FormGroup>
//                   <FormGroup style={formGroupStyle}>
//                     <Label>Q.- Family Planning (Method Adopted)</Label>
//                     <div>
//                       A.-{" "}
//                       {submittedData?.familyPlanning
//                         ?.map(method => method.label)
//                         .join(", ") || "N/A"}
//                     </div>
//                   </FormGroup>
//                 </CardBody>
//               </Card>
//             </>
//           )}
//         </Col>
//         <Col md={6}>
//           <h5>Pre-Existing Disease(s)</h5>
//           <Card>
//             <CardBody>
//               <form onSubmit={handleSubmit}>
//                 <FormGroup>
//                   <Label for="PrehealthIssues">
//                     Pre-Existing Health Issues
//                   </Label>
//                   <Row className="g-1" style={formGroupStyle}>
//                     {preExistingHealthIssues?.map(healthIssues => (
//                       <Col key={healthIssues} xs="auto">
//                         <Button
//                           color={
//                             PreExistingHealthIssues.includes(healthIssues)
//                               ? "primary"
//                               : "light"
//                           }
//                           onClick={() =>
//                             togglePreExistingHealthIssues(healthIssues)
//                           }
//                           className="m-1"
//                         >
//                           {healthIssues}
//                         </Button>
//                       </Col>
//                     ))}
//                   </Row>
//                 </FormGroup>

//                 <FormGroup>
//                   <Label for="maritalStatus">
//                     Do you suffer with any or more of the following disabilities
//                     -
//                   </Label>
//                   <Row className="g-1" style={formGroupStyle}>
//                     {allergies?.map(allergy => (
//                       <Col key={allergy} xs="auto">
//                         <Button
//                           color={
//                             selectedAllergies.includes(allergy)
//                               ? "primary"
//                               : "light"
//                           }
//                           onClick={() => toggleAllergy(allergy)}
//                           className="m-1"
//                         >
//                           {allergy}
//                         </Button>
//                       </Col>
//                     ))}
//                   </Row>
//                 </FormGroup>

//                 <Button type="submit" color="primary" style={{}}>
//                   Save
//                 </Button>
//               </form>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>
//     </div>
//   )
// }

// const formGroupStyle = {
//   border: "2px solid  #e5e8eb",
//   borderRadius: 7,
//   padding: 10,
// }

// export default PreExistingDiseases2
