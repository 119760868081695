import React, { useState } from "react"
import { Row, Col, FormGroup, Label, Button, Card, CardBody } from "reactstrap"

const VaccinationImmunizationData = [
  "Influenza Inactivated (IIV)",
  "Influenza Recombinant (RIV4)",
  "Influenza Live Attenuated",
  "Tetanus, Diphtheria, Pertussis",
  "Measles, Mumps, Rubella",
  "Varicella",
  "Zoster Recombinant",
  "Human Papillomavirus",
  "Pneumococcal Conjugate",
  "Pneumococcal Polysaccharide",
  "Hepatitis A",
  "Hepatitis B",
  "Meningococcal A, C, W, Y",
  "Meningococcal B",
  "Haemophilus Influenzae type B",
  "Cholera Vaccine",
  "Japanese Encephalitis",
  "Meningococcal Meningitis",
  "Pneumococcal",
  "Rabies",
]

const VaccinationImmunization = () => {
  const [formData, setFormData] = useState({
    familyHistoryDisease: [],
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const toggleFamilyHistoryDisease = healthIssues => {
    setFormData(prevFormData => ({
      ...prevFormData,
      familyHistoryDisease: prevFormData.familyHistoryDisease.includes(
        healthIssues
      )
        ? prevFormData.familyHistoryDisease.filter(a => a !== healthIssues)
        : [...prevFormData.familyHistoryDisease, healthIssues],
    }))
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          {submittedData && (
            <>
              <h5>Vaccination / Immunization</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Please Select from the following diseases</Label>
                    <div>
                      A.-{" "}
                      {submittedData.familyHistoryDisease.length > 0
                        ? submittedData.familyHistoryDisease.join(", ")
                        : "Influenza Inactivated (IIV)   Influenza Recombinant (RIV4)"}
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
        <Col md={6}>
          <h5>Vaccination / Immunization</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="PrehealthIssues">
                    Please tick the vaccination
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VaccinationImmunizationData.map(healthIssues => (
                      <Col key={healthIssues} xs="auto">
                        <Button
                          size="sm"
                          color={
                            formData.familyHistoryDisease.includes(healthIssues)
                              ? "primary"
                              : "light"
                          }
                          onClick={() =>
                            toggleFamilyHistoryDisease(healthIssues)
                          }
                          className="m-1"
                        >
                          {healthIssues}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <Button type="submit" color="primary">
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  borderRadius: 7,
  padding: 10,
}

export default VaccinationImmunization
