import React from "react"
import {
  Container,
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap"
import NewbornCaseRecordHeader from "./NewbornCaseRecordHeader"

const SystemicExaminationForm = () => {
  return (
    <div className="page-content">
      <Container className="">
        <Card className="mb-3">
          <CardHeader tag="h4" className="text-dark">
            Systemic Examination
          </CardHeader>
          <CardBody>
            <Row>
              <Col lg="12">
                <NewbornCaseRecordHeader />
              </Col>
              <Col lg="12">
                <Form>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="cvs">
                          Cardiovascular (CVS) examination
                        </Label>
                        <Input
                          type="text"
                          name="cvs"
                          id="cvs"
                          placeholder="Enter details"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="abdomen">Per Abdomen</Label>
                        <Input
                          type="text"
                          name="abdomen"
                          id="abdomen"
                          placeholder="Enter details"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="respiratory">Respiratory</Label>
                        <Input
                          type="text"
                          name="respiratory"
                          id="respiratory"
                          placeholder="Enter details"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="cns">CNS (Central Nervous System)</Label>
                        <Input
                          type="text"
                          name="cns"
                          id="cns"
                          placeholder="Enter details"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Label for="significantFindings">
                      Other Significant Findings
                    </Label>
                    <Input
                      type="textarea"
                      name="significantFindings"
                      id="significantFindings"
                      placeholder="Write Other Significant Findings here..."
                    />
                  </FormGroup>
                  <div className="text-center">
                    <Button color="primary" type="submit">
                      Submit
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default SystemicExaminationForm
