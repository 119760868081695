import React, { useState } from "react"
import Select from "react-select"
import {
  Container,
  Card,
  CardBody,
  Input,
  Button,
  FormGroup,
  Label,
  ModalHeader,
  Modal,
  ModalBody,
} from "reactstrap"
import {
  FaClock,
  FaSearch,
  FaTimes,
  FaTrashAlt,
  FaShareAlt,
  FaEdit,
} from "react-icons/fa"
import DatePicker from "react-datepicker"
import ImageFullView from "components/Common/ImageFullView"

const reports = [
  {
    id: 1,
    name: "Report-1",
    image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    hospital: "Rewa Hospital",
    doctor: "Dr. Gorav Pandey",
    date: "11-8-2024",
  },
  {
    id: 2,
    name: "Report-2",
    image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    hospital: "Noida Hospital",
    doctor: "Dr. MOhan Patel",
    date: "11-8-2024",
  },
  {
    id: 3,
    name: "Report-3",
    image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    hospital: "Delhi Hospital",
    doctor: "Dr. DK Pandey",
    date: "11-8-2024",
  },
  {
    id: 4,
    name: "Report-4",
    image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    hospital: "Pune Hospital",
    doctor: "Dr. Kd Pandey",
    date: "11-8-2024",
  },
  {
    id: 5,
    name: "Report-5",
    image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    hospital: "Rewa Hospital",
    doctor: "Dr. Gorav Pandey",
    date: "11-8-2024",
  },
  {
    id: 6,
    name: "Report-6",
    image: "https://www.pdffiller.com/preview/19/902/19902134.png",
    hospital: "Rewa Hospital",
    doctor: "Dr. Gorav Pandey",
    date: "11-8-2024",
  },
]

const medicines = [
  {
    value: "Paracetamol (Tylenol, Calpol)",
    label: "Paracetamol (Tylenol, Calpol)",
  },
  { value: "Ibuprofen (Advil, Brufen)", label: "Ibuprofen (Advil, Brufen)" },
  { value: "Amoxicillin (Amoxil)", label: "Amoxicillin (Amoxil)" },
  { value: "Ciprofloxacin (Cipro)", label: "Ciprofloxacin (Cipro)" },
  // Add more medicines as needed
]

const InboxForm = ({ selectedOrder }) => {
  console.log("InboxForm", selectedOrder)
  const [prescriptions, setPrescriptions] = useState([
    {
      id: 1,
      name: "Paracetamol (Tylenol, Calpol)",
      quantity: 8,
      frequency: "Day (noon)",
      times: ["2 AM"],
    },
    {
      id: 2,
      name: "Ibuprofen (Advil, Brufen)",
      quantity: 1,
      frequency: "At Bedtime",
      times: ["9:30 PM"],
    },
    {
      id: 3,
      name: "Amoxicillin (Amoxil)",
      quantity: 1,
      frequency: "wake up time",
      times: ["6 AM"],
    },
    {
      id: 4,
      name: "Ciprofloxacin (Cipro)",
      quantity: 8,
      frequency: "Times a Day",
      times: ["9:00 AM", "2:00 PM", "6:00 PM", "10:00 PM"],
    },
  ])

  const handleMedicineSelect = selectedOption => {
    setSelectedMedicines([...selectedMedicines, selectedOption])
  }
  const [selectedMedicines, setSelectedMedicines] = useState([])

  const handleChange = selectedOptions => {
    setSelectedMedicines(selectedOptions)
  }

  const handleSearch = () => {
    console.log("Selected Medicines:", selectedMedicines)
  }

  const handleRemoveMedicine = medicine => {
    setSelectedMedicines(
      selectedMedicines.filter(m => m.value !== medicine.value)
    )
  }

  const handleQuantityChange = (id, value) => {
    setPrescriptions(
      prescriptions.map(p => (p.id === id ? { ...p, quantity: value } : p))
    )
  }

  const handleFrequencyChange = (id, value) => {
    let times = []
    switch (value) {
      case "wake up time":
        times = ["6:00 PM"]
        break
      case "Once A Day(Morning)":
        times = ["6 AM"]
        break
      case "Once A Day(Noon)":
        times = ["2 AM"]
        break
      case "Once A Day(Evening)":
        times = ["8:30 AM"]
        break
      case "Four Times a Day":
        times = ["9:00 AM", "2:00 PM", "6:00 PM", "10:00 PM"]
        break
      case "At Bedtime":
        times = ["9:30 PM"]
        break
      case "SOS":
        times = [""]
        break
      default:
        times = [""]
    }
    setPrescriptions(
      prescriptions.map(p =>
        p.id === id ? { ...p, frequency: value, times } : p
      )
    )
  }

  const handleRemovePrescription = id => {
    setPrescriptions(prescriptions.filter(p => p.id !== id))
  }

  const [timePickerData, setTimePickerData] = useState({
    isOpen: false,
    selectedTime: new Date(),
    prescriptionId: null,
    timeIndex: null,
  })

  const parseTimeString = timeString => {
    const date = new Date()
    const timeParts = timeString.match(/(\d+):(\d+)\s*(AM|PM)?/)
    if (timeParts) {
      let [hours, minutes] = [parseInt(timeParts[1]), parseInt(timeParts[2])]
      const isPM = timeParts[3] === "PM"
      if (isPM && hours < 12) hours += 12
      if (!isPM && hours === 12) hours = 0
      date.setHours(hours)
      date.setMinutes(minutes)
      return date
    }
    return date
  }

  const handleTimeClick = (prescriptionId, timeIndex) => {
    const currentSelectedTime = prescriptions.find(p => p.id === prescriptionId)
      .times[timeIndex]
    const parsedTime = parseTimeString(currentSelectedTime)

    setTimePickerData({
      isOpen: true,
      selectedTime: parsedTime,
      prescriptionId,
      timeIndex,
    })
  }

  const handleTimeChange = newTime => {
    const { prescriptionId, timeIndex } = timePickerData
    const formattedTime = newTime.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    })

    const updatedPrescriptions = prescriptions.map(p => {
      if (p.id === prescriptionId) {
        const newTimes = [...p.times]
        newTimes[timeIndex] = formattedTime
        return { ...p, times: newTimes }
      }
      return p
    })

    setPrescriptions(updatedPrescriptions)
  }

  const closeModal = () => {
    setTimePickerData({
      isOpen: false,
      selectedTime: new Date(),
      prescriptionId: null,
      timeIndex: null,
    })
  }

  const BorderStyle = {
    // border: "1px solid  #007bff",
    border: "2px solid  #e5e8eb",
    borderRadius: "15px",
    padding: 7,
  }
  return (
    <>
      <Container fluid className="p-0">
        <Card style={BorderStyle}>
          <div className="bg-primary text-white p-3 mb-3">
            <h3 className="m-0 text-center " style={{ fontFamily: "serif" }}>
              Inbox
            </h3>
          </div>

          <Card className="mb-3 mx-3">
            <CardBody className="bg-warning text-dark">
              <div
                className="d-flex justify-content-between"
                style={{
                  fontFamily: "serif",
                  fontSize: 20,
                  fontWeight: "bold",
                }}
              >
                <div>Date: {selectedOrder[0]?.date}</div>
                <div>Order ID: {selectedOrder[0]?.orderId}</div>
              </div>
            </CardBody>

            {/* Your existing JSX for displaying reports and prescriptions */}
            {/* ... */}
          </Card>

          <div>
            <Card className="mb-4 mt-2" style={BorderStyle}>
              <CardBody>
                <div
                  className="d-flex overflow-auto"
                  style={{
                    display: "flex",
                    overflowX: "auto",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                  }}
                >
                  <style>
                    {`
                div::-webkit-scrollbar {
                  display: none;
                }
              `}
                  </style>
                  {reports.map((report, index) => (
                    <div
                      key={report.id}
                      className="d-flex flex-column me-2"
                      style={{
                        minWidth: "180px",
                        position: "relative",
                        backgroundColor: "#ffffff",
                        borderRadius: "8px",
                        padding: "20px",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {/* Checkbox in the top right corner */}
                      <Input
                        type="checkbox"
                        style={{
                          position: "absolute",
                          top: "5px",
                          right: "5px",
                        }}
                      />

                      <ImageFullView
                        imageSrc={report.image}
                        altText={report.name}
                        triggerElement={
                          <img
                            src={report.image}
                            alt=""
                            height="100px"
                            width="80px"
                            style={{ borderRadius: "4px" }}
                          />
                        }
                      />

                      {/* Icons for actions */}
                      <div
                        style={{
                          position: "absolute",
                          right: "10px",
                          top: "55%",
                          transform: "translateY(-50%)",
                        }}
                      >
                        <Button color="link">
                          <FaShareAlt />
                        </Button>
                        <Button color="link">
                          <FaEdit />
                        </Button>
                        <Button color="link">
                          <FaTrashAlt />
                        </Button>
                      </div>

                      {/* Report details */}
                      <div
                        className="mt-4 text-center fs-6"
                        style={{ fontFamily: "serif" }}
                      >
                        <div>{report.date}</div>
                        <div>{report.doctor}</div>
                        <div>{report.hospital}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          </div>

          {/* <div className="mx-3 mb-3">
            <div className="input-group">
              <Input placeholder="Search medicines by name" />
              <div className="input-group-append">
                <Button color="primary">
                  <FaSearch />
                </Button>
              </div>
            </div>
          </div> */}
          <div className="mx-3 mb-3">
            <div className="input-group">
              <Select
                isMulti
                options={medicines}
                placeholder="Search medicines by name"
                className="flex-grow-1"
                onChange={handleChange}
              />
              <div className="input-group-append">
                <Button color="primary" onClick={handleSearch}>
                  <FaSearch />
                </Button>
              </div>
            </div>
          </div>

          {/* {selectedMedicines.map(medicine => (
            <div
              key={medicine.value}
              className="mx-3 mb-2 d-flex align-items-center bg-primary text-white p-2 rounded"
            >
              <span>{medicine.label}</span>
              <Button
                // close
                className="ml-auto mx-2"
                onClick={() => handleRemoveMedicine(medicine)}
              >
                <FaTimes />
              </Button>
            </div>
          ))} */}

          <div className="mx-3 mb-3 mt-3" style={BorderStyle}>
            <h5>Prescription (My Digitised Medicine)</h5>
            {prescriptions.map(prescription => (
              <FormGroup
                key={prescription.id}
                className="mb-3 p-2 border rounded"
              >
                <div className="d-flex align-items-center mb-2">
                  <Input
                    type="checkbox"
                    className="mr-2 mx-2"
                    size="medium"
                    checked
                  />
                  <Label className="mb-0">{prescription.name}</Label>
                  <small className="text-muted ml-2 mx-1">
                    Strip of 10 tablets
                  </small>
                  <div
                    className="ml-auto mx-2 p-2 text-sm" // Adjust padding and text size as needed
                    onClick={() => handleRemovePrescription(prescription.id)}
                  >
                    <FaTrashAlt className="text-sm" />
                  </div>
                </div>
                <div className="d-flex ">
                  <Select
                    value={{
                      value: prescription.quantity,
                      label: prescription.quantity,
                    }}
                    onChange={option =>
                      handleQuantityChange(prescription.id, option.value)
                    }
                    options={[1, 2, 3, 4, 5, 6, 7, 8].map(n => ({
                      value: n,
                      label: n,
                    }))}
                    className="w-25 mr-2 mx-3"
                  />
                  <Select
                    value={{
                      value: prescription.frequency,
                      label: prescription.frequency,
                    }}
                    onChange={option =>
                      handleFrequencyChange(prescription.id, option.value)
                    }
                    options={[
                      { value: "wake up time", label: "wake up time" },
                      {
                        value: "Once A Day(Morning)",
                        label: "Once A Day (Morning)",
                      },
                      {
                        value: "Once A Day(Noon)",
                        label: "Once A Day (Noon)",
                      },
                      {
                        value: "Once A Day(Evening)",
                        label: "Once A Day (Evening)",
                      },
                      {
                        value: "Four Times a Day",
                        label: "Four Times a Day",
                      },
                      { value: "SOS", label: "SOS" },
                      { value: "At Bedtime", label: "At Bedtime" },
                    ]}
                    className="w-50 mx-3"
                  />
                </div>
                <div className="d-flex mt-2 mx-3">
                  {prescription.times.map((time, index) => (
                    <div key={index} className="mr-2 d-flex align-items-center">
                      {/* <FaClock className="text-success mr-1 mx-2 fs-5" /> */}
                      <FaClock
                        className="text-success mr-1  mx-2 fs-3"
                        onClick={() => handleTimeClick(prescription.id, index)}
                        style={{ cursor: "pointer" }}
                      />
                      <small className="fs-6">{time}</small>
                    </div>
                  ))}
                </div>
              </FormGroup>
            ))}
          </div>

          <div className="mx-3 mb-3 text-center">
            <Button color="warning">Submit</Button>
          </div>
        </Card>
        <Modal isOpen={timePickerData.isOpen} toggle={closeModal}>
          <ModalHeader toggle={closeModal}>Select Time</ModalHeader>
          <ModalBody>
            <DatePicker
              selected={timePickerData.selectedTime}
              onChange={handleTimeChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
            <Button color="primary" className="mt-3" onClick={closeModal}>
              Set Time
            </Button>
          </ModalBody>
        </Modal>
      </Container>
    </>
  )
}

export default InboxForm

// import React, { useState } from "react"
// import Select from "react-select"
// import {
//   Container,
//   Card,
//   CardBody,
//   Input,
//   Button,
//   FormGroup,
//   Label,
//   ModalHeader,
//   Modal,
//   ModalBody,
// } from "reactstrap"
// import {
//   FaClock,
//   FaSearch,
//   FaTimes,
//   FaTrashAlt,
//   FaShareAlt,
//   FaEdit,
// } from "react-icons/fa"
// import DatePicker from "react-datepicker"
// import ImageFullView from "components/Common/ImageFullView"

// const reports = [
//   {
//     id: 1,
//     name: "Report-1",
//     image: "https://www.pdffiller.com/preview/19/902/19902134.png",
//     hospital: "Rewa Hospital",
//     doctor: "Dr. Gorav Pandey",
//     date: "11-8-2024",
//   },
//   {
//     id: 2,
//     name: "Report-2",
//     image: "https://www.pdffiller.com/preview/19/902/19902134.png",
//     hospital: "Noida Hospital",
//     doctor: "Dr. MOhan Patel",
//     date: "11-8-2024",
//   },
//   {
//     id: 3,
//     name: "Report-3",
//     image: "https://www.pdffiller.com/preview/19/902/19902134.png",
//     hospital: "Delhi Hospital",
//     doctor: "Dr. DK Pandey",
//     date: "11-8-2024",
//   },
//   {
//     id: 4,
//     name: "Report-4",
//     image: "https://www.pdffiller.com/preview/19/902/19902134.png",
//     hospital: "Pune Hospital",
//     doctor: "Dr. Kd Pandey",
//     date: "11-8-2024",
//   },
//   {
//     id: 5,
//     name: "Report-5",
//     image: "https://www.pdffiller.com/preview/19/902/19902134.png",
//     hospital: "Rewa Hospital",
//     doctor: "Dr. Gorav Pandey",
//     date: "11-8-2024",
//   },
//   {
//     id: 6,
//     name: "Report-6",
//     image: "https://www.pdffiller.com/preview/19/902/19902134.png",
//     hospital: "Rewa Hospital",
//     doctor: "Dr. Gorav Pandey",
//     date: "11-8-2024",
//   },
// ]

// const medicines = [
//   {
//     value: "Paracetamol (Tylenol, Calpol)",
//     label: "Paracetamol (Tylenol, Calpol)",
//   },
//   { value: "Ibuprofen (Advil, Brufen)", label: "Ibuprofen (Advil, Brufen)" },
//   { value: "Amoxicillin (Amoxil)", label: "Amoxicillin (Amoxil)" },
//   { value: "Ciprofloxacin (Cipro)", label: "Ciprofloxacin (Cipro)" },
//   // Add more medicines as needed
// ]

// const InboxForm = () => {
//   const [prescriptions, setPrescriptions] = useState([
//     {
//       id: 1,
//       name: "Paracetamol (Tylenol, Calpol)",
//       quantity: 8,
//       frequency: "Day (noon)",
//       times: ["2 AM"],
//     },
//     {
//       id: 2,
//       name: "Ibuprofen (Advil, Brufen)",
//       quantity: 1,
//       frequency: "At Bedtime",
//       times: ["9:30 PM"],
//     },
//     {
//       id: 3,
//       name: "Amoxicillin (Amoxil)",
//       quantity: 1,
//       frequency: "wake up time",
//       times: ["6 AM"],
//     },
//     {
//       id: 4,
//       name: "Ciprofloxacin (Cipro)",
//       quantity: 8,
//       frequency: "Times a Day",
//       times: ["9:00 AM", "2:00 PM", "6:00 PM", "10:00 PM"],
//     },
//   ])

//   const handleMedicineSelect = selectedOption => {
//     setSelectedMedicines([...selectedMedicines, selectedOption])
//   }
//   const [selectedMedicines, setSelectedMedicines] = useState([])

//   const handleChange = selectedOptions => {
//     setSelectedMedicines(selectedOptions)
//   }

//   const handleSearch = () => {
//     console.log("Selected Medicines:", selectedMedicines)
//   }

//   const handleRemoveMedicine = medicine => {
//     setSelectedMedicines(
//       selectedMedicines.filter(m => m.value !== medicine.value)
//     )
//   }

//   const handleQuantityChange = (id, value) => {
//     setPrescriptions(
//       prescriptions.map(p => (p.id === id ? { ...p, quantity: value } : p))
//     )
//   }

//   const handleFrequencyChange = (id, value) => {
//     let times = []
//     switch (value) {
//       case "wake up time":
//         times = ["6:00 PM"]
//         break
//       case "Once A Day(Morning)":
//         times = ["6 AM"]
//         break
//       case "Once A Day(Noon)":
//         times = ["2 AM"]
//         break
//       case "Once A Day(Evening)":
//         times = ["8:30 AM"]
//         break
//       case "Four Times a Day":
//         times = ["9:00 AM", "2:00 PM", "6:00 PM", "10:00 PM"]
//         break
//       case "At Bedtime":
//         times = ["9:30 PM"]
//         break
//       case "SOS":
//         times = [""]
//         break
//       default:
//         times = [""]
//     }
//     setPrescriptions(
//       prescriptions.map(p =>
//         p.id === id ? { ...p, frequency: value, times } : p
//       )
//     )
//   }

//   const handleRemovePrescription = id => {
//     setPrescriptions(prescriptions.filter(p => p.id !== id))
//   }

//   const [timePickerData, setTimePickerData] = useState({
//     isOpen: false,
//     selectedTime: new Date(),
//     prescriptionId: null,
//     timeIndex: null,
//   })

//   const parseTimeString = timeString => {
//     const date = new Date()
//     const timeParts = timeString.match(/(\d+):(\d+)\s*(AM|PM)?/)
//     if (timeParts) {
//       let [hours, minutes] = [parseInt(timeParts[1]), parseInt(timeParts[2])]
//       const isPM = timeParts[3] === "PM"
//       if (isPM && hours < 12) hours += 12
//       if (!isPM && hours === 12) hours = 0
//       date.setHours(hours)
//       date.setMinutes(minutes)
//       return date
//     }
//     return date
//   }

//   const handleTimeClick = (prescriptionId, timeIndex) => {
//     const currentSelectedTime = prescriptions.find(p => p.id === prescriptionId)
//       .times[timeIndex]
//     const parsedTime = parseTimeString(currentSelectedTime)

//     setTimePickerData({
//       isOpen: true,
//       selectedTime: parsedTime,
//       prescriptionId,
//       timeIndex,
//     })
//   }

//   const handleTimeChange = newTime => {
//     const { prescriptionId, timeIndex } = timePickerData
//     const formattedTime = newTime.toLocaleTimeString([], {
//       hour: "2-digit",
//       minute: "2-digit",
//     })

//     const updatedPrescriptions = prescriptions.map(p => {
//       if (p.id === prescriptionId) {
//         const newTimes = [...p.times]
//         newTimes[timeIndex] = formattedTime
//         return { ...p, times: newTimes }
//       }
//       return p
//     })

//     setPrescriptions(updatedPrescriptions)
//   }

//   const closeModal = () => {
//     setTimePickerData({
//       isOpen: false,
//       selectedTime: new Date(),
//       prescriptionId: null,
//       timeIndex: null,
//     })
//   }

//   const BorderStyle = {
//     // border: "1px solid  #007bff",
//     border: "2px solid  #e5e8eb",
//     borderRadius: "15px",
//     padding: 7,
//   }
//   return (
//     <>
//       <Container fluid className="p-0">
//         <Card style={BorderStyle}>
//           <div className="bg-primary text-white p-3 mb-3">
//             <h3 className="m-0 text-center " style={{ fontFamily: "serif" }}>
//               Inbox
//             </h3>
//           </div>

//           <Card className="mb-3 mx-3">
//             <CardBody className="bg-warning text-dark">
//               <div
//                 className="d-flex justify-content-between"
//                 style={{
//                   fontFamily: "serif",
//                   fontSize: 20,
//                   fontWeight: "bold",
//                 }}
//               >
//                 <div>Date: 13-8-2024</div>
//                 <div>Order ID: 123456</div>
//               </div>
//             </CardBody>
//           </Card>

//           <div>
//             <Card className="mb-4 mt-2" style={BorderStyle}>
//               <CardBody>
//                 <div
//                   className="d-flex overflow-auto"
//                   style={{
//                     display: "flex",
//                     overflowX: "auto",
//                     scrollbarWidth: "none",
//                     msOverflowStyle: "none",
//                   }}
//                 >
//                   <style>
//                     {`
//                 div::-webkit-scrollbar {
//                   display: none;
//                 }
//               `}
//                   </style>
//                   {reports.map((report, index) => (
//                     <div
//                       key={report.id}
//                       className="d-flex flex-column me-2"
//                       style={{
//                         minWidth: "180px",
//                         position: "relative",
//                         backgroundColor: "#ffffff",
//                         borderRadius: "8px",
//                         padding: "20px",
//                         boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
//                       }}
//                     >
//                       {/* Checkbox in the top right corner */}
//                       <Input
//                         type="checkbox"
//                         style={{
//                           position: "absolute",
//                           top: "5px",
//                           right: "5px",
//                         }}
//                       />

//                       <ImageFullView
//                         imageSrc={report.image}
//                         altText={report.name}
//                         triggerElement={
//                           <img
//                             src={report.image}
//                             alt=""
//                             height="100px"
//                             width="80px"
//                             style={{ borderRadius: "4px" }}
//                           />
//                         }
//                       />

//                       {/* Icons for actions */}
//                       <div
//                         style={{
//                           position: "absolute",
//                           right: "10px",
//                           top: "55%",
//                           transform: "translateY(-50%)",
//                         }}
//                       >
//                         <Button color="link">
//                           <FaShareAlt />
//                         </Button>
//                         <Button color="link">
//                           <FaEdit />
//                         </Button>
//                         <Button color="link">
//                           <FaTrashAlt />
//                         </Button>
//                       </div>

//                       {/* Report details */}
//                       <div
//                         className="mt-4 text-center fs-6"
//                         style={{ fontFamily: "serif" }}
//                       >
//                         <div>{report.date}</div>
//                         <div>{report.doctor}</div>
//                         <div>{report.hospital}</div>
//                       </div>
//                     </div>
//                   ))}
//                 </div>
//               </CardBody>
//             </Card>
//           </div>

//           {/* <div className="mx-3 mb-3">
//             <div className="input-group">
//               <Input placeholder="Search medicines by name" />
//               <div className="input-group-append">
//                 <Button color="primary">
//                   <FaSearch />
//                 </Button>
//               </div>
//             </div>
//           </div> */}
//           <div className="mx-3 mb-3">
//             <div className="input-group">
//               <Select
//                 isMulti
//                 options={medicines}
//                 placeholder="Search medicines by name"
//                 className="flex-grow-1"
//                 onChange={handleChange}
//               />
//               <div className="input-group-append">
//                 <Button color="primary" onClick={handleSearch}>
//                   <FaSearch />
//                 </Button>
//               </div>
//             </div>
//           </div>

//           {/* {selectedMedicines.map(medicine => (
//             <div
//               key={medicine.value}
//               className="mx-3 mb-2 d-flex align-items-center bg-primary text-white p-2 rounded"
//             >
//               <span>{medicine.label}</span>
//               <Button
//                 // close
//                 className="ml-auto mx-2"
//                 onClick={() => handleRemoveMedicine(medicine)}
//               >
//                 <FaTimes />
//               </Button>
//             </div>
//           ))} */}

//           <div className="mx-3 mb-3 mt-3" style={BorderStyle}>
//             <h5>Prescription (My Digitised Medicine)</h5>
//             {prescriptions.map(prescription => (
//               <FormGroup
//                 key={prescription.id}
//                 className="mb-3 p-2 border rounded"
//               >
//                 <div className="d-flex align-items-center mb-2">
//                   <Input
//                     type="checkbox"
//                     className="mr-2 mx-2"
//                     size="medium"
//                     checked
//                   />
//                   <Label className="mb-0">{prescription.name}</Label>
//                   <small className="text-muted ml-2 mx-1">
//                     Strip of 10 tablets
//                   </small>
//                   <div
//                     className="ml-auto mx-2 p-2 text-sm" // Adjust padding and text size as needed
//                     onClick={() => handleRemovePrescription(prescription.id)}
//                   >
//                     <FaTrashAlt className="text-sm" />
//                   </div>
//                 </div>
//                 <div className="d-flex ">
//                   <Select
//                     value={{
//                       value: prescription.quantity,
//                       label: prescription.quantity,
//                     }}
//                     onChange={option =>
//                       handleQuantityChange(prescription.id, option.value)
//                     }
//                     options={[1, 2, 3, 4, 5, 6, 7, 8].map(n => ({
//                       value: n,
//                       label: n,
//                     }))}
//                     className="w-25 mr-2 mx-3"
//                   />
//                   <Select
//                     value={{
//                       value: prescription.frequency,
//                       label: prescription.frequency,
//                     }}
//                     onChange={option =>
//                       handleFrequencyChange(prescription.id, option.value)
//                     }
//                     options={[
//                       { value: "wake up time", label: "wake up time" },
//                       {
//                         value: "Once A Day(Morning)",
//                         label: "Once A Day (Morning)",
//                       },
//                       {
//                         value: "Once A Day(Noon)",
//                         label: "Once A Day (Noon)",
//                       },
//                       {
//                         value: "Once A Day(Evening)",
//                         label: "Once A Day (Evening)",
//                       },
//                       {
//                         value: "Four Times a Day",
//                         label: "Four Times a Day",
//                       },
//                       { value: "SOS", label: "SOS" },
//                       { value: "At Bedtime", label: "At Bedtime" },
//                     ]}
//                     className="w-50 mx-3"
//                   />
//                 </div>
//                 <div className="d-flex mt-2 mx-3">
//                   {prescription.times.map((time, index) => (
//                     <div key={index} className="mr-2 d-flex align-items-center">
//                       {/* <FaClock className="text-success mr-1 mx-2 fs-5" /> */}
//                       <FaClock
//                         className="text-success mr-1  mx-2 fs-3"
//                         onClick={() => handleTimeClick(prescription.id, index)}
//                         style={{ cursor: "pointer" }}
//                       />
//                       <small className="fs-6">{time}</small>
//                     </div>
//                   ))}
//                 </div>
//               </FormGroup>
//             ))}
//           </div>

//           <div className="mx-3 mb-3 text-center">
//             <Button color="warning">Submit</Button>
//           </div>
//         </Card>
//         <Modal isOpen={timePickerData.isOpen} toggle={closeModal}>
//           <ModalHeader toggle={closeModal}>Select Time</ModalHeader>
//           <ModalBody>
//             <DatePicker
//               selected={timePickerData.selectedTime}
//               onChange={handleTimeChange}
//               showTimeSelect
//               showTimeSelectOnly
//               timeIntervals={15}
//               timeCaption="Time"
//               dateFormat="h:mm aa"
//             />
//             <Button color="primary" className="mt-3" onClick={closeModal}>
//               Set Time
//             </Button>
//           </ModalBody>
//         </Modal>
//       </Container>
//     </>
//   )
// }

// export default InboxForm
