import React, { useState } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap"
import InboxForm from "./InboxForm"

const prescriptionsData = [
  {
    date: "13-8-2024",
    orderId: "123456",
    patientName: "Saurabh Patel",
    mobileNumber: "9369852147",
    doctorName: "Dr. Gaurav",
    isKccPatient: "Yes",
  },
  {
    date: "13-8-2024",
    orderId: "223456",
    patientName: "Mohan Patel",
    mobileNumber: "9369852147",
    doctorName: "Dr. Gaurav",
    isKccPatient: "Yes",
  },
  {
    date: "13-8-2024",
    orderId: "323456",
    patientName: "Sohan Patel",
    mobileNumber: "9369852147",
    doctorName: "Dr. Gaurav",
    isKccPatient: "Yes",
  },
]

const medicineOrdersData = [
  {
    date: "13-8-2024",
    orderId: "654321",
    patientName: "Sunny Deol",
    mobileNumber: "9876543210",
    doctorName: "Dr. Shyam",
    isKccPatient: "No",
  },
  {
    date: "13-8-2024",
    orderId: "754321",
    patientName: "Anmol Mehra",
    mobileNumber: "9876543210",
    doctorName: "Dr. Shyam",
    isKccPatient: "No",
  },
  {
    date: "13-8-2024",
    orderId: "854321",
    patientName: "Rohan Mehra",
    mobileNumber: "9876543210",
    doctorName: "Dr. Shyam",
    isKccPatient: "No",
  },
]

const Inbox = () => {
  const [activeTab, setActiveTab] = useState("prescriptionsMedicineOrders")

  const [selectedOrder, setSelectedOrder] = useState(null)
  const handleOrderClick = order => {
    setSelectedOrder(order)
  }

  const toggleTab = tab => {
    if (activeTab !== tab) setActiveTab(tab)
  }

  const paraValueStyle = {
    fontSize: 20,
    fontFamily: "serif",
    fontWeight: "bold",
  }

  const renderContent = () => {
    const data =
      activeTab === "prescriptionsMedicineOrders"
        ? prescriptionsData
        : medicineOrdersData

    return (
      <Row className="mb-3">
        <Col xl="6" lg="6">
          <Card>
            <CardBody>
              <h5>
                {activeTab === "prescriptionsMedicineOrders"
                  ? "Prescriptions Order History"
                  : "Medicine Order History"}
              </h5>
              {data?.map((item, index) => (
                <Row key={index} className="mb-3">
                  <Col>
                    <Card
                      style={{
                        border: "1px solid #ced4da",
                        padding: "7px",
                        borderRadius: 10,
                        cursor: "pointer",
                      }}
                      onClick={() => handleOrderClick(data)}
                    >
                      <CardBody>
                        <Row>
                          <Col xs="6">
                            <p className="mb-1">
                              <small className="text-muted">Date:</small>
                            </p>
                            <p className="mb-3" style={paraValueStyle}>
                              {item.date}
                            </p>
                          </Col>
                          <Col xs="6" className="text-end">
                            <p className="mb-1">
                              <small className="text-muted">Order ID:</small>
                            </p>
                            <p className="mb-3" style={paraValueStyle}>
                              {item.orderId}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            <p className="mb-1">
                              <small className="text-muted">
                                Patient name:
                              </small>
                            </p>
                            <p className="mb-3" style={paraValueStyle}>
                              {item.patientName}
                            </p>
                          </Col>
                          <Col xs="6" className="text-end">
                            <p className="mb-1">
                              <small className="text-muted">
                                Mobile number
                              </small>
                            </p>
                            <p className="mb-3" style={paraValueStyle}>
                              {item.mobileNumber}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="6">
                            <p className="mb-1">
                              <small className="text-muted">
                                Referring doctor name:
                              </small>
                            </p>
                            <p className="mb-0" style={paraValueStyle}>
                              {item.doctorName}
                            </p>
                          </Col>
                          <Col xs="6" className="text-end">
                            <p className="mb-1">
                              <small className="text-muted">
                                Is KCC Patient?:
                              </small>
                            </p>
                            <p className="mb-0" style={paraValueStyle}>
                              {item.isKccPatient}
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              ))}
            </CardBody>
          </Card>
        </Col>
        <Col xl="6" lg="6">
          {selectedOrder ? (
            <InboxForm selectedOrder={selectedOrder} />
          ) : (
            <>
              <Card className="mt-5">
                <CardBody>
                  <div className="text-center mt-5 mb-5 fs-5 text-black">
                    Select an order to view details.
                  </div>
                </CardBody>
              </Card>
            </>
          )}
        </Col>
      </Row>
    )
  }

  return (
    <Container fluid className="mt-3">
      <Row className="mb-3">
        <Col>
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  fontFamily: "serif",
                  padding: 10,
                }}
                className={
                  activeTab === "prescriptionsMedicineOrders"
                    ? "active bg-primary text-white"
                    : "bg-light"
                }
                onClick={() => toggleTab("prescriptionsMedicineOrders")}
              >
                Prescriptions Digitization Only{" "}
                <span className="badge bg-danger text-white ms-2">2</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{
                  cursor: "pointer",
                  fontSize: 20,
                  fontFamily: "serif",
                  marginLeft: 10,
                  padding: 10,
                }}
                className={
                  activeTab === "MedicineOrders"
                    ? "active bg-primary text-white"
                    : "bg-light"
                }
                onClick={() => toggleTab("MedicineOrders")}
              >
                Medicine Orders{" "}
                <span className="badge bg-danger text-white ms-2">5</span>
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>

      {renderContent()}
    </Container>
  )
}

export default Inbox

// import React from "react"
// import { Container, Row, Col, Card, CardBody } from "reactstrap"

// const Inbox = () => {
//   return (
//     <Container className="mt-3">
//       <Row className="mb-3">
//         <Col>
//           <Card>
//             <CardBody className="d-flex p-0">
//               <div className="bg-warning text-white p-2 flex-grow-1">
//                 PrescriptionsMedicineOrders Digitization only{" "}
//                 <span className="badge bg-white text-warning ms-2">2</span>
//               </div>
//               <div className="bg-light p-2 flex-grow-1">
//                 Medicine MedicineOrders <span className="badge bg-success ms-2">5</span>
//               </div>
//             </CardBody>
//           </Card>
//         </Col>
//       </Row>

//       {[1, 2, 3, 4].map((_, index) => (
//         <Row key={index} className="mb-3">
//           <Col>
//             <Card>
//               <CardBody>
//                 <Row>
//                   <Col xs="6">
//                     <p className="mb-1">
//                       <small className="text-muted">Date:</small>
//                     </p>
//                     <p className="mb-3">13-8-2024</p>
//                   </Col>
//                   <Col xs="6" className="text-end">
//                     <p className="mb-1">
//                       <small className="text-muted">Order ID:</small>
//                     </p>
//                     <p className="mb-3">123456</p>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col xs="6">
//                     <p className="mb-1">
//                       <small className="text-muted">Patient name:</small>
//                     </p>
//                     <p className="mb-3">Saurabh Patel</p>
//                   </Col>
//                   <Col xs="6" className="text-end">
//                     <p className="mb-1">
//                       <small className="text-muted">Mobile number</small>
//                     </p>
//                     <p className="mb-3">9369852147</p>
//                   </Col>
//                 </Row>
//                 <Row>
//                   <Col xs="6">
//                     <p className="mb-1">
//                       <small className="text-muted">
//                         Referring doctor name:
//                       </small>
//                     </p>
//                     <p className="mb-0">Dr. Gaurav</p>
//                   </Col>
//                   <Col xs="6" className="text-end">
//                     <p className="mb-1">
//                       <small className="text-muted">Is KCC Patient?:</small>
//                     </p>
//                     <p className="mb-0">Yes</p>
//                   </Col>
//                 </Row>
//               </CardBody>
//             </Card>
//           </Col>
//         </Row>
//       ))}
//     </Container>
//   )
// }

// export default Inbox
