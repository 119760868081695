import React, { useState } from "react"
import {
  Card,
  CardBody,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import HospitalImage from "../../assets/images/health/hospital.png"
const HospitalIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
      stroke="#000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 9H15"
      stroke="#000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 6V12"
      stroke="#000"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const HospitalReferral = () => {
  const [activeTab, setActiveTab] = useState("1")

  const tabs = [
    { id: "1", name: "Hospital in Delhi NCR" },
    { id: "2", name: "Hospital in Mumbai" },
    { id: "3", name: "Hospital in Pune" },
  ]

  const hospitals = [
    { id: 1, name: "Wockhardt Hospital", location: "Gurugram, India" },
    { id: 2, name: "Wockhardt Hospital", location: "Gurugram, India" },
    { id: 3, name: "Wockhardt Hospital", location: "Gurugram, India" },
    { id: 4, name: "Wockhardt Hospital", location: "Gurugram, India" },
    { id: 5, name: "Wockhardt Hospital", location: "Gurugram, India" },
  ]

  const scrollStyle = {
    overflowX: "auto",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
  }

  const hideScrollbarCSS = `
    ::-webkit-scrollbar {
      display: none;
    }
  `

  return (
    <Card
      className="mt-3"
      style={{
        border: "1px solid grey",
        borderRadius: "15px",
      }}
    >
      <CardBody>
        <h6 style={{ marginBottom: "1rem" }}>
          Refer Patient for Elective Surgery / Hospitalization
        </h6>
        <div style={{ ...scrollStyle, marginBottom: "1rem" }}>
          <style>{hideScrollbarCSS}</style>
          <div style={{ display: "flex", borderBottom: "1px solid #dee2e6" }}>
            {tabs.map(tab => (
              <div
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                style={{
                  padding: "0.5rem 1rem",
                  cursor: "pointer",
                  color: "#0077B5",
                  whiteSpace: "nowrap",
                  borderBottom:
                    activeTab === tab.id ? "2px solid #0077B5" : "none",
                  fontWeight: activeTab === tab.id ? "bold" : "normal",
                }}
              >
                {tab.name}
              </div>
            ))}
          </div>
        </div>
        <TabContent activeTab={activeTab}>
          {tabs.map(tab => (
            <TabPane key={tab.id} tabId={tab.id}>
              <div style={{ ...scrollStyle, display: "flex", gap: "1rem" }}>
                <style>{hideScrollbarCSS}</style>
                {hospitals.map(hospital => (
                  <Card
                    key={hospital.id}
                    style={{
                      minWidth: "150px",
                      maxWidth: "150px",
                      flexShrink: 0,
                    }}
                  >
                    <CardBody
                      style={{
                        border: "1px solid grey",
                        borderRadius: "15px",
                        // display: "flex",
                        // flexWrap: "wrap",
                        // alignItems: "center",
                      }}
                    >
                      <div className="text-end">
                        <Input
                          type="checkbox"
                          // style={{ marginRight: "1rem" }}
                        />
                      </div>
                      <div className="text-center">
                        {/* <HospitalIcon /> */}
                        <img
                          src={HospitalImage}
                          alt="hospital"
                          height={60}
                          width={60}
                        />
                      </div>
                      <div className="text-center">
                        <div
                          style={{
                            fontSize: "0.9rem",
                            fontFamily: "serif",
                            color: "black",
                          }}
                        >
                          {hospital.name}
                        </div>
                        <div
                          style={{
                            fontSize: "0.8rem",
                            fontFamily: "serif",
                            color: "#666",
                          }}
                        >
                          {hospital.location}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </div>
            </TabPane>
          ))}
        </TabContent>
      </CardBody>
    </Card>
  )
}

export default HospitalReferral
