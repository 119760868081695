// src/goproWebcamService.js

import axios from "axios"

const GOPRO_IP = "10.5.5.9" // Replace with your GoPro camera's IP address
// const GOPRO_IP = "10.5.5.110" // Replace with your GoPro camera's IP address
const API_URL = `http://${GOPRO_IP}/gp/gpControl`

const GoproService = {

  
  startWebcam2: async () => {
    try {
      // const response = await axios.post(
      //   `http://10.5.5.110:8080/gopro/webcam/start`
      // )
      const response = await axios.post(
        `http://${GOPRO_IP}/gp/gpControl/command/webcam/start`
      )
      // const response = await axios.get(`${API_URL}/command/shutter?p=1`)

      return response.data
    } catch (error) {
      console.error("Error starting webcam mode:", error)
    }
  },

  startWebcam: async () => {
    try {
      const response = await axios.post(
        // `http://10.5.5.9/gp/gpControl/command/webcam/start`
        // `http://${GOPRO_IP}/gp/gpControl/command/webcam/start`
        // `http://${GOPRO_IP}:8080/gopro/webcam/start`,
       'http://10.5.5.9:8080/gopro/webcam/preview'

      )
      console.warn(response,"res")
      return response.data
    } catch (error) {
      console.error("Error starting webcam mode:", error)
    }
  },



  stopWebcam: async () => {
    try {
      const response = await axios.post(
        `http://${GOPRO_IP}/gp/gpControl/command/webcam/stop`
      )
      return response.data
    } catch (error) {
      console.error("Error stopping webcam mode:", error)
    }
  },
  //   capturePhoto: async () => {
  //     try {
  //       const response = await axios.get(`${API_URL}/command/shutter?p=1`)
  //       return response.data
  //     } catch (error) {
  //       console.error("Error capturing photo:", error)
  //     }
  //   },

  getWebcamStatus: async () => {
    try {
      const response = await axios.get(
        `http://${GOPRO_IP}/gp/gpControl/command/webcam/getStatus`
      )
      return response.data
    } catch (error) {
      console.error("Error getting webcam status:", error)
    }
  },
}

export default GoproService

// // src/goproService.js

// import axios from "axios"
// // http://10.5.5.9/bacpac/PW?t=%3Cwifi_password%3E&p=%01
// const GOPRO_IP = "10.5.5.9" // Replace with your GoPro camera's IP if different
// const API_URL = `http://${GOPRO_IP}/gp/gpControl`

// const GoproService = {
//   startRecording: async () => {
//     try {
//       const response = await axios.get(`${API_URL}/command/shutter?p=1`)
//       return response.data
//     } catch (error) {
//       console.error("Error starting recording:", error)
//     }
//   },

//   stopRecording: async () => {
//     try {
//       const response = await axios.get(`${API_URL}/command/shutter?p=0`)
//       return response.data
//     } catch (error) {
//       console.error("Error stopping recording:", error)
//     }
//   },

//   capturePhoto: async () => {
//     try {
//       const response = await axios.get(`${API_URL}/command/shutter?p=1`)
//       return response.data
//     } catch (error) {
//       console.error("Error capturing photo:", error)
//     }
//   },

//   getCameraStatus: async () => {
//     try {
//       const response = await axios.get(`${API_URL}/status`)
//       return response.data
//     } catch (error) {
//       console.error("Error fetching camera status:", error)
//     }
//   },

//   changeCameraMode: async mode => {
//     // mode: 0 - Video, 1 - Photo, 2 - Multishot
//     try {
//       const response = await axios.get(
//         `${API_URL}/command/sub_mode?mode=${mode}`
//       )
//       return response.data
//     } catch (error) {
//       console.error("Error changing camera mode:", error)
//     }
//   },
// }

// export default GoproService
