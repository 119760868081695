import React, { useState } from "react"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
} from "reactstrap"
import Select from "react-select"

const maritalStatusOptions = [
  { value: "single", label: "Single" },
  { value: "married", label: "Married" },
  { value: "separated", label: "Separated" },
  { value: "divorced", label: "Divorced" },
  { value: "live_in_relationship", label: "Live-in Relationship" },
]

const maritalStatusOptions2 = [
  "Single",
  "Married",
  "Separated",
  "Divorced",
  "Live-in Relationship",
]

const allergyOptions = [
  { value: "none", label: "None" },
  { value: "cows_milk", label: "Cow's Milk" },
  { value: "eggs", label: "Eggs" },
  { value: "peanuts", label: "Peanuts" },
  { value: "shellfish", label: "Shellfish" },
  { value: "wheat", label: "Wheat" },
  { value: "soy", label: "Soy" },
  { value: "fish", label: "Fish" },
  { value: "chamomile", label: "Chamomile" },
  { value: "linseed", label: "Linseed" },
  { value: "sesame_seeds", label: "Sesame Seeds" },
  { value: "peach", label: "Peach" },
  { value: "banana", label: "Banana" },
  { value: "avocado", label: "Avocado" },
  { value: "kiwi_fruit", label: "Kiwi Fruit" },
  { value: "passion_fruit", label: "Passion Fruit" },
  { value: "celery", label: "Celery" },
  { value: "garlic", label: "Garlic" },
  { value: "mustard_seeds", label: "Mustard Seeds" },
  { value: "aniseed", label: "Aniseed" },
  { value: "eczema", label: "Eczema" },
  { value: "hives", label: "Hives" },
  { value: "contact_dermatitis", label: "Contact Dermatitis" },
  { value: "insect_stings", label: "Insect Stings" },
  { value: "sun", label: "Sun" },
  { value: "cosmetic_products", label: "Cosmetic Products" },
  { value: "nickel", label: "Nickel" },
  { value: "pollen", label: "Pollen" },
  { value: "dust", label: "Dust" },
  { value: "molds", label: "Molds" },
  { value: "latex", label: "Latex" },
]

const familyPlanningOptions = [
  { value: "none", label: "None" },
  { value: "injectable_contraceptive", label: "Injectable Contraceptive" },
  { value: "oral_contraceptive_pills", label: "Oral contraceptive pills" },
  {
    value: "emergency_contraceptive_pills",
    label: "Emergency Contraceptive Pills",
  },
  { value: "condoms", label: "Condoms" },
  { value: "iucd", label: "Intra-Uterine Contraceptive Devices (IUCD)" },
  { value: "implants", label: "Implants" },
  {
    value: "combined_contraceptive_patch",
    label: "Combined contraceptive patch",
  },
  {
    value: "contraceptive_vaginal_ring",
    label: "Contraceptive vaginal ring (CVR)",
  },
  { value: "female_sterilization", label: "Female Sterilization" },
  { value: "male_sterilization", label: "Male Sterilization" },
]

const familyPlanningOptions2 = [
  "None",
  "Injectable Contraceptive",
  "Oral contraceptive pills",
  "Emergency Contraceptive Pills",
  "Condoms",
  "Intra-Uterine Contraceptive Devices (IUCD)",
  "Implants",
  "Combined contraceptive patch",
  "Contraceptive vaginal ring (CVR)",
  "Female Sterilization",
  "Male Sterilization",
]

const allergies = [
  "None",
  "Cow's Milk",
  "Eggs",
  "Peanuts",
  "Shellfish",
  "Wheat",
  "Soy",
  "Fish",
  "Chamomile",
  "Linseed",
  "Sesame Seeds",
  "Peach",
  "Banana",
  "Avocado",
  "Kiwi Fruit",
  "Passion Fruit",
  "Celery",
  "Garlic",
  "Mustard Seeds",
  "Aniseed",
  "Eczema",
  "Hives",
  "Contact Dermatitis",
  "Insect Stings",
  "Sun",
  "Cosmetic Products",
  "Nickel",
  "Pollen",
  "Dust",
  "Molds",
  "Latex",
]

const PersonalProfiling = () => {
  const [formData, setFormData] = useState({
    maritalStatus: null,
    numPregnancies: "",
    numMiscarriages: "",
    numChildren: "",
    ageLastChild: "",
    knownAllergies: [],
    familyPlanning: [],
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const [selectedAllergies, setSelectedAllergies] = useState([])

  const toggleAllergy = allergy => {
    setSelectedAllergies(prevSelected =>
      prevSelected.includes(allergy)
        ? prevSelected.filter(a => a !== allergy)
        : [...prevSelected, allergy]
    )
  }

  const [selectedMaritialStatus, setSelectedMaritialStatus] = useState("")

  const toggleMaritialStatus = maritialStatus => {
    setSelectedMaritialStatus(maritialStatus)
    // console.log(maritialStatus)
  }

  const [selectedfamilyPlanning, setSelectedFamilyPlanning] = useState([])

  const toggleFamilyPlanning = familyPlanning => {
    setSelectedFamilyPlanning(prevSelected =>
      prevSelected.includes(familyPlanning)
        ? prevSelected.filter(a => a !== familyPlanning)
        : [...prevSelected, familyPlanning]
    )
  }
  return (
    <div>
      {/* <h4>Personal Profile</h4> */}
      <Row>
        {/* <Col md={6}>
          {submittedData && (
            <>
              <h5>Personal Profile</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Marital status</Label>
                    <div>
                      A.- {submittedData?.maritalStatus?.label || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Number of Pregnancies</Label>
                    <div>A.- {submittedData?.numPregnancies || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Numbers of Miscarriages/Abortions/Still Birth
                    </Label>
                    <div>A.- {submittedData?.numMiscarriages || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Number of Child(ren)</Label>
                    <div>A.- {submittedData?.numChildren || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Age of Last Child</Label>
                    <div>A.- {submittedData?.ageLastChild || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Known Allergies</Label>
                    <div>
                      A.-{" "}
                      {submittedData?.knownAllergies
                        ?.map(allergy => allergy.label)
                        .join(", ") || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Family Planning (Method Adopted)</Label>
                    <div>
                      A.-{" "}
                      {submittedData?.familyPlanning
                        ?.map(method => method.label)
                        .join(", ") || "N/A"}
                    </div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col> */}
        <Col md={6}>
          <>
            <h5>Personal Profile</h5>
            <Card>
              <CardBody>
                <FormGroup style={formGroupStyle}>
                  <Label>Q.- Marital status</Label>
                  <div>A.- {submittedData?.maritalStatus?.label || "N/A"}</div>
                </FormGroup>
                <FormGroup style={formGroupStyle}>
                  <Label>Q.- Number of Pregnancies</Label>
                  <div>A.- {submittedData?.numPregnancies || "N/A"}</div>
                </FormGroup>
                <FormGroup style={formGroupStyle}>
                  <Label>
                    Q.- Numbers of Miscarriages/Abortions/Still Birth
                  </Label>
                  <div>A.- {submittedData?.numMiscarriages || "N/A"}</div>
                </FormGroup>
                <FormGroup style={formGroupStyle}>
                  <Label>Q.- Number of Child(ren)</Label>
                  <div>A.- {submittedData?.numChildren || "N/A"}</div>
                </FormGroup>
                <FormGroup style={formGroupStyle}>
                  <Label>Q.- Age of Last Child</Label>
                  <div>A.- {submittedData?.ageLastChild || "N/A"}</div>
                </FormGroup>
                <FormGroup style={formGroupStyle}>
                  <Label>Q.- Known Allergies</Label>
                  <div>
                    A.-{" "}
                    {submittedData?.knownAllergies
                      ?.map(allergy => allergy.label)
                      .join(", ") || "N/A"}
                  </div>
                </FormGroup>
                <FormGroup style={formGroupStyle}>
                  <Label>Q.- Family Planning (Method Adopted)</Label>
                  <div>
                    A.-{" "}
                    {submittedData?.familyPlanning
                      ?.map(method => method.label)
                      .join(", ") || "N/A"}
                  </div>
                </FormGroup>
              </CardBody>
            </Card>
          </>
        </Col>
        <Col md={6}>
          <h5>Personal Profile</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="maritalStatus">Marital status</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {maritalStatusOptions2?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                {/* <FormGroup>
                  <Label for="maritalStatus">Marital status</Label>
                  <Select
                    id="maritalStatus"
                    name="maritalStatus"
                    options={maritalStatusOptions}
                    onChange={handleChange}
                    value={formData.maritalStatus}
                  />
                </FormGroup> */}
                <FormGroup>
                  <Label for="numPregnancies">Number of Pregnancies</Label>
                  <Input
                    type="number"
                    id="numPregnancies"
                    name="numPregnancies"
                    placeholder="Number of Pregnancies"
                    value={formData.numPregnancies}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="numMiscarriages">
                    Numbers of Miscarriages/Abortions/Still Birth
                  </Label>
                  <Input
                    type="number"
                    id="numMiscarriages"
                    name="numMiscarriages"
                    placeholder="Numbers of Miscarriages/Abortions/Still Birth"
                    value={formData.numMiscarriages}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="numChildren">Number of Child(ren)</Label>
                  <Input
                    type="number"
                    id="numChildren"
                    name="numChildren"
                    placeholder="Number of Child(ren)"
                    value={formData.numChildren}
                    onChange={handleInputChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="ageLastChild">Age of Last Child</Label>
                  <Input
                    type="number"
                    id="ageLastChild"
                    name="ageLastChild"
                    placeholder="Age of Last Child"
                    value={formData.ageLastChild}
                    onChange={handleInputChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">
                    {" "}
                    Family Planning (Method Adopted)
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {familyPlanningOptions2?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="maritalStatus">Known Allergies</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {allergies?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergies.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleAllergy(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {/* <FormGroup>
                  <Label for="knownAllergies">Known Allergies</Label>
                  <Select
                    id="knownAllergies"
                    name="knownAllergies"
                    options={allergyOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={handleChange}
                    value={formData.knownAllergies}
                  />
                </FormGroup> */}
                {/* <FormGroup>
                  <Label for="familyPlanning">
                    Family Planning (Method Adopted)
                  </Label>
                  <Select
                    id="familyPlanning"
                    name="familyPlanning"
                    options={familyPlanningOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={handleChange}
                    value={formData.familyPlanning}
                  />
                </FormGroup> */}
                <Button type="submit" color="primary" style={{}}>
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  borderRadius: 7,
  padding: 10,
}

export default PersonalProfiling
