import React, { useState } from "react"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Badge,
} from "reactstrap"
// for range slider
import "react-rangeslider/lib/index.css"
import { Form, Link } from "react-router-dom"
import { toast } from "react-toastify"
import Select from "react-select"

const Options = ["Yes", "No"]
const Recentallergies = ["Yes", "No"]

const PastSurgeries = [
  "Any brain surgery",
  "Heart Surgeries (Angioplasty, Bypass, Pacemaker etc)",
  "Kidney Surgeries (Kidney transplant, Kidney stone etc)",
  "Liver Surgeries (Liver Transplant etc)",
  "Any spine surgery",
  "Orthopedic Procedures (Knee, Hip, Shoulder replacement Surgeries)",
  "Cancer Surgeries (Breast, Prostate, Cervical, Esophageal etc)",
  "Uterus Removal/ Ovarian Cysts etc",
  "Cancer Treatments (Bone Marrow Transplants, Chemotherapy, Radiotherapy, Immunotherapy etc)",
  "Hormone Therapies",
]
const PostSurgeryComplications = [
  "Frequent/ persistent fever (mild or high)",
  "Sense of confusion, memory loss or forgetfulness",
  "Allergies (skin rashes, abnormal hair falls, itching, cold and cough etc)",
  "Asthmatic attacks or shortness of breath",
  "Frequent blood clots",
  "Compromised immunity (frequent episodes of viral or urinary tract infections)",
  "Frequent chest pain or rapid breathing/ heart rate",
  "Persistent vomiting or diarrhea",
  "Frequent indigestion and constipation",
  "Frequent blue lips or skin",
  "Redness and swelling around the cut",
  "Fluid or pus draining from the wound",
]

const HistoryOf_Accident_Injuryor_Trauma = [
  "Repeated memories of the event or flashbacks",
  "Nightmares",
  "Intense fear that the traumatic event will recur, particularly around anniversaries of the event (or when going back to the scene of the original event)",
  "Withdrawal and isolation from day-to-day activities",
  "Continued avoidance of reminders of the event",
  "Shifts mood or changes in thought patterns",
  "Irritability",
  "Sudden, dramatic mood shifts",
  "Anxiety and nervousness",
  "Anger",
  "Denial",
  "Depression that can commonly occur along with traumatic stress",
  "Difficulty concentrating",
  "Altered sleeping or insomnia",
  "Physical symptoms of stress, such as headaches and nausea",
  "Worsening of an existing medical condition",
]

const PostBloodTransfusionData = [
  "Nausea",
  "Fever",
  "Chills",
  "Chest and lower back pain",
  "Dark urine",
]

const PastSurgeriesHospitalization = () => {
  const [formData, setFormData] = useState({
    maritalStatus: null,
    numPregnancies: "",
    numMiscarriages: "",
    numChildren: "",
    ageLastChild: "",
    knownAllergies: [],
    familyPlanning: [],
    HeightFeetInches: "",
    weight: null,
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const [selectedAllergies, setSelectedAllergies] = useState([])

  const toggleAllergy = allergy => {
    setSelectedAllergies(prevSelected =>
      prevSelected.includes(allergy)
        ? prevSelected.filter(a => a !== allergy)
        : [...prevSelected, allergy]
    )
  }

  // Recentallergies
  const [selectedAllergyOption, setSelectedAllergyOption] = useState(null)

  const toggleAllergyOption = data => {
    setSelectedAllergyOption(data)
  }

  // PostbloodTransfusionOption
  const [
    selectedPostbloodTransfusionOption,
    setselectedPostbloodTransfusionOpton,
  ] = useState(null)

  const togglePostbloodTransfusionOption = data => {
    setselectedPostbloodTransfusionOpton(data)
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          {submittedData && (
            <>
              <h5>Past Surgeries & Hospitalization</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Past Surgeries</Label>
                    <div>A.- {submittedData?.height || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Post Surgeries Complications</Label>
                    <div>A.- {submittedData?.mentalHealthProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.-Do you have any history of accident,injury or trauma?
                    </Label>
                    <div>
                      A.- {submittedData?.mentalHealthWellbeing || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Have you ever undergone blood transfusion?
                    </Label>
                    <div>A.- {submittedData?.cognitiveImpairment || "N/A"}</div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col>

        <Col md={6}>
          <h5>Past Surgeries & Hospitalization</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="maritalStatus">Past Surgeries</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {PastSurgeries?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergies.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleAllergy(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="maritalStatus">
                    Post Surgeries Complications
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {PostSurgeryComplications?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergies.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleAllergy(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="numPregnancies">
                    Do you have any history of accident,injury or trauma?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Options?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergyOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleAllergyOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedAllergyOption === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus">
                      If yes, please indicate health impacts from the options
                    </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {HistoryOf_Accident_Injuryor_Trauma?.map(allergy => (
                        <Col key={allergy} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAllergies.includes(allergy)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAllergy(allergy)}
                            className="m-1"
                          >
                            {allergy}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}
                <FormGroup>
                  <Label for="numPregnancies">
                    Have you ever undergone blood transfusion?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Options?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedPostbloodTransfusionOption === data
                              ? "primary"
                              : "light"
                          }
                          onClick={() => togglePostbloodTransfusionOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedPostbloodTransfusionOption === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus">
                      If yes, have you noticed any of the following effects post
                      blood transfusion
                    </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {PostBloodTransfusionData?.map(allergy => (
                        <Col key={allergy} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAllergies.includes(allergy)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAllergy(allergy)}
                            className="m-1"
                          >
                            {allergy}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <Button type="submit" color="primary" style={{}}>
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  // border: "2px solid  #FF7738",
  borderRadius: 7,
  padding: 10,
}

export default PastSurgeriesHospitalization
