import React from "react"
import { Card, CardBody, Row, Col, Progress } from "reactstrap"
import "bootstrap/dist/css/bootstrap.min.css"
// import "boxicons/css/boxicons.min.css"

// Uncomment and adjust paths if using custom icons
// import MaleIcon from './icons/male-icon.svg';
// import FemaleIcon from './icons/female-icon.svg';

const GenderRatioCard = () => {
  return (
    <Card className="mb-4">
      <CardBody>
        <h5 className="mb-4">Male/ Female Ratio</h5>
        <Row className="align-items-center mb-4">
          <Col xs={2} className="text-center">
            <i
              className="bx bx-male text-primary"
              style={{ fontSize: "2rem" }}
            ></i>
            {/* <img src={MaleIcon} alt="Male" style={{ width: '2rem', height: '2rem' }} /> */}
          </Col>
          <Col xs={2} className="text-center">
            <i
              className="bx bx-female text-danger"
              style={{ fontSize: "2rem" }}
            ></i>
            {/* <img src={FemaleIcon} alt="Female" style={{ width: '2rem', height: '2rem' }} /> */}
          </Col>
          <Col xs={8}>
            <Progress multi className="progress-xl">
              <Progress bar color="primary" value={95}>
                95
              </Progress>
              <Progress bar color="danger" value={88}>
                88
              </Progress>
            </Progress>
            {/* 2nd */}
            {/* <Progress
              bar
              color="primary"
              className="progress-xl mt-2"
              value={95}
            >
              95
            </Progress>
            <Progress
              bar
              color="danger"
              className="progress-xl mt-2"
              value={88}
            >
              88
            </Progress> */}
          </Col>
        </Row>

        <h5 className="mb-4">Literacy Ratio</h5>
        <Row className="align-items-center">
          <Col xs={2} className="text-center">
            <i
              className="bx bxs-user text-primary"
              style={{ fontSize: "2rem" }}
            ></i>
            {/* <img src={MaleIcon} alt="Male" style={{ width: '2rem', height: '2rem' }} /> */}
          </Col>
          <Col xs={2} className="text-center">
            <i
              className="bx bxs-user-voice text-danger"
              style={{ fontSize: "2rem" }}
            ></i>
            {/* <img src={FemaleIcon} alt="Female" style={{ width: '2rem', height: '2rem' }} /> */}
          </Col>
          <Col xs={8}>
            <Progress multi className="progress-xl">
              <Progress bar color="primary" value={78}>
                <span className="text-dark">Male 78%</span>
              </Progress>
              <Progress bar color="danger" value={58}>
                <span className="text-dark">Female 58%</span>
              </Progress>
            </Progress>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default GenderRatioCard
