import React, { useEffect, useRef, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import classnames from "classnames"
import PaitentVitalTable from "./PaitentVitalTable"
import NewbornCaseRecordHeader from "./NewbornCaseRecordHeader"
import PaitentCaseFileCollapseTab from "./PaitentCaseFileCollapseTab"

const LeftBar = () => {
  // document.title = "Video Calling"

  const [activeTab, setactiveTab] = useState("1")
  const [activeTab1, setactiveTab1] = useState("5")
  const toggle = tab => {
    if (activeTab !== tab) {
      setactiveTab(tab)
    }
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }
  const formGroupStyle = {
    border: "2px solid  #e5e8eb",
    // border: "1px solid grey",
    borderRadius: 7,
    // padding: 10,
  }

  const iframeRef = useRef(null)
  const openFullScreen = () => {
    if (iframeRef.current) {
      // Request fullscreen for the iframe's container
      if (iframeRef.current.requestFullscreen) {
        iframeRef.current.requestFullscreen()
      } else if (iframeRef.current.mozRequestFullScreen) {
        iframeRef.current.mozRequestFullScreen() // Firefox
      } else if (iframeRef.current.webkitRequestFullscreen) {
        iframeRef.current.webkitRequestFullscreen() // Chrome, Safari, and Opera
      } else if (iframeRef.current.msRequestFullscreen) {
        iframeRef.current.msRequestFullscreen() // IE/Edge
      }
    }
  }

  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    // Check screen size on mount
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth < 768) // Adjust 768px as needed for your breakpoint
    }
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize)

    return () => window.removeEventListener("resize", checkScreenSize)
  }, [])

  return (
    <>
      <Container fluid={true} style={{ paddingTop: isMobile ? "280px" : "0" }}>
        {isMobile && ( // Conditionally render iframe on larger screens only
          <Col md="12" className="d-flex flex-column align-items-center mb-4">
            <>
              <>
                <Card
                  // style={{ width: "100%" }}
                  style={{
                    width: "100%",
                    position: "fixed",
                    top: 0,
                    zIndex: 1000,
                    paddingTop: "75px",
                  }}
                >
                  <div
                    ref={iframeRef} // Reference to the div containing the iframe
                    style={{
                      position: "relative",
                      paddingTop: "56.25%", // 16:9 aspect ratio
                      width: "100%",
                    }}
                  >
                    <iframe
                      src="https://livepush.io/embed/emmnW9tAl8m5CWK5"
                      allowFullScreen="1"
                      frameBorder="0"
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                    ></iframe>
                  </div>
                  {/* <Button
                    color="primary"
                    onClick={openFullScreen}
                    className="mt-1"
                  >
                    View Full Screen
                  </Button> */}
                  {/* <button
                    type="button"
                    onClick={openFullScreen}
                    className="btn header-item noti-icon"
                    data-toggle="fullscreen"
                  >
                    <i className="bx bx-fullscreen" />
                  </button> */}
                </Card>
              </>
            </>
          </Col>
        )}
        <>
          <>
            <Card style={formGroupStyle}>
              <CardBody>
                {/* <CardTitle className="h4"> Tabs</CardTitle> */}
                <NewbornCaseRecordHeader />

                <Nav pills className="navtab-bg nav-justified mb-2" tabs>
                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        fontSize: window.innerWidth < 768 ? 15 : 24,
                        fontFamily: "inherit",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                      className={classnames({
                        active: activeTab1 === "5",
                      })}
                      onClick={() => {
                        toggle1("5")
                      }}
                    >
                      Paitent's Vital
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{
                        cursor: "pointer",
                        // fontSize: 18,
                        fontSize: window.innerWidth < 768 ? 15 : 24,
                        fontFamily: "inherit",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                      className={classnames({
                        active: activeTab1 === "7",
                      })}
                      onClick={() => {
                        toggle1("7")
                      }}
                    >
                      Paitent's Case File
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent activeTab={activeTab1} className="p-0 text-muted">
                  <TabPane tabId="5">
                    <Row>
                      {/* <NewbornCaseRecordHeader /> */}
                      <PaitentVitalTable />
                    </Row>
                  </TabPane>

                  <TabPane tabId="7">
                    <Row>
                      {/* <NewbornCaseRecordHeader /> */}
                      <PaitentCaseFileCollapseTab />
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </>
        </>
      </Container>
    </>
  )
}

export default LeftBar
