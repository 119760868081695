import React, { useState } from "react"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Badge,
} from "reactstrap"
// for range slider
import "react-rangeslider/lib/index.css"

const Options = ["Yes", "No"]
const Recentallergies = ["Yes", "No"]
const alternativeTherapiesOptions = ["Yes", "No"]
// const alternativeTherapiesOptions = ["Yes", "No"]

const allergies = ["option1", "option2", "options3", "option4", "option5"]

const maritalStatusOptions2 = [
  "option1",
  "option2",
  "options3",
  "option4",
  "option5",
]

const NailsProblem = [
  "Pale nails",
  "White nails",
  "Yellow nails",
  "Bluish nails",
  "Rippled nails",
  "Cracked or Split nails",
  "Puffy nail fold",
  "Dark lines beneath the nail",
  "Gnawed nails",
]

const FollowingSymptoms = [
  "Irregular menstrual cycles",
  "Weight gain or weight loss",
  "Fatigue",
  "Changes in appetite",
  "Changes in mood, such as depression or anxiety",
  "Difficulty sleeping",
  "Hair loss or excessive hair growth",
  "Skin changes, such as acne or dry skin",
  "Changes in libido or sexual function",
  "Infertility or difficulty conceiving",
  "Hot flashes or night sweats",
  "Muscle weakness or tremors",
  "Increased thirst or urination",
  "Swelling in the neck (goiter)",
  "Changes in breast size or tenderness",
  "Weakness",
  "Headaches",
  "Shortness of breath",
  "Frequent infections",
]
const familyPlanningOptions2 = [
  "None",
  "option1",
  "option2",
  "options3",
  "option4",
  "option5",
]

const VitaminD_Deficiency = [
  "Muscle weakness",
  "Bone pain or tenderness",
  "Depression",
  "Impaired wound healing",
]

const skinProblemData = [
  "Acne (Acne vulgaris)",
  "Atopic dermatitis (Eczema)",
  "Shingles (Herpes Zoster)",
  "Hives (Urticaria)",
  "Sunburn",
  "Contact dermatitis",
  "Diaper rash",
  "Rosacea",
  "Athlete's foot (Tinea Pedis)",
  "Basal cell carcinoma",
]

const HearingImpairmentData = [
  "Hearing Profile",
  "Hearing Loss",
  "Ear Infection or Ear Canal Blockage",
  "Noise in ears such as buzzing, roaring, clicking, hissing, humming",
  "Feeling something in ears",
]

const IronDeficiencies = [
  "Pale skin",
  "Dizziness or lightheadedness",
  "Cold hands and feet",
  "Brittle nails",
  "Rapid heartbeat",
]

const VitaminB12deficiency = [
  "Dizziness or lightheadedness",
  "Pale or jaundiced skin",
  "Tingling or numbness in hands and feet",
  "Difficulty walking or balance problems",
  "Memory problems",
  "Mood changes",
]

const Calciumdeficiency = [
  "Muscle cramps or spasms",
  "Numbness or tingling in fingers and toes",
  "Weak and brittle nails",
  "Dental problems (such as weak teeth or gum disease)",
  "Osteoporosis or osteopenia (loss of bone density)",
]

const LodineDeficiency = [
  "Swelling in the neck (goiter)",
  "Weight gain",
  "Dry skin and hair",
  "Depression",
  "Memory problems",
  "Intolerance to cold temperatures",
]

const VitaminCDeficiency = [
  "Easy bruising",
  "Slow wound healing",
  "Dry, splitting hair",
  "Swollen and painful joints",
  "Bleeding gums",
  "Rough, dry, and scaly skin",
]

const HarmonalImbalanceandDeficiencies = () => {
  const [formData, setFormData] = useState({
    maritalStatus: null,
    numPregnancies: "",
    numMiscarriages: "",
    numChildren: "",
    ageLastChild: "",
    knownAllergies: [],
    familyPlanning: [],
    HeightFeetInches: "",
    weight: null,
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const [selectedAllergies, setSelectedAllergies] = useState([])

  const toggleAllergy = allergy => {
    setSelectedAllergies(prevSelected =>
      prevSelected.includes(allergy)
        ? prevSelected.filter(a => a !== allergy)
        : [...prevSelected, allergy]
    )
  }

  // Recentallergies
  const [selectedAllergyOption, setSelectedAllergyOption] = useState(null)

  const toggleAllergyOption = data => {
    setSelectedAllergyOption(data)
  }

  const [selectedMaritialStatus, setSelectedMaritialStatus] = useState("")

  const toggleMaritialStatus = maritialStatus => {
    setSelectedMaritialStatus(maritialStatus)
    // console.log(maritialStatus)
  }

  const [selectedfamilyPlanning, setSelectedFamilyPlanning] = useState([])

  const toggleFamilyPlanning = familyPlanning => {
    setSelectedFamilyPlanning(prevSelected =>
      prevSelected.includes(familyPlanning)
        ? prevSelected.filter(a => a !== familyPlanning)
        : [...prevSelected, familyPlanning]
    )
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          {submittedData && (
            <>
              <h5>Harmonal Imbalanceand Deficiencies</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any skin problem?</Label>
                    <div>A.- {submittedData?.height || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any hearing impairment?</Label>
                    <div>A.- {submittedData?.mentalHealthProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Diet Plan</Label>
                    <div>
                      A.- {submittedData?.mentalHealthWellbeing || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any nail problem?</Label>
                    <div>A.- {submittedData?.cognitiveImpairment || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Have you noticed any symptoms from the following?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Have you noticed any symptoms from the following
                      indicating Vitamin D deficiency?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Have you noticed any symptoms from the following
                      indicating Iron deficiency?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Have you noticed any symptoms from the following
                      indicating Vitamin B12 deficiency?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Have you noticed any symptoms from the following
                      indicating lodine deficiency?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Have you noticed any symptoms from the following
                      indicating Vitamin C deficiency?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Have you noticed any symptoms from the following
                      indicating lodine deficiency?
                    </Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col>

        <Col md={6}>
          <h5>Harmonal Imbalanceand Deficiencies</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="numPregnancies">
                    Do you have any skin problem?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Options?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergyOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleAllergyOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {Options === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus"> Skin Profile </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {skinProblemData?.map(allergy => (
                        <Col key={allergy} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAllergies.includes(allergy)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAllergy(allergy)}
                            className="m-1"
                          >
                            {allergy}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label for="numPregnancies">
                    Do you have any hearing impairment?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Recentallergies?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergyOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleAllergyOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedAllergyOption === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus"> Hearing Profile</Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {HearingImpairmentData?.map(allergy => (
                        <Col key={allergy} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAllergies.includes(allergy)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAllergy(allergy)}
                            className="m-1"
                          >
                            {allergy}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label for="numPregnancies">
                    Do you have any nail problem?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {NailsProblem?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergyOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleAllergyOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedAllergyOption === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus"> Nails </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {HearingImpairmentData?.map(allergy => (
                        <Col key={allergy} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAllergies.includes(allergy)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAllergy(allergy)}
                            className="m-1"
                          >
                            {allergy}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label for="maritalStatus">
                    Have you noticed any symptoms from the following?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {FollowingSymptoms?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">
                    Have you noticed any symptoms from the following indicating
                    Vitamin D deficiency?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VitaminD_Deficiency?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="maritalStatus">
                    Have you noticed any symptoms from the following indicating
                    Iron deficiency?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {IronDeficiencies?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="maritalStatus">
                    Have you noticed any symptoms from the following indicating
                    Vitamin B12 deficiency?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VitaminB12deficiency?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="maritalStatus">
                    Have you noticed any symptoms from the following indicating
                    Calcium deficiency?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Calciumdeficiency?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">
                    Have you noticed any symptoms from the following indicating
                    Vitamin C deficiency?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {VitaminCDeficiency?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="maritalStatus">
                    Have you noticed any symptoms from the following indicating
                    lodine deficiency?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {LodineDeficiency?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <Button type="submit" color="primary" style={{}}>
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  // border: "2px solid  #FF7738",
  borderRadius: 7,
  padding: 10,
}

export default HarmonalImbalanceandDeficiencies
