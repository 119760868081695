import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication

// category
// import CategoryReducer from "./smm/reducer";



const rootReducer = combineReducers({
  // public
  Layout,
  // CategoryReducer
})

export default rootReducer;
