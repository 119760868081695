import React, { useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Row,
} from "reactstrap"

import classnames from "classnames"
import ViraltrendComp1 from "./comp/ViraltrendComp1"
import NewbornCaseRecordView from "./NewbornCaseRecordView"
import SystematicExaminationView from "./SystematicExaminationView"

function PaitentCaseFileCollapseTab() {
  const [col9, setcol9] = useState(false)
  const [col10, setcol10] = useState(false)
  const [col11, setcol11] = useState(false)
  const [allOpen, setAllOpen] = useState(false)

  const toggleSection = section => {
    if (section === "col9") {
      setcol9(!col9)
      if (!col9) setAllOpen(false)
    } else if (section === "col10") {
      setcol10(!col10)
      if (!col10) setAllOpen(false)
    } else if (section === "col11") {
      setcol11(!col11)
      if (!col11) setAllOpen(false)
    }
  }

  const toggleAllSections = () => {
    const nextAllOpenState = !allOpen
    setcol9(nextAllOpenState)
    setcol10(nextAllOpenState)
    setcol11(nextAllOpenState)
    setAllOpen(nextAllOpenState)
  }

  const formGroupStyle = {
    border: "2px solid  #e5e8eb",
    borderRadius: 7,
  }

  return (
    <React.Fragment>
      <Container fluid={true}>
        <Row>
          <Card style={formGroupStyle}>
            <>
              <Row>
                <Col>
                  <div>
                    <div className=" text-end">
                      <Button
                        className="bg-primary m-2 "
                        onClick={toggleAllSections}
                      >
                        {allOpen ? "Close All" : "Open All"}
                      </Button>
                    </div>
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushOne">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col9 }
                            )}
                            type="button"
                            onClick={() => toggleSection("col9")}
                            style={{ cursor: "pointer" }}
                          >
                            Viral Trend
                          </button>
                        </h2>

                        <Collapse isOpen={col9} className="accordion-collapse">
                          <div className="accordion-body1">
                            <ViraltrendComp1 />
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushTwo">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col10 }
                            )}
                            type="button"
                            onClick={() => toggleSection("col10")}
                            style={{ cursor: "pointer" }}
                          >
                            24 May 2024 5:00 PM Systemic Examination
                          </button>
                        </h2>

                        <Collapse isOpen={col10} className="accordion-collapse">
                          <div className="accordion-body">
                            <SystematicExaminationView />
                            {/* <div className="text-muted">
                              Lorem Ipsum It is hidden by default, until the
                              collapse plugin adds the appropriate classes that
                              we use to style
                            </div> */}
                          </div>
                        </Collapse>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingFlushThree">
                          <button
                            className={classnames(
                              "accordion-button",
                              "fw-medium",
                              { collapsed: !col11 }
                            )}
                            type="button"
                            onClick={() => toggleSection("col11")}
                            style={{ cursor: "pointer" }}
                          >
                            24 May 2024 5:00 PM General Examination
                          </button>
                        </h2>
                        <Collapse isOpen={col11} className="accordion-collapse">
                          <div className="accordion-body">
                            <NewbornCaseRecordView />
                          </div>
                        </Collapse>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          </Card>
        </Row>
      </Container>
    </React.Fragment>
  )
}

export default PaitentCaseFileCollapseTab
