// DataCard.js
import React from "react"
import { Card, CardBody, CardTitle, CardText, Row, Col } from "reactstrap"
// import "boxicons/css/boxicons.min.css"
import "./DataCard.css" // Custom CSS for card styling

const DataCardx = ({ icon, title, value, color }) => {
  return (
    <Card className="data-card-glance" style={{ backgroundColor: color }}>
      <CardBody className="text-justify">
        <Row>
          <Col xl="6" lg="6" md="12">
            <div
              className="card-icon-glance"
              style={{
                width: "80px",
                height: "80px",
                padding: "10px",
                border: "1px solid",
                borderRadius: 50,
                color: color,
                backgroundColor: "white",
                // maxWidth: "80px",
                // maxHeight: "80px",
              }}
            >
              {icon}
            </div>
          </Col>
          <Col xl="6" lg="6" md="12">
            <CardTitle tag="h6">{title}</CardTitle>
            <CardText>{value}</CardText>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

const DataCard = ({ icon, title, value, color }) => {
  return (
    <Card className="data-card-glance" style={{ backgroundColor: color }}>
      <CardBody>
        <Row className="align-items-center">
          <Col
            xl="4"
            lg="4"
            md="12"
            className="d-flex justify-content-center mb-3 mb-lg-0"
          >
            <div
              className="card-icon-glance"
              style={{
                width: "80px",
                height: "80px",
                // padding: "15px",
                padding: "10px",
                border: "2px solid",
                borderColor: color,
                // borderRadius: "50%",
                borderRadius: 50,
                color: color,
                backgroundColor: "white",
              }}
            >
              {icon}
            </div>
          </Col>
          <Col xl="8" lg="8" md="12">
            <CardTitle tag="h6" className="text-white mb-1 text-center">
              {title}
            </CardTitle>
            <CardText className="text-white text-center">{value}</CardText>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default DataCard

// const DataCard2 = ({ icon, title, value, color }) => {
//   return (
//     <Card
//       style={{
//         backgroundColor: color,
//         borderRadius: "10px",
//         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//         margin: "10px 0",
//       }}
//     >
//       <CardBody
//         style={{
//           textAlign: "center",
//           padding: "20px",
//         }}
//       >
//         <Row
//           style={{
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//           }}
//         >
//           <Col
//             xl="6"
//             lg="6"
//             md="12"
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               marginBottom: "10px",
//             }}
//           >
//             <div
//               style={{
//                 width: "80px",
//                 height: "80px",
//                 padding: "10px",
//                 border: "2px solid",
//                 borderColor: color,
//                 borderRadius: "50%",
//                 color: color,
//                 backgroundColor: "white",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//               }}
//             >
//               {icon}
//             </div>
//           </Col>
//           <Col
//             xl="6"
//             lg="6"
//             md="12"
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               textAlign: "left",
//             }}
//           >
//             <CardTitle
//               tag="h6"
//               style={{
//                 fontSize: "18px",
//                 // fontWeight: "bold",
//                 color: "white",
//                 marginBottom: "8px",
//                 fontFamily: "sans-serif",
//               }}
//             >
//               {title}
//             </CardTitle>
//             <CardText
//               style={{
//                 fontSize: "16px",
//                 color: "white",
//               }}
//             >
//               {value}
//             </CardText>
//           </Col>
//         </Row>
//       </CardBody>
//     </Card>
//   )
// }
