import React, { useState } from "react"
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardSubtitle,
  Badge,
} from "reactstrap"
// for range slider
import "react-rangeslider/lib/index.css"
import { Form, Link } from "react-router-dom"
import { toast } from "react-toastify"
import Select from "react-select"

const Options = ["Yes", "No"]
const SmokelessTobaccoOptions = ["Yes", "No"]
const Recentallergies = ["Yes", "No"]
const alternativeTherapiesOptions = ["Yes", "No"]
// const alternativeTherapiesOptions = ["Yes", "No"]

const allergies = ["option1", "option2", "options3", "option4", "option5"]

const maritalStatusOptions2 = [
  "option1",
  "option2",
  "options3",
  "option4",
  "option5",
]
const familyPlanningOptions2 = [
  "None",
  "option1",
  "option2",
  "options3",
  "option4",
  "option5",
]

const SleepPattern = [
  "Insomnia",
  "Sleep apnea",
  "Parasomnias",
  "Difficulty falling or staying asleep",
  "Daytime fatigue",
  "Strong urge to take naps during the day",
  "Unusual breathing patterns while asleep",
  "Unusual or unpleasant urges to move while falling asleep",
  "Unusual movement or other experiences while asleep",
  "Unintentional changes to the sleep/wake schedule",
]

const ExerciseRoutine = [
  "No Exercise",
  "Morning",
  "Evening",
  "Both Morning & Evening",
  "All days",
  "Alternate days",
  "Weekends only",
]
const DietType = [
  "Light meal",
  "Heavy meal",
  "Skip meal",
  "Atkins diet",
  "The Zone diet",
  "Ketogenic diet",
  "Vegetarian diet",
  "Vegan diet",
  "Weight Watchers diet",
  "South Beach diet",
  "Raw food diet",
  "Mediterranean diet",
  "Non-vegetarian diet",
  "Eggetarian diet",
  "Fruitarian diet",
]

const DietarySupplements = [
  "Multivitamin",
  "Vitamin C",
  "Vitamin B",
  "Vitamin B12",
  "Vitamin D",
  "Calcium",
  "Fish Oil",
  "Algae-Based Omega-3 Supplement",
  "Probiotics",
  "Protein supplements",
]

const NonAlcoholicDrink = ["Tea", "Coffee", "Cold Drink", "Black Coffee"]

const Waterintakeinaday = [
  "1-3 Glasses",
  "3-5 Glasses",
  "5-8 Glasses",
  "Above 8 Glasses",
]
const SugarTakeinaDay = ["Low", "Moderate", "High"]
const SaltTakeinaDay = ["Low", "Moderate", "High"]
const AlcoholConsumption = ["Never", "Regular", "Occasional"]

const HabitandLifeStyle = () => {
  const [formData, setFormData] = useState({
    maritalStatus: null,
    numPregnancies: "",
    numMiscarriages: "",
    numChildren: "",
    ageLastChild: "",
    knownAllergies: [],
    familyPlanning: [],
    HeightFeetInches: "",
    weight: null,
  })

  const [submittedData, setSubmittedData] = useState(null)

  const handleChange = (selectedOption, { name }) => {
    setFormData({
      ...formData,
      [name]: selectedOption,
    })
  }

  const handleInputChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setSubmittedData(formData)
  }

  const [selectedAllergies, setSelectedAllergies] = useState([])

  const toggleAllergy = allergy => {
    setSelectedAllergies(prevSelected =>
      prevSelected.includes(allergy)
        ? prevSelected.filter(a => a !== allergy)
        : [...prevSelected, allergy]
    )
  }

  // Recentallergies
  const [selectedAllergyOption, setSelectedAllergyOption] = useState(null)

  const toggleAllergyOption = data => {
    setSelectedAllergyOption(data)
  }

  const [selectedMaritialStatus, setSelectedMaritialStatus] = useState("")

  const toggleMaritialStatus = maritialStatus => {
    setSelectedMaritialStatus(maritialStatus)
    // console.log(maritialStatus)
  }

  // salt in take
  const [selectedSaltIntake, setselectedSaltIntake] = useState("")

  const toggleSaltIntake = maritialStatus => {
    setselectedSaltIntake(maritialStatus)
    // console.log(maritialStatus)
  }
  //smokeless tobacco
  const [selectedSmokelessTobacco, setselectedSmokelessTobacco] = useState("")

  const toggleSmokelessTobacco = data => {
    setselectedSmokelessTobacco(data)
    // console.log(maritialStatus)
  }

  const [selectedfamilyPlanning, setSelectedFamilyPlanning] = useState([])

  const toggleFamilyPlanning = familyPlanning => {
    setSelectedFamilyPlanning(prevSelected =>
      prevSelected.includes(familyPlanning)
        ? prevSelected.filter(a => a !== familyPlanning)
        : [...prevSelected, familyPlanning]
    )
  }

  return (
    <div>
      <Row>
        <Col md={6}>
          {submittedData && (
            <>
              <h5>Habits & LifeStyle</h5>
              <Card>
                <CardBody>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Do you have any sleep disorder?</Label>
                    <div>A.- {submittedData?.height || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Exercise Routine</Label>
                    <div>A.- {submittedData?.mentalHealthProblem || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Diet Plan</Label>
                    <div>
                      A.- {submittedData?.mentalHealthWellbeing || "N/A"}
                    </div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>
                      Q.- Please select your Non-Alocoholic drink preferences ?
                    </Label>
                    <div>A.- {submittedData?.cognitiveImpairment || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Dietary Supplements</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Sugar intake in a day</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Salt intake in a day</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Water intake in a day</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Smoking (Cigarette/Beedi)</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>{" "}
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Smokeless Tobacco Consumption</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>{" "}
                  <FormGroup style={formGroupStyle}>
                    <Label>Q.- Alcohol Consumption Pattern</Label>
                    <div>A.- {submittedData?.dietaryAdherence || "N/A"}</div>
                  </FormGroup>
                </CardBody>
              </Card>
            </>
          )}
        </Col>

        <Col md={6}>
          <h5>Habits & LifeStyle</h5>
          <Card>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Label for="numPregnancies">
                    Do you have any sleep disorder?
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Recentallergies?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedAllergyOption === data ? "primary" : "light"
                          }
                          onClick={() => toggleAllergyOption(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {selectedAllergyOption === "Yes" && (
                  <FormGroup>
                    <Label for="maritalStatus"> Rate your sleep pattern </Label>
                    <Row className="g-1" style={formGroupStyle}>
                      {SleepPattern?.map(allergy => (
                        <Col key={allergy} xs="auto">
                          <Button
                            size="sm"
                            color={
                              selectedAllergies.includes(allergy)
                                ? "primary"
                                : "light"
                            }
                            onClick={() => toggleAllergy(allergy)}
                            className="m-1"
                          >
                            {allergy}
                          </Button>
                        </Col>
                      ))}
                    </Row>
                  </FormGroup>
                )}

                <FormGroup>
                  <Label for="maritalStatus">Exercise Routine</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {ExerciseRoutine?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus"> Diet Type</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {DietType?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">
                    {" "}
                    Please select your Non-Alocoholic drink preferences
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {NonAlcoholicDrink?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">Dietary Supplements</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {DietarySupplements?.map(allergy => (
                      <Col key={allergy} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedfamilyPlanning.includes(allergy)
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleFamilyPlanning(allergy)}
                          className="m-1"
                        >
                          {allergy}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">Sugar intake in a day</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {SugarTakeinaDay?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="maritalStatus">Salt intake in a day</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {SaltTakeinaDay?.map(data => (
                      <Col key={data} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedSaltIntake === data ? "primary" : "light"
                          }
                          onClick={() => toggleSaltIntake(data)}
                          className="m-1"
                        >
                          {data}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                {/* water intake in a day */}
                <FormGroup>
                  <Label for="maritalStatus">Water intake in a day</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Waterintakeinaday?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                {/* smoking  */}
                <FormGroup>
                  <Label for="maritalStatus">Smoking (Cigarette/Beedi)</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Options?.map(values => (
                      <Col key={values} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === values
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(values)}
                          className="m-1"
                        >
                          {values}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Label for="maritalStatus">
                    Smokeless Tobacco Consumption
                  </Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {Options?.map(values => (
                      <Col key={values} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedSmokelessTobacco === values
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleSmokelessTobacco(values)}
                          className="m-1"
                        >
                          {values}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <FormGroup>
                  <Label for="maritalStatus">Alcohol Consumption Pattern</Label>
                  <Row className="g-1" style={formGroupStyle}>
                    {AlcoholConsumption?.map(maritialStatus => (
                      <Col key={maritialStatus} xs="auto">
                        <Button
                          size="sm"
                          color={
                            selectedMaritialStatus === maritialStatus
                              ? "primary"
                              : "light"
                          }
                          onClick={() => toggleMaritialStatus(maritialStatus)}
                          className="m-1"
                        >
                          {maritialStatus}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </FormGroup>

                <Button type="submit" color="primary" style={{}}>
                  Save
                </Button>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

const formGroupStyle = {
  border: "2px solid  #e5e8eb",
  // border: "2px solid  #FF7738",
  borderRadius: 7,
  padding: 10,
}

export default HabitandLifeStyle
