import React, { useEffect, useContext, useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
} from "reactstrap"
import AdminContext from "../../context/adminContext"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom"
import { Link } from "react-router-dom"

// import images
import profileImg from "../../assets/images/profile-img.png"
import logoImg from "../../assets/images/logo.svg"
import LoginImage from "../AuthenticationInner/login_screen.jpg"

const Register = props => {
  const context = useContext(AdminContext)
  const { Rigester } = context
  let nevigate = useNavigate()
  //meta title
  document.title = "Register | Health"
  const [loading, setLoading] = useState(false)

  // const dispatch = useDispatch();
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      email: "",
      username: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required("Please Enter Your Email"),
      username: Yup.string()
        .min(2)
        .max(25)
        .required("Please Enter Your Username"),
      // password: Yup.string().min(6).required("Please Enter Your Password"),
      // confirm_password: Yup.string()
      //   .required("Please Enter Your confirm Password")
      //   .oneOf([Yup.ref("password"), null], "Password Must Be Same"),
      password: Yup.string()
        .min(6, "Password must be at least 6 characters long")
        .matches(
          passwordRegex,
          "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
        )
        .required("Please Enter Your Password"),
      confirm_password: Yup.string()
        .required("Please Enter Your confirm Password")
        .oneOf([Yup.ref("password"), null], "Passwords must match"),
    }),
    onSubmit: async (values, action) => {
      // setLoading(true)
      // console.log(values)
      // let response = await Rigester(values)
      // // response?alert('login'):alert('error')
      // if (response || response === false) {
      //   setLoading(false)
      // }
      // response && nevigate("/auth-two-step-verification")
      // // action.resetForm();
    },
  })

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              className="d-flex justify-content-center align-items-center"
              style={{ backgroundColor: "#0077B5", height: "100vh" }}
              // xl={6}
            >
              <div className="text-center">
                {/* <h1 className="text-white mb-4">LOGO</h1> */}
                <img
                  src={LoginImage}
                  alt="Login"
                  className="img-fluid"
                  style={{ maxWidth: "40%", height: "auto" }}
                />
              </div>
            </Col>
            <Col
              xl={4}
              className="position-relative"
              // style={{
              //   backgroundImage: `url(${imagebg})`,
              //   backgroundPosition: "center",
              //   backgroundSize: "cover",
              // }}
            >
              {/* <img src={imagebg} alt=""> */}
              <div
                className="auth-full-page-content p-md-5 p-4"
                style={{ background: "rgba(255,255,255,0.85)" }}
              >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-1 mb-md-1">
                      {/* <Link to="#" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="150"
                          className="logo-dark-element bg-white"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="150"
                          className="logo-light-element bg-white "
                        />
                      </Link> */}
                    </div>
                    <div className="my-auto">
                      {/* <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">Sign in to continue to.</p>
                      </div> */}

                      <div className="text-primary2" style={{ marginLeft: 10 }}>
                        <h5 className="text-primary mt-3">Sign Up</h5>
                        <div className="">
                          <h6 className="text-grey col">
                            Enter details to create your account
                          </h6>
                        </div>
                      </div>
                      <div className="p-2">
                        <Form
                          className="form-horizontal"
                          onSubmit={e => {
                            e.preventDefault()

                            validation.handleSubmit()
                            return false
                          }}
                        >
                          {/* {user && user ? (
                        <Alert color="success">
                          Register User Successfully
                        </Alert>
                      ) : null} */}

                          {/* {registrationError && registrationError ? (
                        <Alert color="danger">{registrationError}</Alert>
                      ) : null} */}

                          <div className="mb-3">
                            {/* <Label className="form-label">Username</Label> */}
                            <Input
                              name="username"
                              type="text"
                              placeholder="Username*"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username &&
                                validation.errors.username
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.username &&
                            validation.errors.username ? (
                              <FormFeedback type="invalid">
                                {validation.errors.username}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            {/* <Label className="form-label">Email</Label> */}
                            <Input
                              id="email"
                              name="email"
                              className="form-control"
                              placeholder="Email*"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            {/* <Label className="form-label">Password</Label> */}
                            <Input
                              name="password"
                              type="password"
                              placeholder="Password*"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.password || ""}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            {/* <Label className="form-label">
                              Confirm Password
                            </Label> */}
                            <Input
                              name="confirm_password"
                              type="password"
                              placeholder="Confirm Password*"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.confirm_password || ""}
                              invalid={
                                validation.touched.confirm_password &&
                                validation.errors.confirm_password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.confirm_password &&
                            validation.errors.confirm_password ? (
                              <FormFeedback type="invalid">
                                {validation.errors.confirm_password}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div
                            className="text-primary2"
                            style={{ marginLeft: 10 }}
                          >
                            <div className="">
                              <h6 className="text-grey col">
                                Already Registered?
                                <span className="text-primary">
                                  {/* &nbsp;&nbsp;SignUp. */}
                                  <Link to="/login" className="text-primary">
                                    {/* <i className="mdi mdi-lock me-1" /> */}
                                    &nbsp;Login
                                  </Link>
                                </span>
                              </h6>
                            </div>
                          </div>
                          <div className="mt-4 d-grid">
                            <button
                              className="btn btn-primary btn-block text-white"
                              type="submit"
                              style={{ backgroundColor: "#F86F03" }}
                            >
                              {loading ? "Loading..." : "Register"}
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <h5 className="font-size-16 mb-3 text-grey">
                              ----------OR----------
                            </h5>

                            <ul className="list-inline">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              </li>{" "}
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-info text-white border-info"
                                >
                                  <i className="mdi mdi-twitter" />
                                </Link>
                              </li>{" "}
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-danger text-white border-danger"
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                >
                                  <i className="mdi mdi-linkedin" />
                                </Link>
                              </li>
                            </ul>
                          </div>

                          {/* <div className="mt-4 text-center">
                            <p className="mb-0">
                              By registering you agree to the our{" "}
                              <Link to="#" className="text-primary">
                                Terms of Use
                              </Link>
                            </p>
                          </div> */}
                        </Form>
                      </div>
                    </div>
                    {/* 
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()}</p>
                    </div> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Register
