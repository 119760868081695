import React, { useState } from "react"
import { Modal, ModalBody, ModalHeader } from "reactstrap"

const ImageFullView = ({ imageSrc, altText, triggerElement }) => {
  const [modal, setModal] = useState(false)

  const toggle = () => setModal(!modal)

  return (
    <>
      <div onClick={toggle}>{triggerElement}</div>
      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}></ModalHeader>
        <ModalBody className="text-center">
          <img
            src={imageSrc}
            alt={altText}
            style={{ width: "100%", height: "auto" }}
          />
        </ModalBody>
      </Modal>
    </>
  )
}

export default ImageFullView
